@import url('https://fonts.googleapis.com/css?family=Montserrat');

/*
https://codeburst.io/how-to-create-horizontal-scrolling-containers-d8069651e9c6

https://www.youtube.com/watch?v=Qhaz36TZG5Y

.scrolling-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

    .card-small-pc {
        min-width: 100px;
        max-width: 110px;
        max-height: 125px;
    }

    .card-small-mobile {
        min-width: 100px;
        max-width: 120px;
        max-height: 125px;
    }

    .card-mobile {
        min-width: 300px;
        max-width: 500px;
    }

    .card-pc {
        height: 375px;
        min-width: 200px;
        max-width: 225px;
        overflow-y: scroll;
    }

.scrolling-wrapper > card-hui {
        min-width: 100px;
        max-width: 150px;
    }

    .card-report {
        min-width: 50px;
        max-width: 450px;
        min-height: 25px;
    }

    .card {
        display: inline-block;
        background-color: transparent;
    }



}
*/
.scrolling-wrapper {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}
.card {
    flex: 0 0 auto;
}

.card-hui {

    min-width: 100px;
    max-width: 150px;
}

.card-small-pc {
    min-width: 100px;
    max-width: 110px;
    max-height: 125px;
}

.card-small-mobile {
    min-width: 100px;
    max-width: 120px;
    max-height: 125px;
}

.card-mobile {
    min-width: 300px;
    max-width: 500px;
}

.card-pc {
    height: 375px;
    min-width: 200px;
    max-width: 225px;
    overflow-y: scroll;
}

.card-report {
    min-width: 50px;
    max-width: 450px;
    min-height: 25px;
}

.card-ohana{
    min-width: 175px;
    max-width: 175px;
    min-height: 350px;
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: hidden;
    border: 1px solid #0b3954;
}

.card-ohana-mobile{
    width: 200px;
}

/*
Tabs Horizontal
 */

.nav-tabs-j {
  margin-bottom: 5px;
  border: 1px solid #fff;
  text-align:center;
}

.nav-item-j {
    margin-bottom: 5px;
    margin-left: 40px;
    margin-right: 40px;
}

a.nav-link-j:link  {
    color: #4788c7;
}

a.nav-link-j:visited  {
    color: #4788c7;
}

a.nav-link-j:focus  {
    color: #4788c7;
    border-bottom: 3px solid #FF6600;
    text-decoration: none;
}

a.nav-link-j:active  {
    color: #4788c7;
    text-decoration: none;
}

a.nav-link-j:hover  {
    color: #043467;
    border-bottom: 3px solid #FF6600;
    text-decoration: none;
}
/*
tabs Horizontal
 */

.col-centered{
  float: none;
  margin: 0 auto;
}

.btn-group-ts > .btn, .btn-ts {
    padding  : .25rem .4rem;
    font-size  : .875rem;
    line-height  : .5;
    border-radius : .2rem;
}

.btn-xl {
    padding: 10px 20px;
    font-size: 30px !important;
    border-radius: 10px;
}

.btn-ty {
    padding: 2px 2px;
    font-size: 12px !important;
    border-radius: 2px;
}

.btn-rounded {
    border-radius: 50px!important;
    font-size: .75rem;
}

.rounded-image {
    border-radius: 64px;
    overflow: hidden;
}

.rounded-icon {
    border-radius: 64px;
    overflow: hidden;

    background-color:#fff;
    border:4px solid whitesmoke;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
}


.rounded-image48 {
    border-radius: 48px;
    overflow: hidden;
}


/*
maps
 */
#jmap {
   height: 100%;
}
/* Optional: Makes the sample page fill the window. */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}
/*maps */
.btn-xlarge {
   padding: 18px 28px;
   font-size: 36px;
   line-height: normal;
   -webkit-border-radius: 8px;
   -moz-border-radius: 8px;
   border-radius: 8px;
}

.text-shadow {
    font-size: 2em;
    color: #0342a8;
    text-shadow: 2px 2px 4px #fff;
}

.text-xlarge {
    font-size: 3em;
}

.text-large {
    font-size: 2.5em;
}

.text-big {
    font-size: 2em;
}

.text-mid {
    font-size: 1.5em;
}

.text-xnorm {
    font-size: 1.25em;
}

.text-norm {
    font-size: 1em;
}

.text-small {
    font-size: .75em;
}

.text-xsmall {
    font-size: .5em;
}

.warning {
    font-size: 16px;
    color: #4f1733;
    font-family: 'Montserrat', sans-serif;
}

.login-mobile {
    padding: 30px;
    max-width: 1000px;
    width: 100% !important;
    background-color: #F7F7F7;
    margin: 1px auto;
    border-radius: 2px;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    font-family: 'Montserrat', sans-serif;
}
.login-mobile h1 {
    text-align: center;
    font-siz777e: 5em;
    font-family: 'Montserrat', sans-serif;
}
.login-mobile h2 {
    text-align: center;
    font-size: 3.8em;
    font-family: 'Montserrat', sans-serif;
}
.login-mobile input[type=submit] {
    width: 100%;
    display: block;
    font-size: 3em;
    margin-bottom: 10px;
    position: relative;
}
.login-mobile input[type=text] {
    height: 120px;
    font-size: 5em;
    width: 100%;
    margin-bottom: 10px;
    -webkit-appearance: none;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-top: 1px solid #c0c0c0;
    /* border-radius: 2px; */
    padding: 0 8px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}
.login-mobile input[type=password] {
    height: 120px;
    font-size: 5em;
    width: 100%;
    margin-bottom: 10px;
    -webkit-appearance: none;
    -webkit-text-security: disc;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-top: 1px solid #c0c0c0;
    /* border-radius: 2px; */
    padding: 0 8px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}
.login-mobile input[type=text]:hover {
    border: 1px solid #b9b9b9;
    border-top: 1px solid #a0a0a0;
    -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
}
.login-mobile input[type=password]:hover {
    border: 1px solid #b9b9b9;
    border-top: 1px solid #a0a0a0;
    -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
}

.login-submit-mobile {
    /* border: 1px solid #3079ed; */
    border:1px;
    color: #fff;
    text-shadow: 0 1px rgba(0,0,0,0.1);
    background-color: #4d90fe;
    padding: 17px 1px;
    font-family: 'Montserrat', sans-serif;
    font-size: 32px;
    /* background-image: -webkit-gradient(linear, 0 0, 0 100%,   from(#4d90fe), to(#4787ed)); */
}
.login-submit-mobile:hover {
    /* border: 1px solid #2f5bb7; */
    border: 1px;
    text-shadow: 0 1px rgba(0,0,0,0.3);
    background-color: #357ae8;
    /* background-image: -webkit-gradient(linear, 0 0, 0 100%,   from(#4d90fe), to(#357ae8)); */
}
.login-mobile a {
    text-decoration: none;
    color: #666;
    font-weight: 400;
    text-align: center;
    display: inline-block;
    opacity: 0.6;
    transition: opacity ease 0.5s;
}
.login-help-mobile{
    font-size: 12px;
}

/* Tablet*/
.login-tablet {
    padding: 30px;
    max-width: 500px;
    width: 100% !important;
    background-color: #F7F7F7;
    margin: 1px auto;
    border-radius: 2px;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    font-family: 'Montserrat', sans-serif;
    opacity: 0.8;
    transition: opacity ease 0.5s;
}
.login-tablet h1 {
    text-align: center;
    font-size: 5em;
    font-family: 'Montserrat', sans-serif;
}
.login-tablet h2 {
    text-align: center;
    font-size: 3.8em;
    font-family: 'Montserrat', sans-serif;
}
.login-tablet p {
    text-align: center;
    font-size: 2em;
    font-family: 'Montserrat', sans-serif;
}
.login-tablet input[type=submit] {
    width: 100%;
    display: block;
    font-size: 1em;
    margin-bottom: 10px;
    position: relative;
}
.login-tablet input[type=text], input[type=password] {
    height: 44px;
    font-size: 32px;
    width: 100%;
    margin-bottom: 10px;
    -webkit-appearance: none;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-top: 1px solid #c0c0c0;
    /* border-radius: 2px; */
    padding: 0 8px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}
.login-tablet input[type=text]:hover, input[type=password]:hover {
    border: 1px solid #b9b9b9;
    border-top: 1px solid #a0a0a0;
    -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
}
.login-submit-tablet {
    /* border: 1px solid #3079ed; */
    border:1px;
    color: #fff;
    text-shadow: 0 1px rgba(0,0,0,0.1);
    background-color: #4d90fe;
    padding: 17px 1px;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    /* background-image: -webkit-gradient(linear, 0 0, 0 100%,   from(#4d90fe), to(#4787ed)); */
}
.login-submit-tablet:hover {
    /* border: 1px solid #2f5bb7; */
    border:1px;
    text-shadow: 0 1px rgba(0,0,0,0.3);
    background-color: #357ae8;
    /* background-image: -webkit-gradient(linear, 0 0, 0 100%,   from(#4d90fe), to(#357ae8)); */
}
.login-tablet a {
    text-decoration: none;
    color: #666;
    font-weight: 400;
    text-align: center;
    display: inline-block;
    opacity: 0.6;
    transition: opacity ease 0.5s;
}

.login-help-tablet{
    font-size: 14px;
}
/* PC */
.login-container {
    padding: 30px;
    max-width: 500px;
    width: 100% !important;
    background-color: #F7F7F7;
    margin: 1px auto;
    border-radius: 2px;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    font-family: 'Montserrat', sans-serif;
    opacity: 0.8;
    transition: opacity ease 0.5s;
}
.login-container h1 {
    text-align: center;
    font-size: 5em;
    font-family: 'Montserrat', sans-serif;
}
.login-container h2 {
    text-align: center;
    font-size: 3.8em;
    font-family: 'Montserrat', sans-serif;
}
.login-container p {
    text-align: center;
    font-size: 2em;
    font-family: 'Montserrat', sans-serif;
}
.login-container input[type=submit] {
    width: 100%;
    display: block;
    font-size: 1em;
    margin-bottom: 10px;
    position: relative;
}
.login-container input[type=text], input[type=password] {
    height: 44px;
    font-size: 32px;
    width: 100%;
    margin-bottom: 10px;
    -webkit-appearance: none;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-top: 1px solid #c0c0c0;
    /* border-radius: 2px; */
    padding: 0 8px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}
.login-container input[type=text]:hover, input[type=password]:hover {
    border: 1px solid #b9b9b9;
    border-top: 1px solid #a0a0a0;
    -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
}
.login-submit {
    /* border: 1px solid #3079ed; */
    border:1px;
    color: #fff;
    text-shadow: 0 1px rgba(0,0,0,0.1);
    background-color: #4d90fe;
    padding: 17px 1px;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    /* background-image: -webkit-gradient(linear, 0 0, 0 100%,   from(#4d90fe), to(#4787ed)); */
}
.login-submit:hover {
    /* border: 1px solid #2f5bb7; */
    border:1px;
    text-shadow: 0 1px rgba(0,0,0,0.3);
    background-color: #357ae8;
    /* background-image: -webkit-gradient(linear, 0 0, 0 100%,   from(#4d90fe), to(#357ae8)); */
}
.login-container a {
    text-decoration: none;
    color: #666;
    font-weight: 400;
    text-align: center;
    display: inline-block;
    opacity: 0.6;
    transition: opacity ease 0.5s;
}
.login-help{
    font-size: 12px;
}

@media (min-width: 768px) {
    .modal-xl {
        width: 80%;
        min-width:900px;
        max-width:1200px;
        height: 80%;
        min-height:700px;
        max-height:1700px;
    }
}

/*
https://github.com/fireship-io/224-animated-css-grid/blob/master/public/base.css
 */
.card-hp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    color: #fff;
    box-shadow: rgba(3, 8, 20, 0.1)1px 0.15rem 0.5rem, rgba(2, 8, 20, 0.1)1px 0.075rem 0.175rem;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    transition: all 500ms;
    overflow: hidden;

    background-size: cover;
    background: #353535 no-repeat center;
}

.text-hp {
    text-shadow: 2px 2px 5px #fff, 0 0 25px grey;
}

a.text-hp:link, a.text-hp:visited, a.text-hp:active  {
    color: #40ee00;
}
a.text-hp:hover {
    color: #47d02c;
    text-decoration: underline;
}

.card-hp:hover {
    box-shadow: rgba(2, 8, 20, 0.1)1px 0.35em 1.175em, rgba(2, 8, 20, 0.08)1px 0.175em 0.5em;
    transform: translateY(-3px) scale(1.1);
}

/*https://github.com/fireship-io/224-animated-css-grid/blob/master/public/animated-grid.css */
.animated-grid {
    height: 85vh;
    margin-bottom: 50px;

    display: grid;
    gap: 1rem;

    /* Explicit grid */
    grid-template-areas:
          'a  b  c  d'
          'l  🌟 🌟 e'
          'k  🌟 🌟 f'
          'j  i  h  g';
    grid-template-rows: repeat(4, 25%);
    grid-template-columns: 240px auto auto 240px;
    --stagger-delay: 100ms;
}

@keyframes cardEntrance {
    from {
        opacity: 0;
        transform: scale(0.3);
        filter: hue-rotate(180deg);
    }
    to {
        opacity: 1;
        transform: scale(1);
        filter: hue-rotate(0deg);
    }
}

.card-hp {
    background-color: rgb(36, 243, 147);
    animation: cardEntrance 700ms ease-out;
    animation-fill-mode: backwards;
}

.card-hp:nth-child(1) {
    grid-area: a;
    animation-delay: calc(1 * var(--stagger-delay));
}
.card-hp:nth-child(2) {
    grid-area: b;
    animation-delay: calc(2 * var(--stagger-delay));
}
.card-hp:nth-child(3) {
    grid-area: c;
    animation-delay: calc(3 * var(--stagger-delay));
}
.card-hp:nth-child(4) {
    grid-area: d;
    animation-delay: calc(4 * var(--stagger-delay));
}
.card-hp:nth-child(5) {
    grid-area: e;
    animation-delay: calc(5 * var(--stagger-delay));
}
.card-hp:nth-child(6) {
    grid-area: f;
    animation-delay: calc(6 * var(--stagger-delay));
}
.card-hp:nth-child(7) {
    grid-area: g;
    animation-delay: calc(7 * var(--stagger-delay));
}
.card-hp:nth-child(8) {
    grid-area: h;
    animation-delay: calc(8 * var(--stagger-delay));
}
.card-hp:nth-child(9) {
    grid-area: i;
    animation-delay: calc(9 * var(--stagger-delay));
}
.card-hp:nth-child(10) {
    grid-area: j;
    animation-delay: calc(10 * var(--stagger-delay));
}
.card-hp:nth-child(11) {
    grid-area: k;
    animation-delay: calc(11 * var(--stagger-delay));
}
.card-hp:nth-child(12) {
    grid-area: l;
    animation-delay: calc(12 * var(--stagger-delay));
}
.card-hp:last-child {
    grid-area: 🌟;
    animation-delay: calc(13 * var(--stagger-delay));
}

/*
https://github.com/fireship-io/224-animated-css-grid/blob/master/public/basic-grid.css
 */
.basic-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
}

/*
 https://github.com/fireship-io/224-animated-css-grid/blob/master/public/photo-grid.css
 */
 .photo-grid {
     display: grid;
     gap: 1rem;

     grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
     grid-auto-rows: 240px;
 }

/* Medium screens */
@media screen and (min-width: 600px) {
    .card-tall {
        grid-row: span 2 / auto;
    }

    .card-wide {
        grid-column: span 2 / auto;
    }
}

/*
Fav Icons
 */

.fa-primary {
  color: #2442a5;
}

.fa-secondary {
    color: #0083ff;
}

.fa-primary,
.fa-secondary {
    transition: var(--transition-speed);
}


/*
 New Page Layout
 */

 .form-rounded {
     border-radius:1rem;/* Opera/IE 8+ */
     -moz-border-radius:1rem;/* Firefox, other Gecko */
     -webkit-border-radius:1rem;/* Safari/Chrome, other WebKit */
     border: 1px solid #a0a0a0;
     padding-left: 10px;
     outline-width: 0;
 }

.form-100 {
    width: 100%;
}


.form-select {
    -webkit-background-clip: text;
    color: rgb(9, 8, 8);
    -webkit-box-shadow: 10px 10px 10px 10px #ffffff;
    -moz-box-shadow:    10px 10px 10px 10px #ffffff;
    box-shadow:         10px 10px 10px 10px #ffffff;
}


/*
Page Outlay
 */
  :root {
      font-size: 16px;
      font-family: 'Montserrat', sans-serif;
      --text-primary: #b6b6b6;
      --text-secondary: #ececec;
      --bg-primary: #23232e;
      --bg-secondary: #141418;
      --transition-speed: 600ms;
  }

/*
 Scrolls
 */
       /* width */
   ::-webkit-scrollbar {
       width: 16px;
   }

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(70, 135, 199, 0.3);
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #cfdcf0;
}

body {
    color: black;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    font-family: 'Montserrat', sans-serif;
}

body::-webkit-scrollbar {
    width: 0.5rem;
}

body::-webkit-scrollbar-track {
    background: #d5d5d9;
}

body::-webkit-scrollbar-thumb {
    background: #ad96f1;
}

header {
    margin-left: 5rem;
    padding: 1rem;
}

main {
    margin-left: 5rem;
    padding: 1rem;
}

footer {
    margin-left: 5rem;
    padding: 1rem;
}


/*
 Right Menu Wrapper
 */

.wrapper-right {
    width: 75%;
    position: relative;
    /*padding: 28px 0 0 0;*/
    margin: 0 auto 0 30px;
    font-family: 'Montserrat', 'Open Sans', serif;
}

.wrapper-db {
    width: 100%;
    position: relative;
    /*padding: 28px 0 0 0;*/
    margin: 0 auto 0 30px;
    font-family: 'Montserrat', 'Open Sans', serif;
}

nav-right {
    position: absolute;
    left: 80%;
    right: 0;
    top: 0;
    display: block;
    width: 15%;
    padding: 4px 0;
    height: 200px;
    z-index: 100;
    color:#4687c7;
    font-family: 'Montserrat', serif;
    border-left: 1px solid #FF6600;
}

nav-right a {
    font-family: 'Montserrat', serif;
    color:#4687c7;
    padding: 2px 4px;
    display: block;
    float: left;
    text-decoration: none;
    margin-right: 4px;
    width: 100%;
    font-size:20px;
}

nav-right a:hover,
nav-right a.active {
    background: white;
    color:#093a7f;
}

.fixed-right {
    position: fixed;
    top: 150px
}

/*
 Right Menu Wrapper
 */

/*
 Left Wrapper
 */

.navbar-ai {
    position: fixed;
    background-color: var(--bg-primary);
    transition: width 600ms ease;
    overflow: scroll;
    z-index: 99999;
}

.navbar-ai-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.nav-ai-item {
    width: 100%;
    padding: 8px 8px 8px 12px;
}

.nav-ai-item:last-child {
    margin-top: auto;
}

.navbar-ai:hover .logo-ai svg {
    transform: rotate(-180deg);
}
.link-ai-text {
    display: none;
    margin-left: 1rem;
}

.logo-ai {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
    text-align: center;
    color: var(--text-secondary);
    background: var(--bg-secondary);
    font-size: 1.5rem;
    letter-spacing: 0.3ch;
    width: 100%;
}

.logo-ai svg {
    transform: rotate(0deg);
    transition: var(--transition-speed);
}

.logo-ai-text
{
    display: inline;
    position: absolute;
    left: -999px;
    transition: var(--transition-speed);
}


.link-ai-text {
    display: none;
    margin-left: 1rem;
}
/* Small screens */
@media only screen and (max-width: 600px) {
    .navbar-ai {
        bottom: 0;
        width: 100vw;
        height: 5rem;
    }

    .logo-ai {
        display: none;
    }

    .navbar-ai-nav {
        flex-direction: row;
    }

    .nav-ai-link {
        justify-content: center;
    }

    main {
        margin: 0;
    }
}

/* Large screens */
@media only screen and (min-width: 600px) {
    .navbar-ai {
        top: 0;
        width: 5rem;
        height: 100vh;
    }

    .navbar-ai:hover {
        width: 16rem;
    }

    .navbar-ai:hover .link-ai-text {
        display: inline;
    }

    .navbar-ai:hover .logo-ai svg
    {
        margin-left: 11rem;
    }

    .navbar-ai:hover .logo-ai-text
    {
        left: 0;
    }
}
.dark {
    --text-primary: #1f1f1f;
    --text-secondary: #000000;
    --bg-primary: #deeffd;
    --bg-secondary: #4687c8;
}

.light {
    --text-primary: #1f1f1f;
    --text-secondary: #000000;
    --bg-primary: #ffffff;
    --bg-secondary: #deeffc;
}

.solar {
    --text-primary: #576e75;
    --text-secondary: #35535c;
    --bg-primary: #fdf6e3;
    --bg-secondary: #f5e5b8;
}

.my_journey {
    --text-primary: #1f1f1f;
    --text-secondary: #000000;
    --bg-primary: #e9fdde;
    --bg-secondary: #72c644;
}

.dark a {
    text-decoration: none;
    color: #055ebe;
}

.dark:hover {
    text-decoration: none;
    color: #043467;
}

.my_journey a {
    text-decoration: none;
    color: #055ebe;
}

.my_journey:hover {
    text-decoration: none;
    color: #055ebe;
}

.theme-icon {
    display: none;
}

.dark #darkIcon {
    display: block;
}

.light #lightIcon {
    display: block;
}

.solar #solarIcon {
    display: block;
}

.my_journey #my_journeyIcon {
    display: block;
}

/*
 navigations top menu
 */
   .nav-menu-link {
       display: flex;
       align-items: center;
       text-decoration: none;
   }

.nav-menu-item {
    width: 100%;
    padding: 8px 8px 8px 12px;
}

.nav-menu-item:last-child {
    margin-top: auto;
}

.nav-menu-link {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.nav-menu-link:hover {
    filter: grayscale(0%) opacity(1);
}

.nav-menu-link svg {
    width: 2rem;
    min-width: 2rem;
}
/*
 navigations top menu removed
 */

   .fa-primary {
       color: #2442a5;
   }

.fa-secondary {
    color: #0083ff;
}

.fa-primary,
.fa-secondary {
    transition: var(--transition-speed);
}

/*
 Left Wrapper
 */

/*
frame
 */
.iframe-ai {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
}

.iframe-ai iframe {
    border: 0;
    height: 85%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
/*
Banner & Icon
 */
.bannerPos
{
    position: relative;
    top: 0;
    left: 0;
    padding-bottom: 5px;
}
.iconPos
{
    position: absolute;
    top: 60%;
    left: 50%;
}
/*
Tool Tips
 */
#tooltip {
    background: #333;
    color: white;
    font-weight: bold;
    padding: 4px 8px;
    font-size: 13px;
    border-radius: 4px;
}

#arrow,
#arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
}

#arrow {
    visibility: hidden;
}

#arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
}

#tooltip[data-popper-placement^='top'] > #arrow {
    bottom: -4px;
}

#tooltip[data-popper-placement^='bottom'] > #arrow {
    top: -4px;
}

#tooltip[data-popper-placement^='left'] > #arrow {
    right: -4px;
}

#tooltip[data-popper-placement^='right'] > #arrow {
    left: -4px;
}
@charset "UTF-8";
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --journey: #4687c7;
  --kokua: #3378b3;
  --kokual: rgba(84, 120, 191, 0.2);
  --kōkua: #3378b3;
  --ohana: #3e6bad;
  --ohanal: rgba(141, 208, 89, 0.2);
  --kula: #668712;
  --kulal: rgba(102, 135, 18, 0.2);
  --hui: #093a7f;
  --huil: rgba(9, 58, 127, 0.2);
  --dbs: #668712;
  --dbalpha: #998712;
  --dbresource: #cc8712;
  --dbthing: #ff8712;
  --dbsl: #e0e7d0;
  --superadmin: #4886b3;
  --admin: #745ab3;
  --supporter: #B350A6;
  --appliedfor: #d72e81;
  --appliedforl: #d72e81;
  --available: #d4ffd4;
  --totest: #7a82d7;
  --active: #77d077;
  --inactive: #d07777;
  --attention: #efe916;
  --closed: #ef5451;
  --help: #b8ef56;
  --expired: #d72c47;
  --abandoned: #b33735;
  --cmd: #00801d;
  --sysadmin: #d71cff;
  --super: #be3aff;
  --dns: #801c24;
  --network: #0954f4;
  --backups: #26803b;
  --hardware: #d7223b;
  --people: #37ab3a;
  --business: #093a7f;
  --manager: #581e80;
  --safezone: #3a15b2;
  --contract: #b25126;
  --forest: #153E15;
  --land: #00801d;
  --safety: #80211f;
  --contractor: #801d3a;
  --sub: #2f3580;
  --workcrew: #4f3587;
  --foreman: #482780;
  --crew: #412187;
  --truck: #fd2242;
  --visitor: #ff3131;
  --workday: #ff943b;
  --worksite: #fd5e53;
  --welcome: #0342a8;
  --error: #fd0921;
  --good: #3b8c3b;
  --zero: #dddddd;
  --eee: #fafafa;
  --none: #ffffff;
  --delete: #8A0303;
  --lightgray: #999999;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-journey,
.table-journey > th,
.table-journey > td {
  background-color: #cbddef;
}
.table-journey th,
.table-journey td,
.table-journey thead th,
.table-journey tbody + tbody {
  border-color: #9fc1e2;
}

.table-hover .table-journey:hover {
  background-color: #b8d0e9;
}
.table-hover .table-journey:hover > td,
.table-hover .table-journey:hover > th {
  background-color: #b8d0e9;
}

.table-kokua,
.table-kokua > th,
.table-kokua > td {
  background-color: #c6d9ea;
}
.table-kokua th,
.table-kokua td,
.table-kokua thead th,
.table-kokua tbody + tbody {
  border-color: #95b9d7;
}

.table-hover .table-kokua:hover {
  background-color: #b3cde3;
}
.table-hover .table-kokua:hover > td,
.table-hover .table-kokua:hover > th {
  background-color: #b3cde3;
}

.table-kokual,
.table-kokual > th,
.table-kokual > td {
  background-color: rgba(248, 249, 252, 0.776);
}
.table-kokual th,
.table-kokual td,
.table-kokual thead th,
.table-kokual tbody + tbody {
  border-color: rgba(237, 240, 248, 0.584);
}

.table-hover .table-kokual:hover {
  background-color: rgba(230, 234, 244, 0.776);
}
.table-hover .table-kokual:hover > td,
.table-hover .table-kokual:hover > th {
  background-color: rgba(230, 234, 244, 0.776);
}

.table-kōkua,
.table-kōkua > th,
.table-kōkua > td {
  background-color: #c6d9ea;
}
.table-kōkua th,
.table-kōkua td,
.table-kōkua thead th,
.table-kōkua tbody + tbody {
  border-color: #95b9d7;
}

.table-hover .table-kōkua:hover {
  background-color: #b3cde3;
}
.table-hover .table-kōkua:hover > td,
.table-hover .table-kōkua:hover > th {
  background-color: #b3cde3;
}

.table-ohana,
.table-ohana > th,
.table-ohana > td {
  background-color: #c9d6e8;
}
.table-ohana th,
.table-ohana td,
.table-ohana thead th,
.table-ohana tbody + tbody {
  border-color: #9bb2d4;
}

.table-hover .table-ohana:hover {
  background-color: #b7c8e0;
}
.table-hover .table-ohana:hover > td,
.table-hover .table-ohana:hover > th {
  background-color: #b7c8e0;
}

.table-ohanal,
.table-ohanal > th,
.table-ohanal > td {
  background-color: rgba(250, 253, 248, 0.776);
}
.table-ohanal th,
.table-ohanal td,
.table-ohanal thead th,
.table-ohanal tbody + tbody {
  border-color: rgba(243, 250, 237, 0.584);
}

.table-hover .table-ohanal:hover {
  background-color: rgba(236, 247, 228, 0.776);
}
.table-hover .table-ohanal:hover > td,
.table-hover .table-ohanal:hover > th {
  background-color: rgba(236, 247, 228, 0.776);
}

.table-kula,
.table-kula > th,
.table-kula > td {
  background-color: #d4ddbd;
}
.table-kula th,
.table-kula td,
.table-kula thead th,
.table-kula tbody + tbody {
  border-color: #afc184;
}

.table-hover .table-kula:hover {
  background-color: #c9d4ac;
}
.table-hover .table-kula:hover > td,
.table-hover .table-kula:hover > th {
  background-color: #c9d4ac;
}

.table-kulal,
.table-kulal > th,
.table-kulal > td {
  background-color: rgba(249, 250, 245, 0.776);
}
.table-kulal th,
.table-kulal td,
.table-kulal thead th,
.table-kulal tbody + tbody {
  border-color: rgba(239, 242, 230, 0.584);
}

.table-hover .table-kulal:hover {
  background-color: rgba(239, 242, 228, 0.776);
}
.table-hover .table-kulal:hover > td,
.table-hover .table-kulal:hover > th {
  background-color: rgba(239, 242, 228, 0.776);
}

.table-hui,
.table-hui > th,
.table-hui > td {
  background-color: #bac8db;
}
.table-hui th,
.table-hui td,
.table-hui thead th,
.table-hui tbody + tbody {
  border-color: #7f99bc;
}

.table-hover .table-hui:hover {
  background-color: #a9bbd2;
}
.table-hover .table-hui:hover > td,
.table-hover .table-hui:hover > th {
  background-color: #a9bbd2;
}

.table-huil,
.table-huil > th,
.table-huil > td {
  background-color: rgba(245, 247, 250, 0.776);
}
.table-huil th,
.table-huil td,
.table-huil thead th,
.table-huil tbody + tbody {
  border-color: rgba(229, 234, 241, 0.584);
}

.table-hover .table-huil:hover {
  background-color: rgba(228, 233, 242, 0.776);
}
.table-hover .table-huil:hover > td,
.table-hover .table-huil:hover > th {
  background-color: rgba(228, 233, 242, 0.776);
}

.table-dbs,
.table-dbs > th,
.table-dbs > td {
  background-color: #d4ddbd;
}
.table-dbs th,
.table-dbs td,
.table-dbs thead th,
.table-dbs tbody + tbody {
  border-color: #afc184;
}

.table-hover .table-dbs:hover {
  background-color: #c9d4ac;
}
.table-hover .table-dbs:hover > td,
.table-hover .table-dbs:hover > th {
  background-color: #c9d4ac;
}

.table-dbalpha,
.table-dbalpha > th,
.table-dbalpha > td {
  background-color: #e2ddbd;
}
.table-dbalpha th,
.table-dbalpha td,
.table-dbalpha thead th,
.table-dbalpha tbody + tbody {
  border-color: #cac184;
}

.table-hover .table-dbalpha:hover {
  background-color: #dad4ab;
}
.table-hover .table-dbalpha:hover > td,
.table-hover .table-dbalpha:hover > th {
  background-color: #dad4ab;
}

.table-dbresource,
.table-dbresource > th,
.table-dbresource > td {
  background-color: #f1ddbd;
}
.table-dbresource th,
.table-dbresource td,
.table-dbresource thead th,
.table-dbresource tbody + tbody {
  border-color: #e4c184;
}

.table-hover .table-dbresource:hover {
  background-color: #edd2a8;
}
.table-hover .table-dbresource:hover > td,
.table-hover .table-dbresource:hover > th {
  background-color: #edd2a8;
}

.table-dbthing,
.table-dbthing > th,
.table-dbthing > td {
  background-color: #ffddbd;
}
.table-dbthing th,
.table-dbthing td,
.table-dbthing thead th,
.table-dbthing tbody + tbody {
  border-color: #ffc184;
}

.table-hover .table-dbthing:hover {
  background-color: #ffd0a4;
}
.table-hover .table-dbthing:hover > td,
.table-hover .table-dbthing:hover > th {
  background-color: #ffd0a4;
}

.table-dbsl,
.table-dbsl > th,
.table-dbsl > td {
  background-color: #f6f8f2;
}
.table-dbsl th,
.table-dbsl td,
.table-dbsl thead th,
.table-dbsl tbody + tbody {
  border-color: #eff3e7;
}

.table-hover .table-dbsl:hover {
  background-color: #ebefe1;
}
.table-hover .table-dbsl:hover > td,
.table-hover .table-dbsl:hover > th {
  background-color: #ebefe1;
}

.table-superadmin,
.table-superadmin > th,
.table-superadmin > td {
  background-color: #ccddea;
}
.table-superadmin th,
.table-superadmin td,
.table-superadmin thead th,
.table-superadmin tbody + tbody {
  border-color: #a0c0d7;
}

.table-hover .table-superadmin:hover {
  background-color: #bad1e3;
}
.table-hover .table-superadmin:hover > td,
.table-hover .table-superadmin:hover > th {
  background-color: #bad1e3;
}

.table-admin,
.table-admin > th,
.table-admin > td {
  background-color: #d8d1ea;
}
.table-admin th,
.table-admin td,
.table-admin thead th,
.table-admin tbody + tbody {
  border-color: #b7a9d7;
}

.table-hover .table-admin:hover {
  background-color: #c9bfe2;
}
.table-hover .table-admin:hover > td,
.table-hover .table-admin:hover > th {
  background-color: #c9bfe2;
}

.table-supporter,
.table-supporter > th,
.table-supporter > td {
  background-color: #eacee6;
}
.table-supporter th,
.table-supporter td,
.table-supporter thead th,
.table-supporter tbody + tbody {
  border-color: #d7a4d1;
}

.table-hover .table-supporter:hover {
  background-color: #e2bcdd;
}
.table-hover .table-supporter:hover > td,
.table-hover .table-supporter:hover > th {
  background-color: #e2bcdd;
}

.table-appliedfor,
.table-appliedfor > th,
.table-appliedfor > td {
  background-color: #f4c4dc;
}
.table-appliedfor th,
.table-appliedfor td,
.table-appliedfor thead th,
.table-appliedfor tbody + tbody {
  border-color: #ea92bd;
}

.table-hover .table-appliedfor:hover {
  background-color: #f0afcf;
}
.table-hover .table-appliedfor:hover > td,
.table-hover .table-appliedfor:hover > th {
  background-color: #f0afcf;
}

.table-appliedforl,
.table-appliedforl > th,
.table-appliedforl > td {
  background-color: #f4c4dc;
}
.table-appliedforl th,
.table-appliedforl td,
.table-appliedforl thead th,
.table-appliedforl tbody + tbody {
  border-color: #ea92bd;
}

.table-hover .table-appliedforl:hover {
  background-color: #f0afcf;
}
.table-hover .table-appliedforl:hover > td,
.table-hover .table-appliedforl:hover > th {
  background-color: #f0afcf;
}

.table-available,
.table-available > th,
.table-available > td {
  background-color: #f3fff3;
}
.table-available th,
.table-available td,
.table-available thead th,
.table-available tbody + tbody {
  border-color: #e9ffe9;
}

.table-hover .table-available:hover {
  background-color: #daffda;
}
.table-hover .table-available:hover > td,
.table-hover .table-available:hover > th {
  background-color: #daffda;
}

.table-totest,
.table-totest > th,
.table-totest > td {
  background-color: #dadcf4;
}
.table-totest th,
.table-totest td,
.table-totest thead th,
.table-totest tbody + tbody {
  border-color: #babeea;
}

.table-hover .table-totest:hover {
  background-color: #c6c9ee;
}
.table-hover .table-totest:hover > td,
.table-hover .table-totest:hover > th {
  background-color: #c6c9ee;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #d9f2d9;
}
.table-active th,
.table-active td,
.table-active thead th,
.table-active tbody + tbody {
  border-color: #b8e7b8;
}

.table-hover .table-active:hover {
  background-color: #c6ecc6;
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #c6ecc6;
}

.table-inactive,
.table-inactive > th,
.table-inactive > td {
  background-color: #f2d9d9;
}
.table-inactive th,
.table-inactive td,
.table-inactive thead th,
.table-inactive tbody + tbody {
  border-color: #e7b8b8;
}

.table-hover .table-inactive:hover {
  background-color: #ecc6c6;
}
.table-hover .table-inactive:hover > td,
.table-hover .table-inactive:hover > th {
  background-color: #ecc6c6;
}

.table-attention,
.table-attention > th,
.table-attention > td {
  background-color: #fbf9be;
}
.table-attention th,
.table-attention td,
.table-attention thead th,
.table-attention tbody + tbody {
  border-color: #f7f486;
}

.table-hover .table-attention:hover {
  background-color: #faf7a6;
}
.table-hover .table-attention:hover > td,
.table-hover .table-attention:hover > th {
  background-color: #faf7a6;
}

.table-closed,
.table-closed > th,
.table-closed > td {
  background-color: #fbcfce;
}
.table-closed th,
.table-closed td,
.table-closed thead th,
.table-closed tbody + tbody {
  border-color: #f7a6a5;
}

.table-hover .table-closed:hover {
  background-color: #f9b8b6;
}
.table-hover .table-closed:hover > td,
.table-hover .table-closed:hover > th {
  background-color: #f9b8b6;
}

.table-help,
.table-help > th,
.table-help > td {
  background-color: #ebfbd0;
}
.table-help th,
.table-help td,
.table-help thead th,
.table-help tbody + tbody {
  border-color: #daf7a7;
}

.table-hover .table-help:hover {
  background-color: #e1f9b9;
}
.table-hover .table-help:hover > td,
.table-hover .table-help:hover > th {
  background-color: #e1f9b9;
}

.table-expired,
.table-expired > th,
.table-expired > td {
  background-color: #f4c4cb;
}
.table-expired th,
.table-expired td,
.table-expired thead th,
.table-expired tbody + tbody {
  border-color: #ea919f;
}

.table-hover .table-expired:hover {
  background-color: #f0afb8;
}
.table-hover .table-expired:hover > td,
.table-hover .table-expired:hover > th {
  background-color: #f0afb8;
}

.table-abandoned,
.table-abandoned > th,
.table-abandoned > td {
  background-color: #eac7c6;
}
.table-abandoned th,
.table-abandoned td,
.table-abandoned thead th,
.table-abandoned tbody + tbody {
  border-color: #d79796;
}

.table-hover .table-abandoned:hover {
  background-color: #e3b5b3;
}
.table-hover .table-abandoned:hover > td,
.table-hover .table-abandoned:hover > th {
  background-color: #e3b5b3;
}

.table-cmd,
.table-cmd > th,
.table-cmd > td {
  background-color: #b8dbc0;
}
.table-cmd th,
.table-cmd td,
.table-cmd thead th,
.table-cmd tbody + tbody {
  border-color: #7abd89;
}

.table-hover .table-cmd:hover {
  background-color: #a7d2b1;
}
.table-hover .table-cmd:hover > td,
.table-hover .table-cmd:hover > th {
  background-color: #a7d2b1;
}

.table-sysadmin,
.table-sysadmin > th,
.table-sysadmin > td {
  background-color: #f4bfff;
}
.table-sysadmin th,
.table-sysadmin td,
.table-sysadmin thead th,
.table-sysadmin tbody + tbody {
  border-color: #ea89ff;
}

.table-hover .table-sysadmin:hover {
  background-color: #f0a6ff;
}
.table-hover .table-sysadmin:hover > td,
.table-hover .table-sysadmin:hover > th {
  background-color: #f0a6ff;
}

.table-super,
.table-super > th,
.table-super > td {
  background-color: #edc8ff;
}
.table-super th,
.table-super td,
.table-super thead th,
.table-super tbody + tbody {
  border-color: #dd99ff;
}

.table-hover .table-super:hover {
  background-color: #e5afff;
}
.table-hover .table-super:hover > td,
.table-hover .table-super:hover > th {
  background-color: #e5afff;
}

.table-dns,
.table-dns > th,
.table-dns > td {
  background-color: #dbbfc2;
}
.table-dns th,
.table-dns td,
.table-dns thead th,
.table-dns tbody + tbody {
  border-color: #bd898d;
}

.table-hover .table-dns:hover {
  background-color: #d2afb2;
}
.table-hover .table-dns:hover > td,
.table-hover .table-dns:hover > th {
  background-color: #d2afb2;
}

.table-network,
.table-network > th,
.table-network > td {
  background-color: #bacffc;
}
.table-network th,
.table-network td,
.table-network thead th,
.table-network tbody + tbody {
  border-color: #7fa6f9;
}

.table-hover .table-network:hover {
  background-color: #a2befb;
}
.table-hover .table-network:hover > td,
.table-hover .table-network:hover > th {
  background-color: #a2befb;
}

.table-backups,
.table-backups > th,
.table-backups > td {
  background-color: #c2dbc8;
}
.table-backups th,
.table-backups td,
.table-backups thead th,
.table-backups tbody + tbody {
  border-color: #8ebd99;
}

.table-hover .table-backups:hover {
  background-color: #b2d2ba;
}
.table-hover .table-backups:hover > td,
.table-hover .table-backups:hover > th {
  background-color: #b2d2ba;
}

.table-hardware,
.table-hardware > th,
.table-hardware > td {
  background-color: #f4c1c8;
}
.table-hardware th,
.table-hardware td,
.table-hardware thead th,
.table-hardware tbody + tbody {
  border-color: #ea8c99;
}

.table-hover .table-hardware:hover {
  background-color: #f0abb5;
}
.table-hover .table-hardware:hover > td,
.table-hover .table-hardware:hover > th {
  background-color: #f0abb5;
}

.table-people,
.table-people > th,
.table-people > td {
  background-color: #c7e7c8;
}
.table-people th,
.table-people td,
.table-people thead th,
.table-people tbody + tbody {
  border-color: #97d399;
}

.table-hover .table-people:hover {
  background-color: #b5dfb6;
}
.table-hover .table-people:hover > td,
.table-hover .table-people:hover > th {
  background-color: #b5dfb6;
}

.table-business,
.table-business > th,
.table-business > td {
  background-color: #bac8db;
}
.table-business th,
.table-business td,
.table-business thead th,
.table-business tbody + tbody {
  border-color: #7f99bc;
}

.table-hover .table-business:hover {
  background-color: #a9bbd2;
}
.table-hover .table-business:hover > td,
.table-hover .table-business:hover > th {
  background-color: #a9bbd2;
}

.table-manager,
.table-manager > th,
.table-manager > td {
  background-color: #d0c0db;
}
.table-manager th,
.table-manager td,
.table-manager thead th,
.table-manager tbody + tbody {
  border-color: #a88abd;
}

.table-hover .table-manager:hover {
  background-color: #c4b0d2;
}
.table-hover .table-manager:hover > td,
.table-hover .table-manager:hover > th {
  background-color: #c4b0d2;
}

.table-safezone,
.table-safezone > th,
.table-safezone > td {
  background-color: #c8bde9;
}
.table-safezone th,
.table-safezone td,
.table-safezone thead th,
.table-safezone tbody + tbody {
  border-color: #9985d7;
}

.table-hover .table-safezone:hover {
  background-color: #b8aae3;
}
.table-hover .table-safezone:hover > td,
.table-hover .table-safezone:hover > th {
  background-color: #b8aae3;
}

.table-contract,
.table-contract > th,
.table-contract > td {
  background-color: #e9cec2;
}
.table-contract th,
.table-contract td,
.table-contract thead th,
.table-contract tbody + tbody {
  border-color: #d7a58e;
}

.table-hover .table-contract:hover {
  background-color: #e2bfaf;
}
.table-hover .table-contract:hover > td,
.table-hover .table-contract:hover > th {
  background-color: #e2bfaf;
}

.table-forest,
.table-forest > th,
.table-forest > td {
  background-color: #bdc9bd;
}
.table-forest th,
.table-forest td,
.table-forest thead th,
.table-forest tbody + tbody {
  border-color: #859b85;
}

.table-hover .table-forest:hover {
  background-color: #afbeaf;
}
.table-hover .table-forest:hover > td,
.table-hover .table-forest:hover > th {
  background-color: #afbeaf;
}

.table-land,
.table-land > th,
.table-land > td {
  background-color: #b8dbc0;
}
.table-land th,
.table-land td,
.table-land thead th,
.table-land tbody + tbody {
  border-color: #7abd89;
}

.table-hover .table-land:hover {
  background-color: #a7d2b1;
}
.table-hover .table-land:hover > td,
.table-hover .table-land:hover > th {
  background-color: #a7d2b1;
}

.table-safety,
.table-safety > th,
.table-safety > td {
  background-color: #dbc1c0;
}
.table-safety th,
.table-safety td,
.table-safety thead th,
.table-safety tbody + tbody {
  border-color: #bd8c8b;
}

.table-hover .table-safety:hover {
  background-color: #d2b1b0;
}
.table-hover .table-safety:hover > td,
.table-hover .table-safety:hover > th {
  background-color: #d2b1b0;
}

.table-contractor,
.table-contractor > th,
.table-contractor > td {
  background-color: #dbc0c8;
}
.table-contractor th,
.table-contractor td,
.table-contractor thead th,
.table-contractor tbody + tbody {
  border-color: #bd8999;
}

.table-hover .table-contractor:hover {
  background-color: #d2b0ba;
}
.table-hover .table-contractor:hover > td,
.table-hover .table-contractor:hover > th {
  background-color: #d2b0ba;
}

.table-sub,
.table-sub > th,
.table-sub > td {
  background-color: #c5c6db;
}
.table-sub th,
.table-sub td,
.table-sub thead th,
.table-sub tbody + tbody {
  border-color: #9396bd;
}

.table-hover .table-sub:hover {
  background-color: #b5b7d1;
}
.table-hover .table-sub:hover > td,
.table-hover .table-sub:hover > th {
  background-color: #b5b7d1;
}

.table-workcrew,
.table-workcrew > th,
.table-workcrew > td {
  background-color: #cec6dd;
}
.table-workcrew th,
.table-workcrew td,
.table-workcrew thead th,
.table-workcrew tbody + tbody {
  border-color: #a396c1;
}

.table-hover .table-workcrew:hover {
  background-color: #c0b6d3;
}
.table-hover .table-workcrew:hover > td,
.table-hover .table-workcrew:hover > th {
  background-color: #c0b6d3;
}

.table-foreman,
.table-foreman > th,
.table-foreman > td {
  background-color: #ccc3db;
}
.table-foreman th,
.table-foreman td,
.table-foreman thead th,
.table-foreman tbody + tbody {
  border-color: #a08fbd;
}

.table-hover .table-foreman:hover {
  background-color: #beb3d1;
}
.table-hover .table-foreman:hover > td,
.table-hover .table-foreman:hover > th {
  background-color: #beb3d1;
}

.table-crew,
.table-crew > th,
.table-crew > td {
  background-color: #cac1dd;
}
.table-crew th,
.table-crew td,
.table-crew thead th,
.table-crew tbody + tbody {
  border-color: #9c8cc1;
}

.table-hover .table-crew:hover {
  background-color: #bcb1d4;
}
.table-hover .table-crew:hover > td,
.table-hover .table-crew:hover > th {
  background-color: #bcb1d4;
}

.table-truck,
.table-truck > th,
.table-truck > td {
  background-color: #fec1ca;
}
.table-truck th,
.table-truck td,
.table-truck thead th,
.table-truck tbody + tbody {
  border-color: #fe8c9d;
}

.table-hover .table-truck:hover {
  background-color: #fea8b5;
}
.table-hover .table-truck:hover > td,
.table-hover .table-truck:hover > th {
  background-color: #fea8b5;
}

.table-visitor,
.table-visitor > th,
.table-visitor > td {
  background-color: #ffc5c5;
}
.table-visitor th,
.table-visitor td,
.table-visitor thead th,
.table-visitor tbody + tbody {
  border-color: #ff9494;
}

.table-hover .table-visitor:hover {
  background-color: #ffacac;
}
.table-hover .table-visitor:hover > td,
.table-hover .table-visitor:hover > th {
  background-color: #ffacac;
}

.table-workday,
.table-workday > th,
.table-workday > td {
  background-color: #ffe1c8;
}
.table-workday th,
.table-workday td,
.table-workday thead th,
.table-workday tbody + tbody {
  border-color: #ffc799;
}

.table-hover .table-workday:hover {
  background-color: #ffd3af;
}
.table-hover .table-workday:hover > td,
.table-hover .table-workday:hover > th {
  background-color: #ffd3af;
}

.table-worksite,
.table-worksite > th,
.table-worksite > td {
  background-color: #fed2cf;
}
.table-worksite th,
.table-worksite td,
.table-worksite thead th,
.table-worksite tbody + tbody {
  border-color: #feaba6;
}

.table-hover .table-worksite:hover {
  background-color: #fdbbb6;
}
.table-hover .table-worksite:hover > td,
.table-hover .table-worksite:hover > th {
  background-color: #fdbbb6;
}

.table-welcome,
.table-welcome > th,
.table-welcome > td {
  background-color: #b8cae7;
}
.table-welcome th,
.table-welcome td,
.table-welcome thead th,
.table-welcome tbody + tbody {
  border-color: #7c9dd2;
}

.table-hover .table-welcome:hover {
  background-color: #a5bce1;
}
.table-hover .table-welcome:hover > td,
.table-hover .table-welcome:hover > th {
  background-color: #a5bce1;
}

.table-error,
.table-error > th,
.table-error > td {
  background-color: #febac1;
}
.table-error th,
.table-error td,
.table-error thead th,
.table-error tbody + tbody {
  border-color: #fe7f8c;
}

.table-hover .table-error:hover {
  background-color: #fea1aa;
}
.table-hover .table-error:hover > td,
.table-hover .table-error:hover > th {
  background-color: #fea1aa;
}

.table-good,
.table-good > th,
.table-good > td {
  background-color: #c8dfc8;
}
.table-good th,
.table-good td,
.table-good thead th,
.table-good tbody + tbody {
  border-color: #99c399;
}

.table-hover .table-good:hover {
  background-color: #b8d6b8;
}
.table-hover .table-good:hover > td,
.table-hover .table-good:hover > th {
  background-color: #b8d6b8;
}

.table-zero,
.table-zero > th,
.table-zero > td {
  background-color: whitesmoke;
}
.table-zero th,
.table-zero td,
.table-zero thead th,
.table-zero tbody + tbody {
  border-color: #ededed;
}

.table-hover .table-zero:hover {
  background-color: #e8e8e8;
}
.table-hover .table-zero:hover > td,
.table-hover .table-zero:hover > th {
  background-color: #e8e8e8;
}

.table-eee,
.table-eee > th,
.table-eee > td {
  background-color: #fefefe;
}
.table-eee th,
.table-eee td,
.table-eee thead th,
.table-eee tbody + tbody {
  border-color: #fcfcfc;
}

.table-hover .table-eee:hover {
  background-color: #f1f1f1;
}
.table-hover .table-eee:hover > td,
.table-hover .table-eee:hover > th {
  background-color: #f1f1f1;
}

.table-none,
.table-none > th,
.table-none > td {
  background-color: white;
}
.table-none th,
.table-none td,
.table-none thead th,
.table-none tbody + tbody {
  border-color: white;
}

.table-hover .table-none:hover {
  background-color: #f2f2f2;
}
.table-hover .table-none:hover > td,
.table-hover .table-none:hover > th {
  background-color: #f2f2f2;
}

.table-delete,
.table-delete > th,
.table-delete > td {
  background-color: #deb8b8;
}
.table-delete th,
.table-delete td,
.table-delete thead th,
.table-delete tbody + tbody {
  border-color: #c27c7c;
}

.table-hover .table-delete:hover {
  background-color: #d6a7a7;
}
.table-hover .table-delete:hover > td,
.table-hover .table-delete:hover > th {
  background-color: #d6a7a7;
}

.table-lightgray,
.table-lightgray > th,
.table-lightgray > td {
  background-color: #e2e2e2;
}
.table-lightgray th,
.table-lightgray td,
.table-lightgray thead th,
.table-lightgray tbody + tbody {
  border-color: #cacaca;
}

.table-hover .table-lightgray:hover {
  background-color: #d5d5d5;
}
.table-hover .table-lightgray:hover > td,
.table-hover .table-lightgray:hover > th {
  background-color: #d5d5d5;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-journey {
  color: #fff;
  background-color: #4687c7;
  border-color: #4687c7;
}
.btn-journey:hover {
  color: #fff;
  background-color: #3674b1;
  border-color: #336da7;
}
.btn-journey:focus, .btn-journey.focus {
  color: #fff;
  background-color: #3674b1;
  border-color: #336da7;
  box-shadow: 0 0 0 0.2rem rgba(98, 153, 207, 0.5);
}
.btn-journey.disabled, .btn-journey:disabled {
  color: #fff;
  background-color: #4687c7;
  border-color: #4687c7;
}
.btn-journey:not(:disabled):not(.disabled):active, .btn-journey:not(:disabled):not(.disabled).active, .show > .btn-journey.dropdown-toggle {
  color: #fff;
  background-color: #336da7;
  border-color: #30679e;
}
.btn-journey:not(:disabled):not(.disabled):active:focus, .btn-journey:not(:disabled):not(.disabled).active:focus, .show > .btn-journey.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(98, 153, 207, 0.5);
}

.btn-kokua {
  color: #fff;
  background-color: #3378b3;
  border-color: #3378b3;
}
.btn-kokua:hover {
  color: #fff;
  background-color: #2b6495;
  border-color: #285d8b;
}
.btn-kokua:focus, .btn-kokua.focus {
  color: #fff;
  background-color: #2b6495;
  border-color: #285d8b;
  box-shadow: 0 0 0 0.2rem rgba(82, 140, 190, 0.5);
}
.btn-kokua.disabled, .btn-kokua:disabled {
  color: #fff;
  background-color: #3378b3;
  border-color: #3378b3;
}
.btn-kokua:not(:disabled):not(.disabled):active, .btn-kokua:not(:disabled):not(.disabled).active, .show > .btn-kokua.dropdown-toggle {
  color: #fff;
  background-color: #285d8b;
  border-color: #255781;
}
.btn-kokua:not(:disabled):not(.disabled):active:focus, .btn-kokua:not(:disabled):not(.disabled).active:focus, .show > .btn-kokua.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 140, 190, 0.5);
}

.btn-kokual {
  color: #fff;
  background-color: rgba(84, 120, 191, 0.2);
  border-color: rgba(84, 120, 191, 0.2);
}
.btn-kokual:hover {
  color: #fff;
  background-color: rgba(64, 101, 172, 0.2);
  border-color: rgba(61, 95, 163, 0.2);
}
.btn-kokual:focus, .btn-kokual.focus {
  color: #fff;
  background-color: rgba(64, 101, 172, 0.2);
  border-color: rgba(61, 95, 163, 0.2);
  box-shadow: 0 0 0 0.2rem rgba(189, 203, 230, 0.5);
}
.btn-kokual.disabled, .btn-kokual:disabled {
  color: #fff;
  background-color: rgba(84, 120, 191, 0.2);
  border-color: rgba(84, 120, 191, 0.2);
}
.btn-kokual:not(:disabled):not(.disabled):active, .btn-kokual:not(:disabled):not(.disabled).active, .show > .btn-kokual.dropdown-toggle {
  color: #fff;
  background-color: rgba(61, 95, 163, 0.2);
  border-color: rgba(58, 90, 154, 0.2);
}
.btn-kokual:not(:disabled):not(.disabled):active:focus, .btn-kokual:not(:disabled):not(.disabled).active:focus, .show > .btn-kokual.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(189, 203, 230, 0.5);
}

.btn-kōkua {
  color: #fff;
  background-color: #3378b3;
  border-color: #3378b3;
}
.btn-kōkua:hover {
  color: #fff;
  background-color: #2b6495;
  border-color: #285d8b;
}
.btn-kōkua:focus, .btn-kōkua.focus {
  color: #fff;
  background-color: #2b6495;
  border-color: #285d8b;
  box-shadow: 0 0 0 0.2rem rgba(82, 140, 190, 0.5);
}
.btn-kōkua.disabled, .btn-kōkua:disabled {
  color: #fff;
  background-color: #3378b3;
  border-color: #3378b3;
}
.btn-kōkua:not(:disabled):not(.disabled):active, .btn-kōkua:not(:disabled):not(.disabled).active, .show > .btn-kōkua.dropdown-toggle {
  color: #fff;
  background-color: #285d8b;
  border-color: #255781;
}
.btn-kōkua:not(:disabled):not(.disabled):active:focus, .btn-kōkua:not(:disabled):not(.disabled).active:focus, .show > .btn-kōkua.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 140, 190, 0.5);
}

.btn-ohana {
  color: #fff;
  background-color: #3e6bad;
  border-color: #3e6bad;
}
.btn-ohana:hover {
  color: #fff;
  background-color: #345a91;
  border-color: #315487;
}
.btn-ohana:focus, .btn-ohana.focus {
  color: #fff;
  background-color: #345a91;
  border-color: #315487;
  box-shadow: 0 0 0 0.2rem rgba(91, 129, 185, 0.5);
}
.btn-ohana.disabled, .btn-ohana:disabled {
  color: #fff;
  background-color: #3e6bad;
  border-color: #3e6bad;
}
.btn-ohana:not(:disabled):not(.disabled):active, .btn-ohana:not(:disabled):not(.disabled).active, .show > .btn-ohana.dropdown-toggle {
  color: #fff;
  background-color: #315487;
  border-color: #2d4e7e;
}
.btn-ohana:not(:disabled):not(.disabled):active:focus, .btn-ohana:not(:disabled):not(.disabled).active:focus, .show > .btn-ohana.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(91, 129, 185, 0.5);
}

.btn-ohanal {
  color: #212529;
  background-color: rgba(141, 208, 89, 0.2);
  border-color: rgba(141, 208, 89, 0.2);
}
.btn-ohanal:hover {
  color: #212529;
  background-color: rgba(121, 200, 59, 0.2);
  border-color: rgba(114, 192, 54, 0.2);
}
.btn-ohanal:focus, .btn-ohanal.focus {
  color: #212529;
  background-color: rgba(121, 200, 59, 0.2);
  border-color: rgba(114, 192, 54, 0.2);
  box-shadow: 0 0 0 0.2rem rgba(75, 103, 60, 0.5);
}
.btn-ohanal.disabled, .btn-ohanal:disabled {
  color: #212529;
  background-color: rgba(141, 208, 89, 0.2);
  border-color: rgba(141, 208, 89, 0.2);
}
.btn-ohanal:not(:disabled):not(.disabled):active, .btn-ohanal:not(:disabled):not(.disabled).active, .show > .btn-ohanal.dropdown-toggle {
  color: #212529;
  background-color: rgba(114, 192, 54, 0.2);
  border-color: rgba(108, 182, 51, 0.2);
}
.btn-ohanal:not(:disabled):not(.disabled):active:focus, .btn-ohanal:not(:disabled):not(.disabled).active:focus, .show > .btn-ohanal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(75, 103, 60, 0.5);
}

.btn-kula {
  color: #fff;
  background-color: #668712;
  border-color: #668712;
}
.btn-kula:hover {
  color: #fff;
  background-color: #4d650e;
  border-color: #445a0c;
}
.btn-kula:focus, .btn-kula.focus {
  color: #fff;
  background-color: #4d650e;
  border-color: #445a0c;
  box-shadow: 0 0 0 0.2rem rgba(125, 153, 54, 0.5);
}
.btn-kula.disabled, .btn-kula:disabled {
  color: #fff;
  background-color: #668712;
  border-color: #668712;
}
.btn-kula:not(:disabled):not(.disabled):active, .btn-kula:not(:disabled):not(.disabled).active, .show > .btn-kula.dropdown-toggle {
  color: #fff;
  background-color: #445a0c;
  border-color: #3c4f0b;
}
.btn-kula:not(:disabled):not(.disabled):active:focus, .btn-kula:not(:disabled):not(.disabled).active:focus, .show > .btn-kula.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 153, 54, 0.5);
}

.btn-kulal {
  color: #fff;
  background-color: rgba(102, 135, 18, 0.2);
  border-color: rgba(102, 135, 18, 0.2);
}
.btn-kulal:hover {
  color: #fff;
  background-color: rgba(77, 101, 14, 0.2);
  border-color: rgba(68, 90, 12, 0.2);
}
.btn-kulal:focus, .btn-kulal.focus {
  color: #fff;
  background-color: rgba(77, 101, 14, 0.2);
  border-color: rgba(68, 90, 12, 0.2);
  box-shadow: 0 0 0 0.2rem rgba(196, 209, 163, 0.5);
}
.btn-kulal.disabled, .btn-kulal:disabled {
  color: #fff;
  background-color: rgba(102, 135, 18, 0.2);
  border-color: rgba(102, 135, 18, 0.2);
}
.btn-kulal:not(:disabled):not(.disabled):active, .btn-kulal:not(:disabled):not(.disabled).active, .show > .btn-kulal.dropdown-toggle {
  color: #fff;
  background-color: rgba(68, 90, 12, 0.2);
  border-color: rgba(60, 79, 11, 0.2);
}
.btn-kulal:not(:disabled):not(.disabled):active:focus, .btn-kulal:not(:disabled):not(.disabled).active:focus, .show > .btn-kulal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 209, 163, 0.5);
}

.btn-hui {
  color: #fff;
  background-color: #093a7f;
  border-color: #093a7f;
}
.btn-hui:hover {
  color: #fff;
  background-color: #062a5b;
  border-color: #06244f;
}
.btn-hui:focus, .btn-hui.focus {
  color: #fff;
  background-color: #062a5b;
  border-color: #06244f;
  box-shadow: 0 0 0 0.2rem rgba(46, 88, 146, 0.5);
}
.btn-hui.disabled, .btn-hui:disabled {
  color: #fff;
  background-color: #093a7f;
  border-color: #093a7f;
}
.btn-hui:not(:disabled):not(.disabled):active, .btn-hui:not(:disabled):not(.disabled).active, .show > .btn-hui.dropdown-toggle {
  color: #fff;
  background-color: #06244f;
  border-color: #051f43;
}
.btn-hui:not(:disabled):not(.disabled):active:focus, .btn-hui:not(:disabled):not(.disabled).active:focus, .show > .btn-hui.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(46, 88, 146, 0.5);
}

.btn-huil {
  color: #fff;
  background-color: rgba(9, 58, 127, 0.2);
  border-color: rgba(9, 58, 127, 0.2);
}
.btn-huil:hover {
  color: #fff;
  background-color: rgba(6, 42, 91, 0.2);
  border-color: rgba(6, 36, 79, 0.2);
}
.btn-huil:focus, .btn-huil.focus {
  color: #fff;
  background-color: rgba(6, 42, 91, 0.2);
  border-color: rgba(6, 36, 79, 0.2);
  box-shadow: 0 0 0 0.2rem rgba(160, 179, 206, 0.5);
}
.btn-huil.disabled, .btn-huil:disabled {
  color: #fff;
  background-color: rgba(9, 58, 127, 0.2);
  border-color: rgba(9, 58, 127, 0.2);
}
.btn-huil:not(:disabled):not(.disabled):active, .btn-huil:not(:disabled):not(.disabled).active, .show > .btn-huil.dropdown-toggle {
  color: #fff;
  background-color: rgba(6, 36, 79, 0.2);
  border-color: rgba(5, 31, 67, 0.2);
}
.btn-huil:not(:disabled):not(.disabled):active:focus, .btn-huil:not(:disabled):not(.disabled).active:focus, .show > .btn-huil.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(160, 179, 206, 0.5);
}

.btn-dbs {
  color: #fff;
  background-color: #668712;
  border-color: #668712;
}
.btn-dbs:hover {
  color: #fff;
  background-color: #4d650e;
  border-color: #445a0c;
}
.btn-dbs:focus, .btn-dbs.focus {
  color: #fff;
  background-color: #4d650e;
  border-color: #445a0c;
  box-shadow: 0 0 0 0.2rem rgba(125, 153, 54, 0.5);
}
.btn-dbs.disabled, .btn-dbs:disabled {
  color: #fff;
  background-color: #668712;
  border-color: #668712;
}
.btn-dbs:not(:disabled):not(.disabled):active, .btn-dbs:not(:disabled):not(.disabled).active, .show > .btn-dbs.dropdown-toggle {
  color: #fff;
  background-color: #445a0c;
  border-color: #3c4f0b;
}
.btn-dbs:not(:disabled):not(.disabled):active:focus, .btn-dbs:not(:disabled):not(.disabled).active:focus, .show > .btn-dbs.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 153, 54, 0.5);
}

.btn-dbalpha {
  color: #fff;
  background-color: #998712;
  border-color: #998712;
}
.btn-dbalpha:hover {
  color: #fff;
  background-color: #77690e;
  border-color: #6b5f0d;
}
.btn-dbalpha:focus, .btn-dbalpha.focus {
  color: #fff;
  background-color: #77690e;
  border-color: #6b5f0d;
  box-shadow: 0 0 0 0.2rem rgba(168, 153, 54, 0.5);
}
.btn-dbalpha.disabled, .btn-dbalpha:disabled {
  color: #fff;
  background-color: #998712;
  border-color: #998712;
}
.btn-dbalpha:not(:disabled):not(.disabled):active, .btn-dbalpha:not(:disabled):not(.disabled).active, .show > .btn-dbalpha.dropdown-toggle {
  color: #fff;
  background-color: #6b5f0d;
  border-color: #60550b;
}
.btn-dbalpha:not(:disabled):not(.disabled):active:focus, .btn-dbalpha:not(:disabled):not(.disabled).active:focus, .show > .btn-dbalpha.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(168, 153, 54, 0.5);
}

.btn-dbresource {
  color: #fff;
  background-color: #cc8712;
  border-color: #cc8712;
}
.btn-dbresource:hover {
  color: #fff;
  background-color: #a9700f;
  border-color: #9d680e;
}
.btn-dbresource:focus, .btn-dbresource.focus {
  color: #fff;
  background-color: #a9700f;
  border-color: #9d680e;
  box-shadow: 0 0 0 0.2rem rgba(212, 153, 54, 0.5);
}
.btn-dbresource.disabled, .btn-dbresource:disabled {
  color: #fff;
  background-color: #cc8712;
  border-color: #cc8712;
}
.btn-dbresource:not(:disabled):not(.disabled):active, .btn-dbresource:not(:disabled):not(.disabled).active, .show > .btn-dbresource.dropdown-toggle {
  color: #fff;
  background-color: #9d680e;
  border-color: #91600d;
}
.btn-dbresource:not(:disabled):not(.disabled):active:focus, .btn-dbresource:not(:disabled):not(.disabled).active:focus, .show > .btn-dbresource.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 153, 54, 0.5);
}

.btn-dbthing {
  color: #212529;
  background-color: #ff8712;
  border-color: #ff8712;
}
.btn-dbthing:hover {
  color: #fff;
  background-color: #eb7400;
  border-color: #de6e00;
}
.btn-dbthing:focus, .btn-dbthing.focus {
  color: #fff;
  background-color: #eb7400;
  border-color: #de6e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 120, 21, 0.5);
}
.btn-dbthing.disabled, .btn-dbthing:disabled {
  color: #212529;
  background-color: #ff8712;
  border-color: #ff8712;
}
.btn-dbthing:not(:disabled):not(.disabled):active, .btn-dbthing:not(:disabled):not(.disabled).active, .show > .btn-dbthing.dropdown-toggle {
  color: #fff;
  background-color: #de6e00;
  border-color: #d16700;
}
.btn-dbthing:not(:disabled):not(.disabled):active:focus, .btn-dbthing:not(:disabled):not(.disabled).active:focus, .show > .btn-dbthing.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 120, 21, 0.5);
}

.btn-dbsl {
  color: #212529;
  background-color: #e0e7d0;
  border-color: #e0e7d0;
}
.btn-dbsl:hover {
  color: #212529;
  background-color: #cfdab7;
  border-color: #cad6ae;
}
.btn-dbsl:focus, .btn-dbsl.focus {
  color: #212529;
  background-color: #cfdab7;
  border-color: #cad6ae;
  box-shadow: 0 0 0 0.2rem rgba(195, 202, 183, 0.5);
}
.btn-dbsl.disabled, .btn-dbsl:disabled {
  color: #212529;
  background-color: #e0e7d0;
  border-color: #e0e7d0;
}
.btn-dbsl:not(:disabled):not(.disabled):active, .btn-dbsl:not(:disabled):not(.disabled).active, .show > .btn-dbsl.dropdown-toggle {
  color: #212529;
  background-color: #cad6ae;
  border-color: #c4d1a6;
}
.btn-dbsl:not(:disabled):not(.disabled):active:focus, .btn-dbsl:not(:disabled):not(.disabled).active:focus, .show > .btn-dbsl.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(195, 202, 183, 0.5);
}

.btn-superadmin {
  color: #fff;
  background-color: #4886b3;
  border-color: #4886b3;
}
.btn-superadmin:hover {
  color: #fff;
  background-color: #3d7298;
  border-color: #396b8f;
}
.btn-superadmin:focus, .btn-superadmin.focus {
  color: #fff;
  background-color: #3d7298;
  border-color: #396b8f;
  box-shadow: 0 0 0 0.2rem rgba(99, 152, 190, 0.5);
}
.btn-superadmin.disabled, .btn-superadmin:disabled {
  color: #fff;
  background-color: #4886b3;
  border-color: #4886b3;
}
.btn-superadmin:not(:disabled):not(.disabled):active, .btn-superadmin:not(:disabled):not(.disabled).active, .show > .btn-superadmin.dropdown-toggle {
  color: #fff;
  background-color: #396b8f;
  border-color: #366486;
}
.btn-superadmin:not(:disabled):not(.disabled):active:focus, .btn-superadmin:not(:disabled):not(.disabled).active:focus, .show > .btn-superadmin.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 152, 190, 0.5);
}

.btn-admin {
  color: #fff;
  background-color: #745ab3;
  border-color: #745ab3;
}
.btn-admin:hover {
  color: #fff;
  background-color: #62499e;
  border-color: #5c4595;
}
.btn-admin:focus, .btn-admin.focus {
  color: #fff;
  background-color: #62499e;
  border-color: #5c4595;
  box-shadow: 0 0 0 0.2rem rgba(137, 115, 190, 0.5);
}
.btn-admin.disabled, .btn-admin:disabled {
  color: #fff;
  background-color: #745ab3;
  border-color: #745ab3;
}
.btn-admin:not(:disabled):not(.disabled):active, .btn-admin:not(:disabled):not(.disabled).active, .show > .btn-admin.dropdown-toggle {
  color: #fff;
  background-color: #5c4595;
  border-color: #57418d;
}
.btn-admin:not(:disabled):not(.disabled):active:focus, .btn-admin:not(:disabled):not(.disabled).active:focus, .show > .btn-admin.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(137, 115, 190, 0.5);
}

.btn-supporter {
  color: #fff;
  background-color: #B350A6;
  border-color: #B350A6;
}
.btn-supporter:hover {
  color: #fff;
  background-color: #9a438e;
  border-color: #913f86;
}
.btn-supporter:focus, .btn-supporter.focus {
  color: #fff;
  background-color: #9a438e;
  border-color: #913f86;
  box-shadow: 0 0 0 0.2rem rgba(190, 106, 179, 0.5);
}
.btn-supporter.disabled, .btn-supporter:disabled {
  color: #fff;
  background-color: #B350A6;
  border-color: #B350A6;
}
.btn-supporter:not(:disabled):not(.disabled):active, .btn-supporter:not(:disabled):not(.disabled).active, .show > .btn-supporter.dropdown-toggle {
  color: #fff;
  background-color: #913f86;
  border-color: #883b7e;
}
.btn-supporter:not(:disabled):not(.disabled):active:focus, .btn-supporter:not(:disabled):not(.disabled).active:focus, .show > .btn-supporter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(190, 106, 179, 0.5);
}

.btn-appliedfor {
  color: #fff;
  background-color: #d72e81;
  border-color: #d72e81;
}
.btn-appliedfor:hover {
  color: #fff;
  background-color: #bb246e;
  border-color: #b02268;
}
.btn-appliedfor:focus, .btn-appliedfor.focus {
  color: #fff;
  background-color: #bb246e;
  border-color: #b02268;
  box-shadow: 0 0 0 0.2rem rgba(221, 77, 148, 0.5);
}
.btn-appliedfor.disabled, .btn-appliedfor:disabled {
  color: #fff;
  background-color: #d72e81;
  border-color: #d72e81;
}
.btn-appliedfor:not(:disabled):not(.disabled):active, .btn-appliedfor:not(:disabled):not(.disabled).active, .show > .btn-appliedfor.dropdown-toggle {
  color: #fff;
  background-color: #b02268;
  border-color: #a62061;
}
.btn-appliedfor:not(:disabled):not(.disabled):active:focus, .btn-appliedfor:not(:disabled):not(.disabled).active:focus, .show > .btn-appliedfor.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 77, 148, 0.5);
}

.btn-appliedforl {
  color: #fff;
  background-color: #d72e81;
  border-color: #d72e81;
}
.btn-appliedforl:hover {
  color: #fff;
  background-color: #bb246e;
  border-color: #b02268;
}
.btn-appliedforl:focus, .btn-appliedforl.focus {
  color: #fff;
  background-color: #bb246e;
  border-color: #b02268;
  box-shadow: 0 0 0 0.2rem rgba(221, 77, 148, 0.5);
}
.btn-appliedforl.disabled, .btn-appliedforl:disabled {
  color: #fff;
  background-color: #d72e81;
  border-color: #d72e81;
}
.btn-appliedforl:not(:disabled):not(.disabled):active, .btn-appliedforl:not(:disabled):not(.disabled).active, .show > .btn-appliedforl.dropdown-toggle {
  color: #fff;
  background-color: #b02268;
  border-color: #a62061;
}
.btn-appliedforl:not(:disabled):not(.disabled):active:focus, .btn-appliedforl:not(:disabled):not(.disabled).active:focus, .show > .btn-appliedforl.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 77, 148, 0.5);
}

.btn-available {
  color: #212529;
  background-color: #d4ffd4;
  border-color: #d4ffd4;
}
.btn-available:hover {
  color: #212529;
  background-color: #aeffae;
  border-color: #a1ffa1;
}
.btn-available:focus, .btn-available.focus {
  color: #212529;
  background-color: #aeffae;
  border-color: #a1ffa1;
  box-shadow: 0 0 0 0.2rem rgba(185, 222, 186, 0.5);
}
.btn-available.disabled, .btn-available:disabled {
  color: #212529;
  background-color: #d4ffd4;
  border-color: #d4ffd4;
}
.btn-available:not(:disabled):not(.disabled):active, .btn-available:not(:disabled):not(.disabled).active, .show > .btn-available.dropdown-toggle {
  color: #212529;
  background-color: #a1ffa1;
  border-color: #94ff94;
}
.btn-available:not(:disabled):not(.disabled):active:focus, .btn-available:not(:disabled):not(.disabled).active:focus, .show > .btn-available.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(185, 222, 186, 0.5);
}

.btn-totest {
  color: #fff;
  background-color: #7a82d7;
  border-color: #7a82d7;
}
.btn-totest:hover {
  color: #fff;
  background-color: #5d66ce;
  border-color: #535dcb;
}
.btn-totest:focus, .btn-totest.focus {
  color: #fff;
  background-color: #5d66ce;
  border-color: #535dcb;
  box-shadow: 0 0 0 0.2rem rgba(142, 149, 221, 0.5);
}
.btn-totest.disabled, .btn-totest:disabled {
  color: #fff;
  background-color: #7a82d7;
  border-color: #7a82d7;
}
.btn-totest:not(:disabled):not(.disabled):active, .btn-totest:not(:disabled):not(.disabled).active, .show > .btn-totest.dropdown-toggle {
  color: #fff;
  background-color: #535dcb;
  border-color: #4954c8;
}
.btn-totest:not(:disabled):not(.disabled):active:focus, .btn-totest:not(:disabled):not(.disabled).active:focus, .show > .btn-totest.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(142, 149, 221, 0.5);
}

.btn-active {
  color: #212529;
  background-color: #77d077;
  border-color: #77d077;
}
.btn-active:hover {
  color: #212529;
  background-color: #5bc65b;
  border-color: #51c351;
}
.btn-active:focus, .btn-active.focus {
  color: #212529;
  background-color: #5bc65b;
  border-color: #51c351;
  box-shadow: 0 0 0 0.2rem rgba(106, 182, 107, 0.5);
}
.btn-active.disabled, .btn-active:disabled {
  color: #212529;
  background-color: #77d077;
  border-color: #77d077;
}
.btn-active:not(:disabled):not(.disabled):active, .btn-active:not(:disabled):not(.disabled).active, .show > .btn-active.dropdown-toggle {
  color: #fff;
  background-color: #51c351;
  border-color: #48c048;
}
.btn-active:not(:disabled):not(.disabled):active:focus, .btn-active:not(:disabled):not(.disabled).active:focus, .show > .btn-active.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(106, 182, 107, 0.5);
}

.btn-inactive {
  color: #fff;
  background-color: #d07777;
  border-color: #d07777;
}
.btn-inactive:hover {
  color: #fff;
  background-color: #c65b5b;
  border-color: #c35151;
}
.btn-inactive:focus, .btn-inactive.focus {
  color: #fff;
  background-color: #c65b5b;
  border-color: #c35151;
  box-shadow: 0 0 0 0.2rem rgba(215, 139, 139, 0.5);
}
.btn-inactive.disabled, .btn-inactive:disabled {
  color: #fff;
  background-color: #d07777;
  border-color: #d07777;
}
.btn-inactive:not(:disabled):not(.disabled):active, .btn-inactive:not(:disabled):not(.disabled).active, .show > .btn-inactive.dropdown-toggle {
  color: #fff;
  background-color: #c35151;
  border-color: #c04848;
}
.btn-inactive:not(:disabled):not(.disabled):active:focus, .btn-inactive:not(:disabled):not(.disabled).active:focus, .show > .btn-inactive.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 139, 139, 0.5);
}

.btn-attention {
  color: #212529;
  background-color: #efe916;
  border-color: #efe916;
}
.btn-attention:hover {
  color: #212529;
  background-color: #d0cb0e;
  border-color: #c5bf0d;
}
.btn-attention:focus, .btn-attention.focus {
  color: #212529;
  background-color: #d0cb0e;
  border-color: #c5bf0d;
  box-shadow: 0 0 0 0.2rem rgba(208, 204, 25, 0.5);
}
.btn-attention.disabled, .btn-attention:disabled {
  color: #212529;
  background-color: #efe916;
  border-color: #efe916;
}
.btn-attention:not(:disabled):not(.disabled):active, .btn-attention:not(:disabled):not(.disabled).active, .show > .btn-attention.dropdown-toggle {
  color: #212529;
  background-color: #c5bf0d;
  border-color: #b9b40d;
}
.btn-attention:not(:disabled):not(.disabled):active:focus, .btn-attention:not(:disabled):not(.disabled).active:focus, .show > .btn-attention.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(208, 204, 25, 0.5);
}

.btn-closed {
  color: #fff;
  background-color: #ef5451;
  border-color: #ef5451;
}
.btn-closed:hover {
  color: #fff;
  background-color: #ec322e;
  border-color: #eb2622;
}
.btn-closed:focus, .btn-closed.focus {
  color: #fff;
  background-color: #ec322e;
  border-color: #eb2622;
  box-shadow: 0 0 0 0.2rem rgba(241, 110, 107, 0.5);
}
.btn-closed.disabled, .btn-closed:disabled {
  color: #fff;
  background-color: #ef5451;
  border-color: #ef5451;
}
.btn-closed:not(:disabled):not(.disabled):active, .btn-closed:not(:disabled):not(.disabled).active, .show > .btn-closed.dropdown-toggle {
  color: #fff;
  background-color: #eb2622;
  border-color: #ea1b17;
}
.btn-closed:not(:disabled):not(.disabled):active:focus, .btn-closed:not(:disabled):not(.disabled).active:focus, .show > .btn-closed.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 110, 107, 0.5);
}

.btn-help {
  color: #212529;
  background-color: #b8ef56;
  border-color: #b8ef56;
}
.btn-help:hover {
  color: #212529;
  background-color: #a9ec33;
  border-color: #a4eb27;
}
.btn-help:focus, .btn-help.focus {
  color: #212529;
  background-color: #a9ec33;
  border-color: #a4eb27;
  box-shadow: 0 0 0 0.2rem rgba(161, 209, 79, 0.5);
}
.btn-help.disabled, .btn-help:disabled {
  color: #212529;
  background-color: #b8ef56;
  border-color: #b8ef56;
}
.btn-help:not(:disabled):not(.disabled):active, .btn-help:not(:disabled):not(.disabled).active, .show > .btn-help.dropdown-toggle {
  color: #212529;
  background-color: #a4eb27;
  border-color: #a0e91c;
}
.btn-help:not(:disabled):not(.disabled):active:focus, .btn-help:not(:disabled):not(.disabled).active:focus, .show > .btn-help.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(161, 209, 79, 0.5);
}

.btn-expired {
  color: #fff;
  background-color: #d72c47;
  border-color: #d72c47;
}
.btn-expired:hover {
  color: #fff;
  background-color: #ba233b;
  border-color: #af2138;
}
.btn-expired:focus, .btn-expired.focus {
  color: #fff;
  background-color: #ba233b;
  border-color: #af2138;
  box-shadow: 0 0 0 0.2rem rgba(221, 76, 99, 0.5);
}
.btn-expired.disabled, .btn-expired:disabled {
  color: #fff;
  background-color: #d72c47;
  border-color: #d72c47;
}
.btn-expired:not(:disabled):not(.disabled):active, .btn-expired:not(:disabled):not(.disabled).active, .show > .btn-expired.dropdown-toggle {
  color: #fff;
  background-color: #af2138;
  border-color: #a41f34;
}
.btn-expired:not(:disabled):not(.disabled):active:focus, .btn-expired:not(:disabled):not(.disabled).active:focus, .show > .btn-expired.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 76, 99, 0.5);
}

.btn-abandoned {
  color: #fff;
  background-color: #b33735;
  border-color: #b33735;
}
.btn-abandoned:hover {
  color: #fff;
  background-color: #952e2c;
  border-color: #8c2b29;
}
.btn-abandoned:focus, .btn-abandoned.focus {
  color: #fff;
  background-color: #952e2c;
  border-color: #8c2b29;
  box-shadow: 0 0 0 0.2rem rgba(190, 85, 83, 0.5);
}
.btn-abandoned.disabled, .btn-abandoned:disabled {
  color: #fff;
  background-color: #b33735;
  border-color: #b33735;
}
.btn-abandoned:not(:disabled):not(.disabled):active, .btn-abandoned:not(:disabled):not(.disabled).active, .show > .btn-abandoned.dropdown-toggle {
  color: #fff;
  background-color: #8c2b29;
  border-color: #822826;
}
.btn-abandoned:not(:disabled):not(.disabled):active:focus, .btn-abandoned:not(:disabled):not(.disabled).active:focus, .show > .btn-abandoned.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(190, 85, 83, 0.5);
}

.btn-cmd {
  color: #fff;
  background-color: #00801d;
  border-color: #00801d;
}
.btn-cmd:hover {
  color: #fff;
  background-color: #005a14;
  border-color: #004d11;
}
.btn-cmd:focus, .btn-cmd.focus {
  color: #fff;
  background-color: #005a14;
  border-color: #004d11;
  box-shadow: 0 0 0 0.2rem rgba(38, 147, 63, 0.5);
}
.btn-cmd.disabled, .btn-cmd:disabled {
  color: #fff;
  background-color: #00801d;
  border-color: #00801d;
}
.btn-cmd:not(:disabled):not(.disabled):active, .btn-cmd:not(:disabled):not(.disabled).active, .show > .btn-cmd.dropdown-toggle {
  color: #fff;
  background-color: #004d11;
  border-color: #00400f;
}
.btn-cmd:not(:disabled):not(.disabled):active:focus, .btn-cmd:not(:disabled):not(.disabled).active:focus, .show > .btn-cmd.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 147, 63, 0.5);
}

.btn-sysadmin {
  color: #fff;
  background-color: #d71cff;
  border-color: #d71cff;
}
.btn-sysadmin:hover {
  color: #fff;
  background-color: #ca00f5;
  border-color: #bf00e8;
}
.btn-sysadmin:focus, .btn-sysadmin.focus {
  color: #fff;
  background-color: #ca00f5;
  border-color: #bf00e8;
  box-shadow: 0 0 0 0.2rem rgba(221, 62, 255, 0.5);
}
.btn-sysadmin.disabled, .btn-sysadmin:disabled {
  color: #fff;
  background-color: #d71cff;
  border-color: #d71cff;
}
.btn-sysadmin:not(:disabled):not(.disabled):active, .btn-sysadmin:not(:disabled):not(.disabled).active, .show > .btn-sysadmin.dropdown-toggle {
  color: #fff;
  background-color: #bf00e8;
  border-color: #b500db;
}
.btn-sysadmin:not(:disabled):not(.disabled):active:focus, .btn-sysadmin:not(:disabled):not(.disabled).active:focus, .show > .btn-sysadmin.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 62, 255, 0.5);
}

.btn-super {
  color: #fff;
  background-color: #be3aff;
  border-color: #be3aff;
}
.btn-super:hover {
  color: #fff;
  background-color: #b114ff;
  border-color: #ad07ff;
}
.btn-super:focus, .btn-super.focus {
  color: #fff;
  background-color: #b114ff;
  border-color: #ad07ff;
  box-shadow: 0 0 0 0.2rem rgba(200, 88, 255, 0.5);
}
.btn-super.disabled, .btn-super:disabled {
  color: #fff;
  background-color: #be3aff;
  border-color: #be3aff;
}
.btn-super:not(:disabled):not(.disabled):active, .btn-super:not(:disabled):not(.disabled).active, .show > .btn-super.dropdown-toggle {
  color: #fff;
  background-color: #ad07ff;
  border-color: #a700f9;
}
.btn-super:not(:disabled):not(.disabled):active:focus, .btn-super:not(:disabled):not(.disabled).active:focus, .show > .btn-super.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(200, 88, 255, 0.5);
}

.btn-dns {
  color: #fff;
  background-color: #801c24;
  border-color: #801c24;
}
.btn-dns:hover {
  color: #fff;
  background-color: #61151b;
  border-color: #561318;
}
.btn-dns:focus, .btn-dns.focus {
  color: #fff;
  background-color: #61151b;
  border-color: #561318;
  box-shadow: 0 0 0 0.2rem rgba(147, 62, 69, 0.5);
}
.btn-dns.disabled, .btn-dns:disabled {
  color: #fff;
  background-color: #801c24;
  border-color: #801c24;
}
.btn-dns:not(:disabled):not(.disabled):active, .btn-dns:not(:disabled):not(.disabled).active, .show > .btn-dns.dropdown-toggle {
  color: #fff;
  background-color: #561318;
  border-color: #4c1115;
}
.btn-dns:not(:disabled):not(.disabled):active:focus, .btn-dns:not(:disabled):not(.disabled).active:focus, .show > .btn-dns.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 62, 69, 0.5);
}

.btn-network {
  color: #fff;
  background-color: #0954f4;
  border-color: #0954f4;
}
.btn-network:hover {
  color: #fff;
  background-color: #0847cf;
  border-color: #0743c3;
}
.btn-network:focus, .btn-network.focus {
  color: #fff;
  background-color: #0847cf;
  border-color: #0743c3;
  box-shadow: 0 0 0 0.2rem rgba(46, 110, 246, 0.5);
}
.btn-network.disabled, .btn-network:disabled {
  color: #fff;
  background-color: #0954f4;
  border-color: #0954f4;
}
.btn-network:not(:disabled):not(.disabled):active, .btn-network:not(:disabled):not(.disabled).active, .show > .btn-network.dropdown-toggle {
  color: #fff;
  background-color: #0743c3;
  border-color: #073fb7;
}
.btn-network:not(:disabled):not(.disabled):active:focus, .btn-network:not(:disabled):not(.disabled).active:focus, .show > .btn-network.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(46, 110, 246, 0.5);
}

.btn-backups {
  color: #fff;
  background-color: #26803b;
  border-color: #26803b;
}
.btn-backups:hover {
  color: #fff;
  background-color: #1d632d;
  border-color: #1a5929;
}
.btn-backups:focus, .btn-backups.focus {
  color: #fff;
  background-color: #1d632d;
  border-color: #1a5929;
  box-shadow: 0 0 0 0.2rem rgba(71, 147, 88, 0.5);
}
.btn-backups.disabled, .btn-backups:disabled {
  color: #fff;
  background-color: #26803b;
  border-color: #26803b;
}
.btn-backups:not(:disabled):not(.disabled):active, .btn-backups:not(:disabled):not(.disabled).active, .show > .btn-backups.dropdown-toggle {
  color: #fff;
  background-color: #1a5929;
  border-color: #174f24;
}
.btn-backups:not(:disabled):not(.disabled):active:focus, .btn-backups:not(:disabled):not(.disabled).active:focus, .show > .btn-backups.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 147, 88, 0.5);
}

.btn-hardware {
  color: #fff;
  background-color: #d7223b;
  border-color: #d7223b;
}
.btn-hardware:hover {
  color: #fff;
  background-color: #b61d32;
  border-color: #ab1b2f;
}
.btn-hardware:focus, .btn-hardware.focus {
  color: #fff;
  background-color: #b61d32;
  border-color: #ab1b2f;
  box-shadow: 0 0 0 0.2rem rgba(221, 67, 88, 0.5);
}
.btn-hardware.disabled, .btn-hardware:disabled {
  color: #fff;
  background-color: #d7223b;
  border-color: #d7223b;
}
.btn-hardware:not(:disabled):not(.disabled):active, .btn-hardware:not(:disabled):not(.disabled).active, .show > .btn-hardware.dropdown-toggle {
  color: #fff;
  background-color: #ab1b2f;
  border-color: #a0192c;
}
.btn-hardware:not(:disabled):not(.disabled):active:focus, .btn-hardware:not(:disabled):not(.disabled).active:focus, .show > .btn-hardware.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 67, 88, 0.5);
}

.btn-people {
  color: #fff;
  background-color: #37ab3a;
  border-color: #37ab3a;
}
.btn-people:hover {
  color: #fff;
  background-color: #2e8e30;
  border-color: #2b842d;
}
.btn-people:focus, .btn-people.focus {
  color: #fff;
  background-color: #2e8e30;
  border-color: #2b842d;
  box-shadow: 0 0 0 0.2rem rgba(85, 184, 88, 0.5);
}
.btn-people.disabled, .btn-people:disabled {
  color: #fff;
  background-color: #37ab3a;
  border-color: #37ab3a;
}
.btn-people:not(:disabled):not(.disabled):active, .btn-people:not(:disabled):not(.disabled).active, .show > .btn-people.dropdown-toggle {
  color: #fff;
  background-color: #2b842d;
  border-color: #277b2a;
}
.btn-people:not(:disabled):not(.disabled):active:focus, .btn-people:not(:disabled):not(.disabled).active:focus, .show > .btn-people.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(85, 184, 88, 0.5);
}

.btn-business {
  color: #fff;
  background-color: #093a7f;
  border-color: #093a7f;
}
.btn-business:hover {
  color: #fff;
  background-color: #062a5b;
  border-color: #06244f;
}
.btn-business:focus, .btn-business.focus {
  color: #fff;
  background-color: #062a5b;
  border-color: #06244f;
  box-shadow: 0 0 0 0.2rem rgba(46, 88, 146, 0.5);
}
.btn-business.disabled, .btn-business:disabled {
  color: #fff;
  background-color: #093a7f;
  border-color: #093a7f;
}
.btn-business:not(:disabled):not(.disabled):active, .btn-business:not(:disabled):not(.disabled).active, .show > .btn-business.dropdown-toggle {
  color: #fff;
  background-color: #06244f;
  border-color: #051f43;
}
.btn-business:not(:disabled):not(.disabled):active:focus, .btn-business:not(:disabled):not(.disabled).active:focus, .show > .btn-business.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(46, 88, 146, 0.5);
}

.btn-manager {
  color: #fff;
  background-color: #581e80;
  border-color: #581e80;
}
.btn-manager:hover {
  color: #fff;
  background-color: #431761;
  border-color: #3c1457;
}
.btn-manager:focus, .btn-manager.focus {
  color: #fff;
  background-color: #431761;
  border-color: #3c1457;
  box-shadow: 0 0 0 0.2rem rgba(113, 64, 147, 0.5);
}
.btn-manager.disabled, .btn-manager:disabled {
  color: #fff;
  background-color: #581e80;
  border-color: #581e80;
}
.btn-manager:not(:disabled):not(.disabled):active, .btn-manager:not(:disabled):not(.disabled).active, .show > .btn-manager.dropdown-toggle {
  color: #fff;
  background-color: #3c1457;
  border-color: #34124c;
}
.btn-manager:not(:disabled):not(.disabled):active:focus, .btn-manager:not(:disabled):not(.disabled).active:focus, .show > .btn-manager.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(113, 64, 147, 0.5);
}

.btn-safezone {
  color: #fff;
  background-color: #3a15b2;
  border-color: #3a15b2;
}
.btn-safezone:hover {
  color: #fff;
  background-color: #2f1190;
  border-color: #2b1084;
}
.btn-safezone:focus, .btn-safezone.focus {
  color: #fff;
  background-color: #2f1190;
  border-color: #2b1084;
  box-shadow: 0 0 0 0.2rem rgba(88, 56, 190, 0.5);
}
.btn-safezone.disabled, .btn-safezone:disabled {
  color: #fff;
  background-color: #3a15b2;
  border-color: #3a15b2;
}
.btn-safezone:not(:disabled):not(.disabled):active, .btn-safezone:not(:disabled):not(.disabled).active, .show > .btn-safezone.dropdown-toggle {
  color: #fff;
  background-color: #2b1084;
  border-color: #270e79;
}
.btn-safezone:not(:disabled):not(.disabled):active:focus, .btn-safezone:not(:disabled):not(.disabled).active:focus, .show > .btn-safezone.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 56, 190, 0.5);
}

.btn-contract {
  color: #fff;
  background-color: #b25126;
  border-color: #b25126;
}
.btn-contract:hover {
  color: #fff;
  background-color: #92431f;
  border-color: #883e1d;
}
.btn-contract:focus, .btn-contract.focus {
  color: #fff;
  background-color: #92431f;
  border-color: #883e1d;
  box-shadow: 0 0 0 0.2rem rgba(190, 107, 71, 0.5);
}
.btn-contract.disabled, .btn-contract:disabled {
  color: #fff;
  background-color: #b25126;
  border-color: #b25126;
}
.btn-contract:not(:disabled):not(.disabled):active, .btn-contract:not(:disabled):not(.disabled).active, .show > .btn-contract.dropdown-toggle {
  color: #fff;
  background-color: #883e1d;
  border-color: #7d391b;
}
.btn-contract:not(:disabled):not(.disabled):active:focus, .btn-contract:not(:disabled):not(.disabled).active:focus, .show > .btn-contract.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(190, 107, 71, 0.5);
}

.btn-forest {
  color: #fff;
  background-color: #153E15;
  border-color: #153E15;
}
.btn-forest:hover {
  color: #fff;
  background-color: #0b210b;
  border-color: #081808;
}
.btn-forest:focus, .btn-forest.focus {
  color: #fff;
  background-color: #0b210b;
  border-color: #081808;
  box-shadow: 0 0 0 0.2rem rgba(56, 91, 56, 0.5);
}
.btn-forest.disabled, .btn-forest:disabled {
  color: #fff;
  background-color: #153E15;
  border-color: #153E15;
}
.btn-forest:not(:disabled):not(.disabled):active, .btn-forest:not(:disabled):not(.disabled).active, .show > .btn-forest.dropdown-toggle {
  color: #fff;
  background-color: #081808;
  border-color: #050e05;
}
.btn-forest:not(:disabled):not(.disabled):active:focus, .btn-forest:not(:disabled):not(.disabled).active:focus, .show > .btn-forest.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 91, 56, 0.5);
}

.btn-land {
  color: #fff;
  background-color: #00801d;
  border-color: #00801d;
}
.btn-land:hover {
  color: #fff;
  background-color: #005a14;
  border-color: #004d11;
}
.btn-land:focus, .btn-land.focus {
  color: #fff;
  background-color: #005a14;
  border-color: #004d11;
  box-shadow: 0 0 0 0.2rem rgba(38, 147, 63, 0.5);
}
.btn-land.disabled, .btn-land:disabled {
  color: #fff;
  background-color: #00801d;
  border-color: #00801d;
}
.btn-land:not(:disabled):not(.disabled):active, .btn-land:not(:disabled):not(.disabled).active, .show > .btn-land.dropdown-toggle {
  color: #fff;
  background-color: #004d11;
  border-color: #00400f;
}
.btn-land:not(:disabled):not(.disabled):active:focus, .btn-land:not(:disabled):not(.disabled).active:focus, .show > .btn-land.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 147, 63, 0.5);
}

.btn-safety {
  color: #fff;
  background-color: #80211f;
  border-color: #80211f;
}
.btn-safety:hover {
  color: #fff;
  background-color: #611918;
  border-color: #571615;
}
.btn-safety:focus, .btn-safety.focus {
  color: #fff;
  background-color: #611918;
  border-color: #571615;
  box-shadow: 0 0 0 0.2rem rgba(147, 66, 65, 0.5);
}
.btn-safety.disabled, .btn-safety:disabled {
  color: #fff;
  background-color: #80211f;
  border-color: #80211f;
}
.btn-safety:not(:disabled):not(.disabled):active, .btn-safety:not(:disabled):not(.disabled).active, .show > .btn-safety.dropdown-toggle {
  color: #fff;
  background-color: #571615;
  border-color: #4d1413;
}
.btn-safety:not(:disabled):not(.disabled):active:focus, .btn-safety:not(:disabled):not(.disabled).active:focus, .show > .btn-safety.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 66, 65, 0.5);
}

.btn-contractor {
  color: #fff;
  background-color: #801d3a;
  border-color: #801d3a;
}
.btn-contractor:hover {
  color: #fff;
  background-color: #61162c;
  border-color: #561427;
}
.btn-contractor:focus, .btn-contractor.focus {
  color: #fff;
  background-color: #61162c;
  border-color: #561427;
  box-shadow: 0 0 0 0.2rem rgba(147, 63, 88, 0.5);
}
.btn-contractor.disabled, .btn-contractor:disabled {
  color: #fff;
  background-color: #801d3a;
  border-color: #801d3a;
}
.btn-contractor:not(:disabled):not(.disabled):active, .btn-contractor:not(:disabled):not(.disabled).active, .show > .btn-contractor.dropdown-toggle {
  color: #fff;
  background-color: #561427;
  border-color: #4c1122;
}
.btn-contractor:not(:disabled):not(.disabled):active:focus, .btn-contractor:not(:disabled):not(.disabled).active:focus, .show > .btn-contractor.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 63, 88, 0.5);
}

.btn-sub {
  color: #fff;
  background-color: #2f3580;
  border-color: #2f3580;
}
.btn-sub:hover {
  color: #fff;
  background-color: #252964;
  border-color: #21265b;
}
.btn-sub:focus, .btn-sub.focus {
  color: #fff;
  background-color: #252964;
  border-color: #21265b;
  box-shadow: 0 0 0 0.2rem rgba(78, 83, 147, 0.5);
}
.btn-sub.disabled, .btn-sub:disabled {
  color: #fff;
  background-color: #2f3580;
  border-color: #2f3580;
}
.btn-sub:not(:disabled):not(.disabled):active, .btn-sub:not(:disabled):not(.disabled).active, .show > .btn-sub.dropdown-toggle {
  color: #fff;
  background-color: #21265b;
  border-color: #1e2251;
}
.btn-sub:not(:disabled):not(.disabled):active:focus, .btn-sub:not(:disabled):not(.disabled).active:focus, .show > .btn-sub.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 83, 147, 0.5);
}

.btn-workcrew {
  color: #fff;
  background-color: #4f3587;
  border-color: #4f3587;
}
.btn-workcrew:hover {
  color: #fff;
  background-color: #3f2a6c;
  border-color: #3a2762;
}
.btn-workcrew:focus, .btn-workcrew.focus {
  color: #fff;
  background-color: #3f2a6c;
  border-color: #3a2762;
  box-shadow: 0 0 0 0.2rem rgba(105, 83, 153, 0.5);
}
.btn-workcrew.disabled, .btn-workcrew:disabled {
  color: #fff;
  background-color: #4f3587;
  border-color: #4f3587;
}
.btn-workcrew:not(:disabled):not(.disabled):active, .btn-workcrew:not(:disabled):not(.disabled).active, .show > .btn-workcrew.dropdown-toggle {
  color: #fff;
  background-color: #3a2762;
  border-color: #342359;
}
.btn-workcrew:not(:disabled):not(.disabled):active:focus, .btn-workcrew:not(:disabled):not(.disabled).active:focus, .show > .btn-workcrew.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 83, 153, 0.5);
}

.btn-foreman {
  color: #fff;
  background-color: #482780;
  border-color: #482780;
}
.btn-foreman:hover {
  color: #fff;
  background-color: #381e63;
  border-color: #321b59;
}
.btn-foreman:focus, .btn-foreman.focus {
  color: #fff;
  background-color: #381e63;
  border-color: #321b59;
  box-shadow: 0 0 0 0.2rem rgba(99, 71, 147, 0.5);
}
.btn-foreman.disabled, .btn-foreman:disabled {
  color: #fff;
  background-color: #482780;
  border-color: #482780;
}
.btn-foreman:not(:disabled):not(.disabled):active, .btn-foreman:not(:disabled):not(.disabled).active, .show > .btn-foreman.dropdown-toggle {
  color: #fff;
  background-color: #321b59;
  border-color: #2d184f;
}
.btn-foreman:not(:disabled):not(.disabled):active:focus, .btn-foreman:not(:disabled):not(.disabled).active:focus, .show > .btn-foreman.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 71, 147, 0.5);
}

.btn-crew {
  color: #fff;
  background-color: #412187;
  border-color: #412187;
}
.btn-crew:hover {
  color: #fff;
  background-color: #321968;
  border-color: #2d175e;
}
.btn-crew:focus, .btn-crew.focus {
  color: #fff;
  background-color: #321968;
  border-color: #2d175e;
  box-shadow: 0 0 0 0.2rem rgba(94, 66, 153, 0.5);
}
.btn-crew.disabled, .btn-crew:disabled {
  color: #fff;
  background-color: #412187;
  border-color: #412187;
}
.btn-crew:not(:disabled):not(.disabled):active, .btn-crew:not(:disabled):not(.disabled).active, .show > .btn-crew.dropdown-toggle {
  color: #fff;
  background-color: #2d175e;
  border-color: #281454;
}
.btn-crew:not(:disabled):not(.disabled):active:focus, .btn-crew:not(:disabled):not(.disabled).active:focus, .show > .btn-crew.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(94, 66, 153, 0.5);
}

.btn-truck {
  color: #fff;
  background-color: #fd2242;
  border-color: #fd2242;
}
.btn-truck:hover {
  color: #fff;
  background-color: #f70226;
  border-color: #ea0224;
}
.btn-truck:focus, .btn-truck.focus {
  color: #fff;
  background-color: #f70226;
  border-color: #ea0224;
  box-shadow: 0 0 0 0.2rem rgba(253, 67, 94, 0.5);
}
.btn-truck.disabled, .btn-truck:disabled {
  color: #fff;
  background-color: #fd2242;
  border-color: #fd2242;
}
.btn-truck:not(:disabled):not(.disabled):active, .btn-truck:not(:disabled):not(.disabled).active, .show > .btn-truck.dropdown-toggle {
  color: #fff;
  background-color: #ea0224;
  border-color: #dd0222;
}
.btn-truck:not(:disabled):not(.disabled):active:focus, .btn-truck:not(:disabled):not(.disabled).active:focus, .show > .btn-truck.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 67, 94, 0.5);
}

.btn-visitor {
  color: #fff;
  background-color: #ff3131;
  border-color: #ff3131;
}
.btn-visitor:hover {
  color: #fff;
  background-color: #ff0b0b;
  border-color: #fd0000;
}
.btn-visitor:focus, .btn-visitor.focus {
  color: #fff;
  background-color: #ff0b0b;
  border-color: #fd0000;
  box-shadow: 0 0 0 0.2rem rgba(255, 80, 80, 0.5);
}
.btn-visitor.disabled, .btn-visitor:disabled {
  color: #fff;
  background-color: #ff3131;
  border-color: #ff3131;
}
.btn-visitor:not(:disabled):not(.disabled):active, .btn-visitor:not(:disabled):not(.disabled).active, .show > .btn-visitor.dropdown-toggle {
  color: #fff;
  background-color: #fd0000;
  border-color: #f00000;
}
.btn-visitor:not(:disabled):not(.disabled):active:focus, .btn-visitor:not(:disabled):not(.disabled).active:focus, .show > .btn-visitor.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 80, 80, 0.5);
}

.btn-workday {
  color: #212529;
  background-color: #ff943b;
  border-color: #ff943b;
}
.btn-workday:hover {
  color: #212529;
  background-color: #ff7f15;
  border-color: #ff7808;
}
.btn-workday:focus, .btn-workday.focus {
  color: #212529;
  background-color: #ff7f15;
  border-color: #ff7808;
  box-shadow: 0 0 0 0.2rem rgba(222, 131, 56, 0.5);
}
.btn-workday.disabled, .btn-workday:disabled {
  color: #212529;
  background-color: #ff943b;
  border-color: #ff943b;
}
.btn-workday:not(:disabled):not(.disabled):active, .btn-workday:not(:disabled):not(.disabled).active, .show > .btn-workday.dropdown-toggle {
  color: #fff;
  background-color: #ff7808;
  border-color: #fa7200;
}
.btn-workday:not(:disabled):not(.disabled):active:focus, .btn-workday:not(:disabled):not(.disabled).active:focus, .show > .btn-workday.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 131, 56, 0.5);
}

.btn-worksite {
  color: #fff;
  background-color: #fd5e53;
  border-color: #fd5e53;
}
.btn-worksite:hover {
  color: #fff;
  background-color: #fd3b2d;
  border-color: #fc2f21;
}
.btn-worksite:focus, .btn-worksite.focus {
  color: #fff;
  background-color: #fd3b2d;
  border-color: #fc2f21;
  box-shadow: 0 0 0 0.2rem rgba(253, 118, 109, 0.5);
}
.btn-worksite.disabled, .btn-worksite:disabled {
  color: #fff;
  background-color: #fd5e53;
  border-color: #fd5e53;
}
.btn-worksite:not(:disabled):not(.disabled):active, .btn-worksite:not(:disabled):not(.disabled).active, .show > .btn-worksite.dropdown-toggle {
  color: #fff;
  background-color: #fc2f21;
  border-color: #fc2314;
}
.btn-worksite:not(:disabled):not(.disabled):active:focus, .btn-worksite:not(:disabled):not(.disabled).active:focus, .show > .btn-worksite.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 118, 109, 0.5);
}

.btn-welcome {
  color: #fff;
  background-color: #0342a8;
  border-color: #0342a8;
}
.btn-welcome:hover {
  color: #fff;
  background-color: #023382;
  border-color: #022e76;
}
.btn-welcome:focus, .btn-welcome.focus {
  color: #fff;
  background-color: #023382;
  border-color: #022e76;
  box-shadow: 0 0 0 0.2rem rgba(41, 94, 181, 0.5);
}
.btn-welcome.disabled, .btn-welcome:disabled {
  color: #fff;
  background-color: #0342a8;
  border-color: #0342a8;
}
.btn-welcome:not(:disabled):not(.disabled):active, .btn-welcome:not(:disabled):not(.disabled).active, .show > .btn-welcome.dropdown-toggle {
  color: #fff;
  background-color: #022e76;
  border-color: #022969;
}
.btn-welcome:not(:disabled):not(.disabled):active:focus, .btn-welcome:not(:disabled):not(.disabled).active:focus, .show > .btn-welcome.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(41, 94, 181, 0.5);
}

.btn-error {
  color: #fff;
  background-color: #fd0921;
  border-color: #fd0921;
}
.btn-error:hover {
  color: #fff;
  background-color: #de0217;
  border-color: #d10216;
}
.btn-error:focus, .btn-error.focus {
  color: #fff;
  background-color: #de0217;
  border-color: #d10216;
  box-shadow: 0 0 0 0.2rem rgba(253, 46, 66, 0.5);
}
.btn-error.disabled, .btn-error:disabled {
  color: #fff;
  background-color: #fd0921;
  border-color: #fd0921;
}
.btn-error:not(:disabled):not(.disabled):active, .btn-error:not(:disabled):not(.disabled).active, .show > .btn-error.dropdown-toggle {
  color: #fff;
  background-color: #d10216;
  border-color: #c50215;
}
.btn-error:not(:disabled):not(.disabled):active:focus, .btn-error:not(:disabled):not(.disabled).active:focus, .show > .btn-error.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 46, 66, 0.5);
}

.btn-good {
  color: #fff;
  background-color: #3b8c3b;
  border-color: #3b8c3b;
}
.btn-good:hover {
  color: #fff;
  background-color: #307130;
  border-color: #2c682c;
}
.btn-good:focus, .btn-good.focus {
  color: #fff;
  background-color: #307130;
  border-color: #2c682c;
  box-shadow: 0 0 0 0.2rem rgba(88, 157, 88, 0.5);
}
.btn-good.disabled, .btn-good:disabled {
  color: #fff;
  background-color: #3b8c3b;
  border-color: #3b8c3b;
}
.btn-good:not(:disabled):not(.disabled):active, .btn-good:not(:disabled):not(.disabled).active, .show > .btn-good.dropdown-toggle {
  color: #fff;
  background-color: #2c682c;
  border-color: #285f28;
}
.btn-good:not(:disabled):not(.disabled):active:focus, .btn-good:not(:disabled):not(.disabled).active:focus, .show > .btn-good.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 157, 88, 0.5);
}

.btn-zero {
  color: #212529;
  background-color: #dddddd;
  border-color: #dddddd;
}
.btn-zero:hover {
  color: #212529;
  background-color: #cacaca;
  border-color: #c4c4c4;
}
.btn-zero:focus, .btn-zero.focus {
  color: #212529;
  background-color: #cacaca;
  border-color: #c4c4c4;
  box-shadow: 0 0 0 0.2rem rgba(193, 193, 194, 0.5);
}
.btn-zero.disabled, .btn-zero:disabled {
  color: #212529;
  background-color: #dddddd;
  border-color: #dddddd;
}
.btn-zero:not(:disabled):not(.disabled):active, .btn-zero:not(:disabled):not(.disabled).active, .show > .btn-zero.dropdown-toggle {
  color: #212529;
  background-color: #c4c4c4;
  border-color: #bdbdbd;
}
.btn-zero:not(:disabled):not(.disabled):active:focus, .btn-zero:not(:disabled):not(.disabled).active:focus, .show > .btn-zero.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(193, 193, 194, 0.5);
}

.btn-eee {
  color: #212529;
  background-color: #fafafa;
  border-color: #fafafa;
}
.btn-eee:hover {
  color: #212529;
  background-color: #e7e7e7;
  border-color: #e1e1e1;
}
.btn-eee:focus, .btn-eee.focus {
  color: #212529;
  background-color: #e7e7e7;
  border-color: #e1e1e1;
  box-shadow: 0 0 0 0.2rem rgba(217, 218, 219, 0.5);
}
.btn-eee.disabled, .btn-eee:disabled {
  color: #212529;
  background-color: #fafafa;
  border-color: #fafafa;
}
.btn-eee:not(:disabled):not(.disabled):active, .btn-eee:not(:disabled):not(.disabled).active, .show > .btn-eee.dropdown-toggle {
  color: #212529;
  background-color: #e1e1e1;
  border-color: #dadada;
}
.btn-eee:not(:disabled):not(.disabled):active:focus, .btn-eee:not(:disabled):not(.disabled).active:focus, .show > .btn-eee.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 218, 219, 0.5);
}

.btn-none {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-none:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-none:focus, .btn-none.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.btn-none.disabled, .btn-none:disabled {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-none:not(:disabled):not(.disabled):active, .btn-none:not(:disabled):not(.disabled).active, .show > .btn-none.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-none:not(:disabled):not(.disabled):active:focus, .btn-none:not(:disabled):not(.disabled).active:focus, .show > .btn-none.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-delete {
  color: #fff;
  background-color: #8A0303;
  border-color: #8A0303;
}
.btn-delete:hover {
  color: #fff;
  background-color: #650202;
  border-color: #580202;
}
.btn-delete:focus, .btn-delete.focus {
  color: #fff;
  background-color: #650202;
  border-color: #580202;
  box-shadow: 0 0 0 0.2rem rgba(156, 41, 41, 0.5);
}
.btn-delete.disabled, .btn-delete:disabled {
  color: #fff;
  background-color: #8A0303;
  border-color: #8A0303;
}
.btn-delete:not(:disabled):not(.disabled):active, .btn-delete:not(:disabled):not(.disabled).active, .show > .btn-delete.dropdown-toggle {
  color: #fff;
  background-color: #580202;
  border-color: #4c0202;
}
.btn-delete:not(:disabled):not(.disabled):active:focus, .btn-delete:not(:disabled):not(.disabled).active:focus, .show > .btn-delete.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 41, 41, 0.5);
}

.btn-lightgray {
  color: #212529;
  background-color: #999999;
  border-color: #999999;
}
.btn-lightgray:hover {
  color: #fff;
  background-color: #868686;
  border-color: gray;
}
.btn-lightgray:focus, .btn-lightgray.focus {
  color: #fff;
  background-color: #868686;
  border-color: gray;
  box-shadow: 0 0 0 0.2rem rgba(135, 136, 136, 0.5);
}
.btn-lightgray.disabled, .btn-lightgray:disabled {
  color: #212529;
  background-color: #999999;
  border-color: #999999;
}
.btn-lightgray:not(:disabled):not(.disabled):active, .btn-lightgray:not(:disabled):not(.disabled).active, .show > .btn-lightgray.dropdown-toggle {
  color: #fff;
  background-color: gray;
  border-color: #797979;
}
.btn-lightgray:not(:disabled):not(.disabled):active:focus, .btn-lightgray:not(:disabled):not(.disabled).active:focus, .show > .btn-lightgray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(135, 136, 136, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-journey {
  color: #4687c7;
  border-color: #4687c7;
}
.btn-outline-journey:hover {
  color: #fff;
  background-color: #4687c7;
  border-color: #4687c7;
}
.btn-outline-journey:focus, .btn-outline-journey.focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 135, 199, 0.5);
}
.btn-outline-journey.disabled, .btn-outline-journey:disabled {
  color: #4687c7;
  background-color: transparent;
}
.btn-outline-journey:not(:disabled):not(.disabled):active, .btn-outline-journey:not(:disabled):not(.disabled).active, .show > .btn-outline-journey.dropdown-toggle {
  color: #fff;
  background-color: #4687c7;
  border-color: #4687c7;
}
.btn-outline-journey:not(:disabled):not(.disabled):active:focus, .btn-outline-journey:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-journey.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 135, 199, 0.5);
}

.btn-outline-kokua {
  color: #3378b3;
  border-color: #3378b3;
}
.btn-outline-kokua:hover {
  color: #fff;
  background-color: #3378b3;
  border-color: #3378b3;
}
.btn-outline-kokua:focus, .btn-outline-kokua.focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 120, 179, 0.5);
}
.btn-outline-kokua.disabled, .btn-outline-kokua:disabled {
  color: #3378b3;
  background-color: transparent;
}
.btn-outline-kokua:not(:disabled):not(.disabled):active, .btn-outline-kokua:not(:disabled):not(.disabled).active, .show > .btn-outline-kokua.dropdown-toggle {
  color: #fff;
  background-color: #3378b3;
  border-color: #3378b3;
}
.btn-outline-kokua:not(:disabled):not(.disabled):active:focus, .btn-outline-kokua:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-kokua.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 120, 179, 0.5);
}

.btn-outline-kokual {
  color: rgba(84, 120, 191, 0.2);
  border-color: rgba(84, 120, 191, 0.2);
}
.btn-outline-kokual:hover {
  color: #fff;
  background-color: rgba(84, 120, 191, 0.2);
  border-color: rgba(84, 120, 191, 0.2);
}
.btn-outline-kokual:focus, .btn-outline-kokual.focus {
  box-shadow: 0 0 0 0.2rem rgba(84, 120, 191, 0.5);
}
.btn-outline-kokual.disabled, .btn-outline-kokual:disabled {
  color: rgba(84, 120, 191, 0.2);
  background-color: transparent;
}
.btn-outline-kokual:not(:disabled):not(.disabled):active, .btn-outline-kokual:not(:disabled):not(.disabled).active, .show > .btn-outline-kokual.dropdown-toggle {
  color: #fff;
  background-color: rgba(84, 120, 191, 0.2);
  border-color: rgba(84, 120, 191, 0.2);
}
.btn-outline-kokual:not(:disabled):not(.disabled):active:focus, .btn-outline-kokual:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-kokual.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(84, 120, 191, 0.5);
}

.btn-outline-kōkua {
  color: #3378b3;
  border-color: #3378b3;
}
.btn-outline-kōkua:hover {
  color: #fff;
  background-color: #3378b3;
  border-color: #3378b3;
}
.btn-outline-kōkua:focus, .btn-outline-kōkua.focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 120, 179, 0.5);
}
.btn-outline-kōkua.disabled, .btn-outline-kōkua:disabled {
  color: #3378b3;
  background-color: transparent;
}
.btn-outline-kōkua:not(:disabled):not(.disabled):active, .btn-outline-kōkua:not(:disabled):not(.disabled).active, .show > .btn-outline-kōkua.dropdown-toggle {
  color: #fff;
  background-color: #3378b3;
  border-color: #3378b3;
}
.btn-outline-kōkua:not(:disabled):not(.disabled):active:focus, .btn-outline-kōkua:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-kōkua.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 120, 179, 0.5);
}

.btn-outline-ohana {
  color: #3e6bad;
  border-color: #3e6bad;
}
.btn-outline-ohana:hover {
  color: #fff;
  background-color: #3e6bad;
  border-color: #3e6bad;
}
.btn-outline-ohana:focus, .btn-outline-ohana.focus {
  box-shadow: 0 0 0 0.2rem rgba(62, 107, 173, 0.5);
}
.btn-outline-ohana.disabled, .btn-outline-ohana:disabled {
  color: #3e6bad;
  background-color: transparent;
}
.btn-outline-ohana:not(:disabled):not(.disabled):active, .btn-outline-ohana:not(:disabled):not(.disabled).active, .show > .btn-outline-ohana.dropdown-toggle {
  color: #fff;
  background-color: #3e6bad;
  border-color: #3e6bad;
}
.btn-outline-ohana:not(:disabled):not(.disabled):active:focus, .btn-outline-ohana:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-ohana.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(62, 107, 173, 0.5);
}

.btn-outline-ohanal {
  color: rgba(141, 208, 89, 0.2);
  border-color: rgba(141, 208, 89, 0.2);
}
.btn-outline-ohanal:hover {
  color: #212529;
  background-color: rgba(141, 208, 89, 0.2);
  border-color: rgba(141, 208, 89, 0.2);
}
.btn-outline-ohanal:focus, .btn-outline-ohanal.focus {
  box-shadow: 0 0 0 0.2rem rgba(141, 208, 89, 0.5);
}
.btn-outline-ohanal.disabled, .btn-outline-ohanal:disabled {
  color: rgba(141, 208, 89, 0.2);
  background-color: transparent;
}
.btn-outline-ohanal:not(:disabled):not(.disabled):active, .btn-outline-ohanal:not(:disabled):not(.disabled).active, .show > .btn-outline-ohanal.dropdown-toggle {
  color: #212529;
  background-color: rgba(141, 208, 89, 0.2);
  border-color: rgba(141, 208, 89, 0.2);
}
.btn-outline-ohanal:not(:disabled):not(.disabled):active:focus, .btn-outline-ohanal:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-ohanal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(141, 208, 89, 0.5);
}

.btn-outline-kula {
  color: #668712;
  border-color: #668712;
}
.btn-outline-kula:hover {
  color: #fff;
  background-color: #668712;
  border-color: #668712;
}
.btn-outline-kula:focus, .btn-outline-kula.focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 135, 18, 0.5);
}
.btn-outline-kula.disabled, .btn-outline-kula:disabled {
  color: #668712;
  background-color: transparent;
}
.btn-outline-kula:not(:disabled):not(.disabled):active, .btn-outline-kula:not(:disabled):not(.disabled).active, .show > .btn-outline-kula.dropdown-toggle {
  color: #fff;
  background-color: #668712;
  border-color: #668712;
}
.btn-outline-kula:not(:disabled):not(.disabled):active:focus, .btn-outline-kula:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-kula.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 135, 18, 0.5);
}

.btn-outline-kulal {
  color: rgba(102, 135, 18, 0.2);
  border-color: rgba(102, 135, 18, 0.2);
}
.btn-outline-kulal:hover {
  color: #fff;
  background-color: rgba(102, 135, 18, 0.2);
  border-color: rgba(102, 135, 18, 0.2);
}
.btn-outline-kulal:focus, .btn-outline-kulal.focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 135, 18, 0.5);
}
.btn-outline-kulal.disabled, .btn-outline-kulal:disabled {
  color: rgba(102, 135, 18, 0.2);
  background-color: transparent;
}
.btn-outline-kulal:not(:disabled):not(.disabled):active, .btn-outline-kulal:not(:disabled):not(.disabled).active, .show > .btn-outline-kulal.dropdown-toggle {
  color: #fff;
  background-color: rgba(102, 135, 18, 0.2);
  border-color: rgba(102, 135, 18, 0.2);
}
.btn-outline-kulal:not(:disabled):not(.disabled):active:focus, .btn-outline-kulal:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-kulal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 135, 18, 0.5);
}

.btn-outline-hui {
  color: #093a7f;
  border-color: #093a7f;
}
.btn-outline-hui:hover {
  color: #fff;
  background-color: #093a7f;
  border-color: #093a7f;
}
.btn-outline-hui:focus, .btn-outline-hui.focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 58, 127, 0.5);
}
.btn-outline-hui.disabled, .btn-outline-hui:disabled {
  color: #093a7f;
  background-color: transparent;
}
.btn-outline-hui:not(:disabled):not(.disabled):active, .btn-outline-hui:not(:disabled):not(.disabled).active, .show > .btn-outline-hui.dropdown-toggle {
  color: #fff;
  background-color: #093a7f;
  border-color: #093a7f;
}
.btn-outline-hui:not(:disabled):not(.disabled):active:focus, .btn-outline-hui:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-hui.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 58, 127, 0.5);
}

.btn-outline-huil {
  color: rgba(9, 58, 127, 0.2);
  border-color: rgba(9, 58, 127, 0.2);
}
.btn-outline-huil:hover {
  color: #fff;
  background-color: rgba(9, 58, 127, 0.2);
  border-color: rgba(9, 58, 127, 0.2);
}
.btn-outline-huil:focus, .btn-outline-huil.focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 58, 127, 0.5);
}
.btn-outline-huil.disabled, .btn-outline-huil:disabled {
  color: rgba(9, 58, 127, 0.2);
  background-color: transparent;
}
.btn-outline-huil:not(:disabled):not(.disabled):active, .btn-outline-huil:not(:disabled):not(.disabled).active, .show > .btn-outline-huil.dropdown-toggle {
  color: #fff;
  background-color: rgba(9, 58, 127, 0.2);
  border-color: rgba(9, 58, 127, 0.2);
}
.btn-outline-huil:not(:disabled):not(.disabled):active:focus, .btn-outline-huil:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-huil.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 58, 127, 0.5);
}

.btn-outline-dbs {
  color: #668712;
  border-color: #668712;
}
.btn-outline-dbs:hover {
  color: #fff;
  background-color: #668712;
  border-color: #668712;
}
.btn-outline-dbs:focus, .btn-outline-dbs.focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 135, 18, 0.5);
}
.btn-outline-dbs.disabled, .btn-outline-dbs:disabled {
  color: #668712;
  background-color: transparent;
}
.btn-outline-dbs:not(:disabled):not(.disabled):active, .btn-outline-dbs:not(:disabled):not(.disabled).active, .show > .btn-outline-dbs.dropdown-toggle {
  color: #fff;
  background-color: #668712;
  border-color: #668712;
}
.btn-outline-dbs:not(:disabled):not(.disabled):active:focus, .btn-outline-dbs:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dbs.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 135, 18, 0.5);
}

.btn-outline-dbalpha {
  color: #998712;
  border-color: #998712;
}
.btn-outline-dbalpha:hover {
  color: #fff;
  background-color: #998712;
  border-color: #998712;
}
.btn-outline-dbalpha:focus, .btn-outline-dbalpha.focus {
  box-shadow: 0 0 0 0.2rem rgba(153, 135, 18, 0.5);
}
.btn-outline-dbalpha.disabled, .btn-outline-dbalpha:disabled {
  color: #998712;
  background-color: transparent;
}
.btn-outline-dbalpha:not(:disabled):not(.disabled):active, .btn-outline-dbalpha:not(:disabled):not(.disabled).active, .show > .btn-outline-dbalpha.dropdown-toggle {
  color: #fff;
  background-color: #998712;
  border-color: #998712;
}
.btn-outline-dbalpha:not(:disabled):not(.disabled):active:focus, .btn-outline-dbalpha:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dbalpha.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(153, 135, 18, 0.5);
}

.btn-outline-dbresource {
  color: #cc8712;
  border-color: #cc8712;
}
.btn-outline-dbresource:hover {
  color: #fff;
  background-color: #cc8712;
  border-color: #cc8712;
}
.btn-outline-dbresource:focus, .btn-outline-dbresource.focus {
  box-shadow: 0 0 0 0.2rem rgba(204, 135, 18, 0.5);
}
.btn-outline-dbresource.disabled, .btn-outline-dbresource:disabled {
  color: #cc8712;
  background-color: transparent;
}
.btn-outline-dbresource:not(:disabled):not(.disabled):active, .btn-outline-dbresource:not(:disabled):not(.disabled).active, .show > .btn-outline-dbresource.dropdown-toggle {
  color: #fff;
  background-color: #cc8712;
  border-color: #cc8712;
}
.btn-outline-dbresource:not(:disabled):not(.disabled):active:focus, .btn-outline-dbresource:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dbresource.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(204, 135, 18, 0.5);
}

.btn-outline-dbthing {
  color: #ff8712;
  border-color: #ff8712;
}
.btn-outline-dbthing:hover {
  color: #212529;
  background-color: #ff8712;
  border-color: #ff8712;
}
.btn-outline-dbthing:focus, .btn-outline-dbthing.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 135, 18, 0.5);
}
.btn-outline-dbthing.disabled, .btn-outline-dbthing:disabled {
  color: #ff8712;
  background-color: transparent;
}
.btn-outline-dbthing:not(:disabled):not(.disabled):active, .btn-outline-dbthing:not(:disabled):not(.disabled).active, .show > .btn-outline-dbthing.dropdown-toggle {
  color: #212529;
  background-color: #ff8712;
  border-color: #ff8712;
}
.btn-outline-dbthing:not(:disabled):not(.disabled):active:focus, .btn-outline-dbthing:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dbthing.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 135, 18, 0.5);
}

.btn-outline-dbsl {
  color: #e0e7d0;
  border-color: #e0e7d0;
}
.btn-outline-dbsl:hover {
  color: #212529;
  background-color: #e0e7d0;
  border-color: #e0e7d0;
}
.btn-outline-dbsl:focus, .btn-outline-dbsl.focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 231, 208, 0.5);
}
.btn-outline-dbsl.disabled, .btn-outline-dbsl:disabled {
  color: #e0e7d0;
  background-color: transparent;
}
.btn-outline-dbsl:not(:disabled):not(.disabled):active, .btn-outline-dbsl:not(:disabled):not(.disabled).active, .show > .btn-outline-dbsl.dropdown-toggle {
  color: #212529;
  background-color: #e0e7d0;
  border-color: #e0e7d0;
}
.btn-outline-dbsl:not(:disabled):not(.disabled):active:focus, .btn-outline-dbsl:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dbsl.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 231, 208, 0.5);
}

.btn-outline-superadmin {
  color: #4886b3;
  border-color: #4886b3;
}
.btn-outline-superadmin:hover {
  color: #fff;
  background-color: #4886b3;
  border-color: #4886b3;
}
.btn-outline-superadmin:focus, .btn-outline-superadmin.focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 134, 179, 0.5);
}
.btn-outline-superadmin.disabled, .btn-outline-superadmin:disabled {
  color: #4886b3;
  background-color: transparent;
}
.btn-outline-superadmin:not(:disabled):not(.disabled):active, .btn-outline-superadmin:not(:disabled):not(.disabled).active, .show > .btn-outline-superadmin.dropdown-toggle {
  color: #fff;
  background-color: #4886b3;
  border-color: #4886b3;
}
.btn-outline-superadmin:not(:disabled):not(.disabled):active:focus, .btn-outline-superadmin:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-superadmin.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 134, 179, 0.5);
}

.btn-outline-admin {
  color: #745ab3;
  border-color: #745ab3;
}
.btn-outline-admin:hover {
  color: #fff;
  background-color: #745ab3;
  border-color: #745ab3;
}
.btn-outline-admin:focus, .btn-outline-admin.focus {
  box-shadow: 0 0 0 0.2rem rgba(116, 90, 179, 0.5);
}
.btn-outline-admin.disabled, .btn-outline-admin:disabled {
  color: #745ab3;
  background-color: transparent;
}
.btn-outline-admin:not(:disabled):not(.disabled):active, .btn-outline-admin:not(:disabled):not(.disabled).active, .show > .btn-outline-admin.dropdown-toggle {
  color: #fff;
  background-color: #745ab3;
  border-color: #745ab3;
}
.btn-outline-admin:not(:disabled):not(.disabled):active:focus, .btn-outline-admin:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-admin.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(116, 90, 179, 0.5);
}

.btn-outline-supporter {
  color: #B350A6;
  border-color: #B350A6;
}
.btn-outline-supporter:hover {
  color: #fff;
  background-color: #B350A6;
  border-color: #B350A6;
}
.btn-outline-supporter:focus, .btn-outline-supporter.focus {
  box-shadow: 0 0 0 0.2rem rgba(179, 80, 166, 0.5);
}
.btn-outline-supporter.disabled, .btn-outline-supporter:disabled {
  color: #B350A6;
  background-color: transparent;
}
.btn-outline-supporter:not(:disabled):not(.disabled):active, .btn-outline-supporter:not(:disabled):not(.disabled).active, .show > .btn-outline-supporter.dropdown-toggle {
  color: #fff;
  background-color: #B350A6;
  border-color: #B350A6;
}
.btn-outline-supporter:not(:disabled):not(.disabled):active:focus, .btn-outline-supporter:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-supporter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(179, 80, 166, 0.5);
}

.btn-outline-appliedfor {
  color: #d72e81;
  border-color: #d72e81;
}
.btn-outline-appliedfor:hover {
  color: #fff;
  background-color: #d72e81;
  border-color: #d72e81;
}
.btn-outline-appliedfor:focus, .btn-outline-appliedfor.focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 46, 129, 0.5);
}
.btn-outline-appliedfor.disabled, .btn-outline-appliedfor:disabled {
  color: #d72e81;
  background-color: transparent;
}
.btn-outline-appliedfor:not(:disabled):not(.disabled):active, .btn-outline-appliedfor:not(:disabled):not(.disabled).active, .show > .btn-outline-appliedfor.dropdown-toggle {
  color: #fff;
  background-color: #d72e81;
  border-color: #d72e81;
}
.btn-outline-appliedfor:not(:disabled):not(.disabled):active:focus, .btn-outline-appliedfor:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-appliedfor.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 46, 129, 0.5);
}

.btn-outline-appliedforl {
  color: #d72e81;
  border-color: #d72e81;
}
.btn-outline-appliedforl:hover {
  color: #fff;
  background-color: #d72e81;
  border-color: #d72e81;
}
.btn-outline-appliedforl:focus, .btn-outline-appliedforl.focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 46, 129, 0.5);
}
.btn-outline-appliedforl.disabled, .btn-outline-appliedforl:disabled {
  color: #d72e81;
  background-color: transparent;
}
.btn-outline-appliedforl:not(:disabled):not(.disabled):active, .btn-outline-appliedforl:not(:disabled):not(.disabled).active, .show > .btn-outline-appliedforl.dropdown-toggle {
  color: #fff;
  background-color: #d72e81;
  border-color: #d72e81;
}
.btn-outline-appliedforl:not(:disabled):not(.disabled):active:focus, .btn-outline-appliedforl:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-appliedforl.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 46, 129, 0.5);
}

.btn-outline-available {
  color: #d4ffd4;
  border-color: #d4ffd4;
}
.btn-outline-available:hover {
  color: #212529;
  background-color: #d4ffd4;
  border-color: #d4ffd4;
}
.btn-outline-available:focus, .btn-outline-available.focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 255, 212, 0.5);
}
.btn-outline-available.disabled, .btn-outline-available:disabled {
  color: #d4ffd4;
  background-color: transparent;
}
.btn-outline-available:not(:disabled):not(.disabled):active, .btn-outline-available:not(:disabled):not(.disabled).active, .show > .btn-outline-available.dropdown-toggle {
  color: #212529;
  background-color: #d4ffd4;
  border-color: #d4ffd4;
}
.btn-outline-available:not(:disabled):not(.disabled):active:focus, .btn-outline-available:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-available.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 255, 212, 0.5);
}

.btn-outline-totest {
  color: #7a82d7;
  border-color: #7a82d7;
}
.btn-outline-totest:hover {
  color: #fff;
  background-color: #7a82d7;
  border-color: #7a82d7;
}
.btn-outline-totest:focus, .btn-outline-totest.focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 130, 215, 0.5);
}
.btn-outline-totest.disabled, .btn-outline-totest:disabled {
  color: #7a82d7;
  background-color: transparent;
}
.btn-outline-totest:not(:disabled):not(.disabled):active, .btn-outline-totest:not(:disabled):not(.disabled).active, .show > .btn-outline-totest.dropdown-toggle {
  color: #fff;
  background-color: #7a82d7;
  border-color: #7a82d7;
}
.btn-outline-totest:not(:disabled):not(.disabled):active:focus, .btn-outline-totest:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-totest.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 130, 215, 0.5);
}

.btn-outline-active {
  color: #77d077;
  border-color: #77d077;
}
.btn-outline-active:hover {
  color: #212529;
  background-color: #77d077;
  border-color: #77d077;
}
.btn-outline-active:focus, .btn-outline-active.focus {
  box-shadow: 0 0 0 0.2rem rgba(119, 208, 119, 0.5);
}
.btn-outline-active.disabled, .btn-outline-active:disabled {
  color: #77d077;
  background-color: transparent;
}
.btn-outline-active:not(:disabled):not(.disabled):active, .btn-outline-active:not(:disabled):not(.disabled).active, .show > .btn-outline-active.dropdown-toggle {
  color: #212529;
  background-color: #77d077;
  border-color: #77d077;
}
.btn-outline-active:not(:disabled):not(.disabled):active:focus, .btn-outline-active:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-active.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(119, 208, 119, 0.5);
}

.btn-outline-inactive {
  color: #d07777;
  border-color: #d07777;
}
.btn-outline-inactive:hover {
  color: #fff;
  background-color: #d07777;
  border-color: #d07777;
}
.btn-outline-inactive:focus, .btn-outline-inactive.focus {
  box-shadow: 0 0 0 0.2rem rgba(208, 119, 119, 0.5);
}
.btn-outline-inactive.disabled, .btn-outline-inactive:disabled {
  color: #d07777;
  background-color: transparent;
}
.btn-outline-inactive:not(:disabled):not(.disabled):active, .btn-outline-inactive:not(:disabled):not(.disabled).active, .show > .btn-outline-inactive.dropdown-toggle {
  color: #fff;
  background-color: #d07777;
  border-color: #d07777;
}
.btn-outline-inactive:not(:disabled):not(.disabled):active:focus, .btn-outline-inactive:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-inactive.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(208, 119, 119, 0.5);
}

.btn-outline-attention {
  color: #efe916;
  border-color: #efe916;
}
.btn-outline-attention:hover {
  color: #212529;
  background-color: #efe916;
  border-color: #efe916;
}
.btn-outline-attention:focus, .btn-outline-attention.focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 233, 22, 0.5);
}
.btn-outline-attention.disabled, .btn-outline-attention:disabled {
  color: #efe916;
  background-color: transparent;
}
.btn-outline-attention:not(:disabled):not(.disabled):active, .btn-outline-attention:not(:disabled):not(.disabled).active, .show > .btn-outline-attention.dropdown-toggle {
  color: #212529;
  background-color: #efe916;
  border-color: #efe916;
}
.btn-outline-attention:not(:disabled):not(.disabled):active:focus, .btn-outline-attention:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-attention.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 233, 22, 0.5);
}

.btn-outline-closed {
  color: #ef5451;
  border-color: #ef5451;
}
.btn-outline-closed:hover {
  color: #fff;
  background-color: #ef5451;
  border-color: #ef5451;
}
.btn-outline-closed:focus, .btn-outline-closed.focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 84, 81, 0.5);
}
.btn-outline-closed.disabled, .btn-outline-closed:disabled {
  color: #ef5451;
  background-color: transparent;
}
.btn-outline-closed:not(:disabled):not(.disabled):active, .btn-outline-closed:not(:disabled):not(.disabled).active, .show > .btn-outline-closed.dropdown-toggle {
  color: #fff;
  background-color: #ef5451;
  border-color: #ef5451;
}
.btn-outline-closed:not(:disabled):not(.disabled):active:focus, .btn-outline-closed:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-closed.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 84, 81, 0.5);
}

.btn-outline-help {
  color: #b8ef56;
  border-color: #b8ef56;
}
.btn-outline-help:hover {
  color: #212529;
  background-color: #b8ef56;
  border-color: #b8ef56;
}
.btn-outline-help:focus, .btn-outline-help.focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 239, 86, 0.5);
}
.btn-outline-help.disabled, .btn-outline-help:disabled {
  color: #b8ef56;
  background-color: transparent;
}
.btn-outline-help:not(:disabled):not(.disabled):active, .btn-outline-help:not(:disabled):not(.disabled).active, .show > .btn-outline-help.dropdown-toggle {
  color: #212529;
  background-color: #b8ef56;
  border-color: #b8ef56;
}
.btn-outline-help:not(:disabled):not(.disabled):active:focus, .btn-outline-help:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-help.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 239, 86, 0.5);
}

.btn-outline-expired {
  color: #d72c47;
  border-color: #d72c47;
}
.btn-outline-expired:hover {
  color: #fff;
  background-color: #d72c47;
  border-color: #d72c47;
}
.btn-outline-expired:focus, .btn-outline-expired.focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 44, 71, 0.5);
}
.btn-outline-expired.disabled, .btn-outline-expired:disabled {
  color: #d72c47;
  background-color: transparent;
}
.btn-outline-expired:not(:disabled):not(.disabled):active, .btn-outline-expired:not(:disabled):not(.disabled).active, .show > .btn-outline-expired.dropdown-toggle {
  color: #fff;
  background-color: #d72c47;
  border-color: #d72c47;
}
.btn-outline-expired:not(:disabled):not(.disabled):active:focus, .btn-outline-expired:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-expired.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 44, 71, 0.5);
}

.btn-outline-abandoned {
  color: #b33735;
  border-color: #b33735;
}
.btn-outline-abandoned:hover {
  color: #fff;
  background-color: #b33735;
  border-color: #b33735;
}
.btn-outline-abandoned:focus, .btn-outline-abandoned.focus {
  box-shadow: 0 0 0 0.2rem rgba(179, 55, 53, 0.5);
}
.btn-outline-abandoned.disabled, .btn-outline-abandoned:disabled {
  color: #b33735;
  background-color: transparent;
}
.btn-outline-abandoned:not(:disabled):not(.disabled):active, .btn-outline-abandoned:not(:disabled):not(.disabled).active, .show > .btn-outline-abandoned.dropdown-toggle {
  color: #fff;
  background-color: #b33735;
  border-color: #b33735;
}
.btn-outline-abandoned:not(:disabled):not(.disabled):active:focus, .btn-outline-abandoned:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-abandoned.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(179, 55, 53, 0.5);
}

.btn-outline-cmd {
  color: #00801d;
  border-color: #00801d;
}
.btn-outline-cmd:hover {
  color: #fff;
  background-color: #00801d;
  border-color: #00801d;
}
.btn-outline-cmd:focus, .btn-outline-cmd.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 128, 29, 0.5);
}
.btn-outline-cmd.disabled, .btn-outline-cmd:disabled {
  color: #00801d;
  background-color: transparent;
}
.btn-outline-cmd:not(:disabled):not(.disabled):active, .btn-outline-cmd:not(:disabled):not(.disabled).active, .show > .btn-outline-cmd.dropdown-toggle {
  color: #fff;
  background-color: #00801d;
  border-color: #00801d;
}
.btn-outline-cmd:not(:disabled):not(.disabled):active:focus, .btn-outline-cmd:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-cmd.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 128, 29, 0.5);
}

.btn-outline-sysadmin {
  color: #d71cff;
  border-color: #d71cff;
}
.btn-outline-sysadmin:hover {
  color: #fff;
  background-color: #d71cff;
  border-color: #d71cff;
}
.btn-outline-sysadmin:focus, .btn-outline-sysadmin.focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 28, 255, 0.5);
}
.btn-outline-sysadmin.disabled, .btn-outline-sysadmin:disabled {
  color: #d71cff;
  background-color: transparent;
}
.btn-outline-sysadmin:not(:disabled):not(.disabled):active, .btn-outline-sysadmin:not(:disabled):not(.disabled).active, .show > .btn-outline-sysadmin.dropdown-toggle {
  color: #fff;
  background-color: #d71cff;
  border-color: #d71cff;
}
.btn-outline-sysadmin:not(:disabled):not(.disabled):active:focus, .btn-outline-sysadmin:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-sysadmin.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 28, 255, 0.5);
}

.btn-outline-super {
  color: #be3aff;
  border-color: #be3aff;
}
.btn-outline-super:hover {
  color: #fff;
  background-color: #be3aff;
  border-color: #be3aff;
}
.btn-outline-super:focus, .btn-outline-super.focus {
  box-shadow: 0 0 0 0.2rem rgba(190, 58, 255, 0.5);
}
.btn-outline-super.disabled, .btn-outline-super:disabled {
  color: #be3aff;
  background-color: transparent;
}
.btn-outline-super:not(:disabled):not(.disabled):active, .btn-outline-super:not(:disabled):not(.disabled).active, .show > .btn-outline-super.dropdown-toggle {
  color: #fff;
  background-color: #be3aff;
  border-color: #be3aff;
}
.btn-outline-super:not(:disabled):not(.disabled):active:focus, .btn-outline-super:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-super.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(190, 58, 255, 0.5);
}

.btn-outline-dns {
  color: #801c24;
  border-color: #801c24;
}
.btn-outline-dns:hover {
  color: #fff;
  background-color: #801c24;
  border-color: #801c24;
}
.btn-outline-dns:focus, .btn-outline-dns.focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 28, 36, 0.5);
}
.btn-outline-dns.disabled, .btn-outline-dns:disabled {
  color: #801c24;
  background-color: transparent;
}
.btn-outline-dns:not(:disabled):not(.disabled):active, .btn-outline-dns:not(:disabled):not(.disabled).active, .show > .btn-outline-dns.dropdown-toggle {
  color: #fff;
  background-color: #801c24;
  border-color: #801c24;
}
.btn-outline-dns:not(:disabled):not(.disabled):active:focus, .btn-outline-dns:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dns.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 28, 36, 0.5);
}

.btn-outline-network {
  color: #0954f4;
  border-color: #0954f4;
}
.btn-outline-network:hover {
  color: #fff;
  background-color: #0954f4;
  border-color: #0954f4;
}
.btn-outline-network:focus, .btn-outline-network.focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 84, 244, 0.5);
}
.btn-outline-network.disabled, .btn-outline-network:disabled {
  color: #0954f4;
  background-color: transparent;
}
.btn-outline-network:not(:disabled):not(.disabled):active, .btn-outline-network:not(:disabled):not(.disabled).active, .show > .btn-outline-network.dropdown-toggle {
  color: #fff;
  background-color: #0954f4;
  border-color: #0954f4;
}
.btn-outline-network:not(:disabled):not(.disabled):active:focus, .btn-outline-network:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-network.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 84, 244, 0.5);
}

.btn-outline-backups {
  color: #26803b;
  border-color: #26803b;
}
.btn-outline-backups:hover {
  color: #fff;
  background-color: #26803b;
  border-color: #26803b;
}
.btn-outline-backups:focus, .btn-outline-backups.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 128, 59, 0.5);
}
.btn-outline-backups.disabled, .btn-outline-backups:disabled {
  color: #26803b;
  background-color: transparent;
}
.btn-outline-backups:not(:disabled):not(.disabled):active, .btn-outline-backups:not(:disabled):not(.disabled).active, .show > .btn-outline-backups.dropdown-toggle {
  color: #fff;
  background-color: #26803b;
  border-color: #26803b;
}
.btn-outline-backups:not(:disabled):not(.disabled):active:focus, .btn-outline-backups:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-backups.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 128, 59, 0.5);
}

.btn-outline-hardware {
  color: #d7223b;
  border-color: #d7223b;
}
.btn-outline-hardware:hover {
  color: #fff;
  background-color: #d7223b;
  border-color: #d7223b;
}
.btn-outline-hardware:focus, .btn-outline-hardware.focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 34, 59, 0.5);
}
.btn-outline-hardware.disabled, .btn-outline-hardware:disabled {
  color: #d7223b;
  background-color: transparent;
}
.btn-outline-hardware:not(:disabled):not(.disabled):active, .btn-outline-hardware:not(:disabled):not(.disabled).active, .show > .btn-outline-hardware.dropdown-toggle {
  color: #fff;
  background-color: #d7223b;
  border-color: #d7223b;
}
.btn-outline-hardware:not(:disabled):not(.disabled):active:focus, .btn-outline-hardware:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-hardware.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 34, 59, 0.5);
}

.btn-outline-people {
  color: #37ab3a;
  border-color: #37ab3a;
}
.btn-outline-people:hover {
  color: #fff;
  background-color: #37ab3a;
  border-color: #37ab3a;
}
.btn-outline-people:focus, .btn-outline-people.focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 171, 58, 0.5);
}
.btn-outline-people.disabled, .btn-outline-people:disabled {
  color: #37ab3a;
  background-color: transparent;
}
.btn-outline-people:not(:disabled):not(.disabled):active, .btn-outline-people:not(:disabled):not(.disabled).active, .show > .btn-outline-people.dropdown-toggle {
  color: #fff;
  background-color: #37ab3a;
  border-color: #37ab3a;
}
.btn-outline-people:not(:disabled):not(.disabled):active:focus, .btn-outline-people:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-people.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 171, 58, 0.5);
}

.btn-outline-business {
  color: #093a7f;
  border-color: #093a7f;
}
.btn-outline-business:hover {
  color: #fff;
  background-color: #093a7f;
  border-color: #093a7f;
}
.btn-outline-business:focus, .btn-outline-business.focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 58, 127, 0.5);
}
.btn-outline-business.disabled, .btn-outline-business:disabled {
  color: #093a7f;
  background-color: transparent;
}
.btn-outline-business:not(:disabled):not(.disabled):active, .btn-outline-business:not(:disabled):not(.disabled).active, .show > .btn-outline-business.dropdown-toggle {
  color: #fff;
  background-color: #093a7f;
  border-color: #093a7f;
}
.btn-outline-business:not(:disabled):not(.disabled):active:focus, .btn-outline-business:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-business.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 58, 127, 0.5);
}

.btn-outline-manager {
  color: #581e80;
  border-color: #581e80;
}
.btn-outline-manager:hover {
  color: #fff;
  background-color: #581e80;
  border-color: #581e80;
}
.btn-outline-manager:focus, .btn-outline-manager.focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 30, 128, 0.5);
}
.btn-outline-manager.disabled, .btn-outline-manager:disabled {
  color: #581e80;
  background-color: transparent;
}
.btn-outline-manager:not(:disabled):not(.disabled):active, .btn-outline-manager:not(:disabled):not(.disabled).active, .show > .btn-outline-manager.dropdown-toggle {
  color: #fff;
  background-color: #581e80;
  border-color: #581e80;
}
.btn-outline-manager:not(:disabled):not(.disabled):active:focus, .btn-outline-manager:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-manager.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 30, 128, 0.5);
}

.btn-outline-safezone {
  color: #3a15b2;
  border-color: #3a15b2;
}
.btn-outline-safezone:hover {
  color: #fff;
  background-color: #3a15b2;
  border-color: #3a15b2;
}
.btn-outline-safezone:focus, .btn-outline-safezone.focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 21, 178, 0.5);
}
.btn-outline-safezone.disabled, .btn-outline-safezone:disabled {
  color: #3a15b2;
  background-color: transparent;
}
.btn-outline-safezone:not(:disabled):not(.disabled):active, .btn-outline-safezone:not(:disabled):not(.disabled).active, .show > .btn-outline-safezone.dropdown-toggle {
  color: #fff;
  background-color: #3a15b2;
  border-color: #3a15b2;
}
.btn-outline-safezone:not(:disabled):not(.disabled):active:focus, .btn-outline-safezone:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-safezone.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 21, 178, 0.5);
}

.btn-outline-contract {
  color: #b25126;
  border-color: #b25126;
}
.btn-outline-contract:hover {
  color: #fff;
  background-color: #b25126;
  border-color: #b25126;
}
.btn-outline-contract:focus, .btn-outline-contract.focus {
  box-shadow: 0 0 0 0.2rem rgba(178, 81, 38, 0.5);
}
.btn-outline-contract.disabled, .btn-outline-contract:disabled {
  color: #b25126;
  background-color: transparent;
}
.btn-outline-contract:not(:disabled):not(.disabled):active, .btn-outline-contract:not(:disabled):not(.disabled).active, .show > .btn-outline-contract.dropdown-toggle {
  color: #fff;
  background-color: #b25126;
  border-color: #b25126;
}
.btn-outline-contract:not(:disabled):not(.disabled):active:focus, .btn-outline-contract:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-contract.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(178, 81, 38, 0.5);
}

.btn-outline-forest {
  color: #153E15;
  border-color: #153E15;
}
.btn-outline-forest:hover {
  color: #fff;
  background-color: #153E15;
  border-color: #153E15;
}
.btn-outline-forest:focus, .btn-outline-forest.focus {
  box-shadow: 0 0 0 0.2rem rgba(21, 62, 21, 0.5);
}
.btn-outline-forest.disabled, .btn-outline-forest:disabled {
  color: #153E15;
  background-color: transparent;
}
.btn-outline-forest:not(:disabled):not(.disabled):active, .btn-outline-forest:not(:disabled):not(.disabled).active, .show > .btn-outline-forest.dropdown-toggle {
  color: #fff;
  background-color: #153E15;
  border-color: #153E15;
}
.btn-outline-forest:not(:disabled):not(.disabled):active:focus, .btn-outline-forest:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-forest.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(21, 62, 21, 0.5);
}

.btn-outline-land {
  color: #00801d;
  border-color: #00801d;
}
.btn-outline-land:hover {
  color: #fff;
  background-color: #00801d;
  border-color: #00801d;
}
.btn-outline-land:focus, .btn-outline-land.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 128, 29, 0.5);
}
.btn-outline-land.disabled, .btn-outline-land:disabled {
  color: #00801d;
  background-color: transparent;
}
.btn-outline-land:not(:disabled):not(.disabled):active, .btn-outline-land:not(:disabled):not(.disabled).active, .show > .btn-outline-land.dropdown-toggle {
  color: #fff;
  background-color: #00801d;
  border-color: #00801d;
}
.btn-outline-land:not(:disabled):not(.disabled):active:focus, .btn-outline-land:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-land.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 128, 29, 0.5);
}

.btn-outline-safety {
  color: #80211f;
  border-color: #80211f;
}
.btn-outline-safety:hover {
  color: #fff;
  background-color: #80211f;
  border-color: #80211f;
}
.btn-outline-safety:focus, .btn-outline-safety.focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 33, 31, 0.5);
}
.btn-outline-safety.disabled, .btn-outline-safety:disabled {
  color: #80211f;
  background-color: transparent;
}
.btn-outline-safety:not(:disabled):not(.disabled):active, .btn-outline-safety:not(:disabled):not(.disabled).active, .show > .btn-outline-safety.dropdown-toggle {
  color: #fff;
  background-color: #80211f;
  border-color: #80211f;
}
.btn-outline-safety:not(:disabled):not(.disabled):active:focus, .btn-outline-safety:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-safety.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 33, 31, 0.5);
}

.btn-outline-contractor {
  color: #801d3a;
  border-color: #801d3a;
}
.btn-outline-contractor:hover {
  color: #fff;
  background-color: #801d3a;
  border-color: #801d3a;
}
.btn-outline-contractor:focus, .btn-outline-contractor.focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 29, 58, 0.5);
}
.btn-outline-contractor.disabled, .btn-outline-contractor:disabled {
  color: #801d3a;
  background-color: transparent;
}
.btn-outline-contractor:not(:disabled):not(.disabled):active, .btn-outline-contractor:not(:disabled):not(.disabled).active, .show > .btn-outline-contractor.dropdown-toggle {
  color: #fff;
  background-color: #801d3a;
  border-color: #801d3a;
}
.btn-outline-contractor:not(:disabled):not(.disabled):active:focus, .btn-outline-contractor:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-contractor.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 29, 58, 0.5);
}

.btn-outline-sub {
  color: #2f3580;
  border-color: #2f3580;
}
.btn-outline-sub:hover {
  color: #fff;
  background-color: #2f3580;
  border-color: #2f3580;
}
.btn-outline-sub:focus, .btn-outline-sub.focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 53, 128, 0.5);
}
.btn-outline-sub.disabled, .btn-outline-sub:disabled {
  color: #2f3580;
  background-color: transparent;
}
.btn-outline-sub:not(:disabled):not(.disabled):active, .btn-outline-sub:not(:disabled):not(.disabled).active, .show > .btn-outline-sub.dropdown-toggle {
  color: #fff;
  background-color: #2f3580;
  border-color: #2f3580;
}
.btn-outline-sub:not(:disabled):not(.disabled):active:focus, .btn-outline-sub:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-sub.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 53, 128, 0.5);
}

.btn-outline-workcrew {
  color: #4f3587;
  border-color: #4f3587;
}
.btn-outline-workcrew:hover {
  color: #fff;
  background-color: #4f3587;
  border-color: #4f3587;
}
.btn-outline-workcrew:focus, .btn-outline-workcrew.focus {
  box-shadow: 0 0 0 0.2rem rgba(79, 53, 135, 0.5);
}
.btn-outline-workcrew.disabled, .btn-outline-workcrew:disabled {
  color: #4f3587;
  background-color: transparent;
}
.btn-outline-workcrew:not(:disabled):not(.disabled):active, .btn-outline-workcrew:not(:disabled):not(.disabled).active, .show > .btn-outline-workcrew.dropdown-toggle {
  color: #fff;
  background-color: #4f3587;
  border-color: #4f3587;
}
.btn-outline-workcrew:not(:disabled):not(.disabled):active:focus, .btn-outline-workcrew:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-workcrew.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(79, 53, 135, 0.5);
}

.btn-outline-foreman {
  color: #482780;
  border-color: #482780;
}
.btn-outline-foreman:hover {
  color: #fff;
  background-color: #482780;
  border-color: #482780;
}
.btn-outline-foreman:focus, .btn-outline-foreman.focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 39, 128, 0.5);
}
.btn-outline-foreman.disabled, .btn-outline-foreman:disabled {
  color: #482780;
  background-color: transparent;
}
.btn-outline-foreman:not(:disabled):not(.disabled):active, .btn-outline-foreman:not(:disabled):not(.disabled).active, .show > .btn-outline-foreman.dropdown-toggle {
  color: #fff;
  background-color: #482780;
  border-color: #482780;
}
.btn-outline-foreman:not(:disabled):not(.disabled):active:focus, .btn-outline-foreman:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-foreman.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 39, 128, 0.5);
}

.btn-outline-crew {
  color: #412187;
  border-color: #412187;
}
.btn-outline-crew:hover {
  color: #fff;
  background-color: #412187;
  border-color: #412187;
}
.btn-outline-crew:focus, .btn-outline-crew.focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 33, 135, 0.5);
}
.btn-outline-crew.disabled, .btn-outline-crew:disabled {
  color: #412187;
  background-color: transparent;
}
.btn-outline-crew:not(:disabled):not(.disabled):active, .btn-outline-crew:not(:disabled):not(.disabled).active, .show > .btn-outline-crew.dropdown-toggle {
  color: #fff;
  background-color: #412187;
  border-color: #412187;
}
.btn-outline-crew:not(:disabled):not(.disabled):active:focus, .btn-outline-crew:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-crew.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 33, 135, 0.5);
}

.btn-outline-truck {
  color: #fd2242;
  border-color: #fd2242;
}
.btn-outline-truck:hover {
  color: #fff;
  background-color: #fd2242;
  border-color: #fd2242;
}
.btn-outline-truck:focus, .btn-outline-truck.focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 34, 66, 0.5);
}
.btn-outline-truck.disabled, .btn-outline-truck:disabled {
  color: #fd2242;
  background-color: transparent;
}
.btn-outline-truck:not(:disabled):not(.disabled):active, .btn-outline-truck:not(:disabled):not(.disabled).active, .show > .btn-outline-truck.dropdown-toggle {
  color: #fff;
  background-color: #fd2242;
  border-color: #fd2242;
}
.btn-outline-truck:not(:disabled):not(.disabled):active:focus, .btn-outline-truck:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-truck.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 34, 66, 0.5);
}

.btn-outline-visitor {
  color: #ff3131;
  border-color: #ff3131;
}
.btn-outline-visitor:hover {
  color: #fff;
  background-color: #ff3131;
  border-color: #ff3131;
}
.btn-outline-visitor:focus, .btn-outline-visitor.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 49, 49, 0.5);
}
.btn-outline-visitor.disabled, .btn-outline-visitor:disabled {
  color: #ff3131;
  background-color: transparent;
}
.btn-outline-visitor:not(:disabled):not(.disabled):active, .btn-outline-visitor:not(:disabled):not(.disabled).active, .show > .btn-outline-visitor.dropdown-toggle {
  color: #fff;
  background-color: #ff3131;
  border-color: #ff3131;
}
.btn-outline-visitor:not(:disabled):not(.disabled):active:focus, .btn-outline-visitor:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-visitor.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 49, 49, 0.5);
}

.btn-outline-workday {
  color: #ff943b;
  border-color: #ff943b;
}
.btn-outline-workday:hover {
  color: #212529;
  background-color: #ff943b;
  border-color: #ff943b;
}
.btn-outline-workday:focus, .btn-outline-workday.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 148, 59, 0.5);
}
.btn-outline-workday.disabled, .btn-outline-workday:disabled {
  color: #ff943b;
  background-color: transparent;
}
.btn-outline-workday:not(:disabled):not(.disabled):active, .btn-outline-workday:not(:disabled):not(.disabled).active, .show > .btn-outline-workday.dropdown-toggle {
  color: #212529;
  background-color: #ff943b;
  border-color: #ff943b;
}
.btn-outline-workday:not(:disabled):not(.disabled):active:focus, .btn-outline-workday:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-workday.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 148, 59, 0.5);
}

.btn-outline-worksite {
  color: #fd5e53;
  border-color: #fd5e53;
}
.btn-outline-worksite:hover {
  color: #fff;
  background-color: #fd5e53;
  border-color: #fd5e53;
}
.btn-outline-worksite:focus, .btn-outline-worksite.focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 94, 83, 0.5);
}
.btn-outline-worksite.disabled, .btn-outline-worksite:disabled {
  color: #fd5e53;
  background-color: transparent;
}
.btn-outline-worksite:not(:disabled):not(.disabled):active, .btn-outline-worksite:not(:disabled):not(.disabled).active, .show > .btn-outline-worksite.dropdown-toggle {
  color: #fff;
  background-color: #fd5e53;
  border-color: #fd5e53;
}
.btn-outline-worksite:not(:disabled):not(.disabled):active:focus, .btn-outline-worksite:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-worksite.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 94, 83, 0.5);
}

.btn-outline-welcome {
  color: #0342a8;
  border-color: #0342a8;
}
.btn-outline-welcome:hover {
  color: #fff;
  background-color: #0342a8;
  border-color: #0342a8;
}
.btn-outline-welcome:focus, .btn-outline-welcome.focus {
  box-shadow: 0 0 0 0.2rem rgba(3, 66, 168, 0.5);
}
.btn-outline-welcome.disabled, .btn-outline-welcome:disabled {
  color: #0342a8;
  background-color: transparent;
}
.btn-outline-welcome:not(:disabled):not(.disabled):active, .btn-outline-welcome:not(:disabled):not(.disabled).active, .show > .btn-outline-welcome.dropdown-toggle {
  color: #fff;
  background-color: #0342a8;
  border-color: #0342a8;
}
.btn-outline-welcome:not(:disabled):not(.disabled):active:focus, .btn-outline-welcome:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-welcome.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(3, 66, 168, 0.5);
}

.btn-outline-error {
  color: #fd0921;
  border-color: #fd0921;
}
.btn-outline-error:hover {
  color: #fff;
  background-color: #fd0921;
  border-color: #fd0921;
}
.btn-outline-error:focus, .btn-outline-error.focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 9, 33, 0.5);
}
.btn-outline-error.disabled, .btn-outline-error:disabled {
  color: #fd0921;
  background-color: transparent;
}
.btn-outline-error:not(:disabled):not(.disabled):active, .btn-outline-error:not(:disabled):not(.disabled).active, .show > .btn-outline-error.dropdown-toggle {
  color: #fff;
  background-color: #fd0921;
  border-color: #fd0921;
}
.btn-outline-error:not(:disabled):not(.disabled):active:focus, .btn-outline-error:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-error.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 9, 33, 0.5);
}

.btn-outline-good {
  color: #3b8c3b;
  border-color: #3b8c3b;
}
.btn-outline-good:hover {
  color: #fff;
  background-color: #3b8c3b;
  border-color: #3b8c3b;
}
.btn-outline-good:focus, .btn-outline-good.focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 140, 59, 0.5);
}
.btn-outline-good.disabled, .btn-outline-good:disabled {
  color: #3b8c3b;
  background-color: transparent;
}
.btn-outline-good:not(:disabled):not(.disabled):active, .btn-outline-good:not(:disabled):not(.disabled).active, .show > .btn-outline-good.dropdown-toggle {
  color: #fff;
  background-color: #3b8c3b;
  border-color: #3b8c3b;
}
.btn-outline-good:not(:disabled):not(.disabled):active:focus, .btn-outline-good:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-good.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 140, 59, 0.5);
}

.btn-outline-zero {
  color: #dddddd;
  border-color: #dddddd;
}
.btn-outline-zero:hover {
  color: #212529;
  background-color: #dddddd;
  border-color: #dddddd;
}
.btn-outline-zero:focus, .btn-outline-zero.focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 221, 221, 0.5);
}
.btn-outline-zero.disabled, .btn-outline-zero:disabled {
  color: #dddddd;
  background-color: transparent;
}
.btn-outline-zero:not(:disabled):not(.disabled):active, .btn-outline-zero:not(:disabled):not(.disabled).active, .show > .btn-outline-zero.dropdown-toggle {
  color: #212529;
  background-color: #dddddd;
  border-color: #dddddd;
}
.btn-outline-zero:not(:disabled):not(.disabled):active:focus, .btn-outline-zero:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-zero.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 221, 221, 0.5);
}

.btn-outline-eee {
  color: #fafafa;
  border-color: #fafafa;
}
.btn-outline-eee:hover {
  color: #212529;
  background-color: #fafafa;
  border-color: #fafafa;
}
.btn-outline-eee:focus, .btn-outline-eee.focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5);
}
.btn-outline-eee.disabled, .btn-outline-eee:disabled {
  color: #fafafa;
  background-color: transparent;
}
.btn-outline-eee:not(:disabled):not(.disabled):active, .btn-outline-eee:not(:disabled):not(.disabled).active, .show > .btn-outline-eee.dropdown-toggle {
  color: #212529;
  background-color: #fafafa;
  border-color: #fafafa;
}
.btn-outline-eee:not(:disabled):not(.disabled):active:focus, .btn-outline-eee:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-eee.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5);
}

.btn-outline-none {
  color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-none:hover {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-none:focus, .btn-outline-none.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-none.disabled, .btn-outline-none:disabled {
  color: #ffffff;
  background-color: transparent;
}
.btn-outline-none:not(:disabled):not(.disabled):active, .btn-outline-none:not(:disabled):not(.disabled).active, .show > .btn-outline-none.dropdown-toggle {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-none:not(:disabled):not(.disabled):active:focus, .btn-outline-none:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-none.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-delete {
  color: #8A0303;
  border-color: #8A0303;
}
.btn-outline-delete:hover {
  color: #fff;
  background-color: #8A0303;
  border-color: #8A0303;
}
.btn-outline-delete:focus, .btn-outline-delete.focus {
  box-shadow: 0 0 0 0.2rem rgba(138, 3, 3, 0.5);
}
.btn-outline-delete.disabled, .btn-outline-delete:disabled {
  color: #8A0303;
  background-color: transparent;
}
.btn-outline-delete:not(:disabled):not(.disabled):active, .btn-outline-delete:not(:disabled):not(.disabled).active, .show > .btn-outline-delete.dropdown-toggle {
  color: #fff;
  background-color: #8A0303;
  border-color: #8A0303;
}
.btn-outline-delete:not(:disabled):not(.disabled):active:focus, .btn-outline-delete:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-delete.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(138, 3, 3, 0.5);
}

.btn-outline-lightgray {
  color: #999999;
  border-color: #999999;
}
.btn-outline-lightgray:hover {
  color: #212529;
  background-color: #999999;
  border-color: #999999;
}
.btn-outline-lightgray:focus, .btn-outline-lightgray.focus {
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}
.btn-outline-lightgray.disabled, .btn-outline-lightgray:disabled {
  color: #999999;
  background-color: transparent;
}
.btn-outline-lightgray:not(:disabled):not(.disabled):active, .btn-outline-lightgray:not(:disabled):not(.disabled).active, .show > .btn-outline-lightgray.dropdown-toggle {
  color: #212529;
  background-color: #999999;
  border-color: #999999;
}
.btn-outline-lightgray:not(:disabled):not(.disabled):active:focus, .btn-outline-lightgray:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-lightgray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.badge-journey {
  color: #fff;
  background-color: #4687c7;
}
a.badge-journey:hover, a.badge-journey:focus {
  color: #fff;
  background-color: #336da7;
}
a.badge-journey:focus, a.badge-journey.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(70, 135, 199, 0.5);
}

.badge-kokua {
  color: #fff;
  background-color: #3378b3;
}
a.badge-kokua:hover, a.badge-kokua:focus {
  color: #fff;
  background-color: #285d8b;
}
a.badge-kokua:focus, a.badge-kokua.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(51, 120, 179, 0.5);
}

.badge-kokual {
  color: #fff;
  background-color: rgba(84, 120, 191, 0.2);
}
a.badge-kokual:hover, a.badge-kokual:focus {
  color: #fff;
  background-color: rgba(61, 95, 163, 0.2);
}
a.badge-kokual:focus, a.badge-kokual.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(84, 120, 191, 0.5);
}

.badge-kōkua {
  color: #fff;
  background-color: #3378b3;
}
a.badge-kōkua:hover, a.badge-kōkua:focus {
  color: #fff;
  background-color: #285d8b;
}
a.badge-kōkua:focus, a.badge-kōkua.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(51, 120, 179, 0.5);
}

.badge-ohana {
  color: #fff;
  background-color: #3e6bad;
}
a.badge-ohana:hover, a.badge-ohana:focus {
  color: #fff;
  background-color: #315487;
}
a.badge-ohana:focus, a.badge-ohana.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(62, 107, 173, 0.5);
}

.badge-ohanal {
  color: #212529;
  background-color: rgba(141, 208, 89, 0.2);
}
a.badge-ohanal:hover, a.badge-ohanal:focus {
  color: #212529;
  background-color: rgba(114, 192, 54, 0.2);
}
a.badge-ohanal:focus, a.badge-ohanal.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(141, 208, 89, 0.5);
}

.badge-kula {
  color: #fff;
  background-color: #668712;
}
a.badge-kula:hover, a.badge-kula:focus {
  color: #fff;
  background-color: #445a0c;
}
a.badge-kula:focus, a.badge-kula.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(102, 135, 18, 0.5);
}

.badge-kulal {
  color: #fff;
  background-color: rgba(102, 135, 18, 0.2);
}
a.badge-kulal:hover, a.badge-kulal:focus {
  color: #fff;
  background-color: rgba(68, 90, 12, 0.2);
}
a.badge-kulal:focus, a.badge-kulal.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(102, 135, 18, 0.5);
}

.badge-hui {
  color: #fff;
  background-color: #093a7f;
}
a.badge-hui:hover, a.badge-hui:focus {
  color: #fff;
  background-color: #06244f;
}
a.badge-hui:focus, a.badge-hui.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(9, 58, 127, 0.5);
}

.badge-huil {
  color: #fff;
  background-color: rgba(9, 58, 127, 0.2);
}
a.badge-huil:hover, a.badge-huil:focus {
  color: #fff;
  background-color: rgba(6, 36, 79, 0.2);
}
a.badge-huil:focus, a.badge-huil.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(9, 58, 127, 0.5);
}

.badge-dbs {
  color: #fff;
  background-color: #668712;
}
a.badge-dbs:hover, a.badge-dbs:focus {
  color: #fff;
  background-color: #445a0c;
}
a.badge-dbs:focus, a.badge-dbs.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(102, 135, 18, 0.5);
}

.badge-dbalpha {
  color: #fff;
  background-color: #998712;
}
a.badge-dbalpha:hover, a.badge-dbalpha:focus {
  color: #fff;
  background-color: #6b5f0d;
}
a.badge-dbalpha:focus, a.badge-dbalpha.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(153, 135, 18, 0.5);
}

.badge-dbresource {
  color: #fff;
  background-color: #cc8712;
}
a.badge-dbresource:hover, a.badge-dbresource:focus {
  color: #fff;
  background-color: #9d680e;
}
a.badge-dbresource:focus, a.badge-dbresource.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(204, 135, 18, 0.5);
}

.badge-dbthing {
  color: #212529;
  background-color: #ff8712;
}
a.badge-dbthing:hover, a.badge-dbthing:focus {
  color: #212529;
  background-color: #de6e00;
}
a.badge-dbthing:focus, a.badge-dbthing.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 135, 18, 0.5);
}

.badge-dbsl {
  color: #212529;
  background-color: #e0e7d0;
}
a.badge-dbsl:hover, a.badge-dbsl:focus {
  color: #212529;
  background-color: #cad6ae;
}
a.badge-dbsl:focus, a.badge-dbsl.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(224, 231, 208, 0.5);
}

.badge-superadmin {
  color: #fff;
  background-color: #4886b3;
}
a.badge-superadmin:hover, a.badge-superadmin:focus {
  color: #fff;
  background-color: #396b8f;
}
a.badge-superadmin:focus, a.badge-superadmin.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(72, 134, 179, 0.5);
}

.badge-admin {
  color: #fff;
  background-color: #745ab3;
}
a.badge-admin:hover, a.badge-admin:focus {
  color: #fff;
  background-color: #5c4595;
}
a.badge-admin:focus, a.badge-admin.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(116, 90, 179, 0.5);
}

.badge-supporter {
  color: #fff;
  background-color: #B350A6;
}
a.badge-supporter:hover, a.badge-supporter:focus {
  color: #fff;
  background-color: #913f86;
}
a.badge-supporter:focus, a.badge-supporter.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(179, 80, 166, 0.5);
}

.badge-appliedfor {
  color: #fff;
  background-color: #d72e81;
}
a.badge-appliedfor:hover, a.badge-appliedfor:focus {
  color: #fff;
  background-color: #b02268;
}
a.badge-appliedfor:focus, a.badge-appliedfor.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(215, 46, 129, 0.5);
}

.badge-appliedforl {
  color: #fff;
  background-color: #d72e81;
}
a.badge-appliedforl:hover, a.badge-appliedforl:focus {
  color: #fff;
  background-color: #b02268;
}
a.badge-appliedforl:focus, a.badge-appliedforl.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(215, 46, 129, 0.5);
}

.badge-available {
  color: #212529;
  background-color: #d4ffd4;
}
a.badge-available:hover, a.badge-available:focus {
  color: #212529;
  background-color: #a1ffa1;
}
a.badge-available:focus, a.badge-available.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(212, 255, 212, 0.5);
}

.badge-totest {
  color: #fff;
  background-color: #7a82d7;
}
a.badge-totest:hover, a.badge-totest:focus {
  color: #fff;
  background-color: #535dcb;
}
a.badge-totest:focus, a.badge-totest.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(122, 130, 215, 0.5);
}

.badge-active {
  color: #212529;
  background-color: #77d077;
}
a.badge-active:hover, a.badge-active:focus {
  color: #212529;
  background-color: #51c351;
}
a.badge-active:focus, a.badge-active.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(119, 208, 119, 0.5);
}

.badge-inactive {
  color: #fff;
  background-color: #d07777;
}
a.badge-inactive:hover, a.badge-inactive:focus {
  color: #fff;
  background-color: #c35151;
}
a.badge-inactive:focus, a.badge-inactive.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(208, 119, 119, 0.5);
}

.badge-attention {
  color: #212529;
  background-color: #efe916;
}
a.badge-attention:hover, a.badge-attention:focus {
  color: #212529;
  background-color: #c5bf0d;
}
a.badge-attention:focus, a.badge-attention.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(239, 233, 22, 0.5);
}

.badge-closed {
  color: #fff;
  background-color: #ef5451;
}
a.badge-closed:hover, a.badge-closed:focus {
  color: #fff;
  background-color: #eb2622;
}
a.badge-closed:focus, a.badge-closed.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(239, 84, 81, 0.5);
}

.badge-help {
  color: #212529;
  background-color: #b8ef56;
}
a.badge-help:hover, a.badge-help:focus {
  color: #212529;
  background-color: #a4eb27;
}
a.badge-help:focus, a.badge-help.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(184, 239, 86, 0.5);
}

.badge-expired {
  color: #fff;
  background-color: #d72c47;
}
a.badge-expired:hover, a.badge-expired:focus {
  color: #fff;
  background-color: #af2138;
}
a.badge-expired:focus, a.badge-expired.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(215, 44, 71, 0.5);
}

.badge-abandoned {
  color: #fff;
  background-color: #b33735;
}
a.badge-abandoned:hover, a.badge-abandoned:focus {
  color: #fff;
  background-color: #8c2b29;
}
a.badge-abandoned:focus, a.badge-abandoned.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(179, 55, 53, 0.5);
}

.badge-cmd {
  color: #fff;
  background-color: #00801d;
}
a.badge-cmd:hover, a.badge-cmd:focus {
  color: #fff;
  background-color: #004d11;
}
a.badge-cmd:focus, a.badge-cmd.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 128, 29, 0.5);
}

.badge-sysadmin {
  color: #fff;
  background-color: #d71cff;
}
a.badge-sysadmin:hover, a.badge-sysadmin:focus {
  color: #fff;
  background-color: #bf00e8;
}
a.badge-sysadmin:focus, a.badge-sysadmin.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(215, 28, 255, 0.5);
}

.badge-super {
  color: #fff;
  background-color: #be3aff;
}
a.badge-super:hover, a.badge-super:focus {
  color: #fff;
  background-color: #ad07ff;
}
a.badge-super:focus, a.badge-super.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(190, 58, 255, 0.5);
}

.badge-dns {
  color: #fff;
  background-color: #801c24;
}
a.badge-dns:hover, a.badge-dns:focus {
  color: #fff;
  background-color: #561318;
}
a.badge-dns:focus, a.badge-dns.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 28, 36, 0.5);
}

.badge-network {
  color: #fff;
  background-color: #0954f4;
}
a.badge-network:hover, a.badge-network:focus {
  color: #fff;
  background-color: #0743c3;
}
a.badge-network:focus, a.badge-network.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(9, 84, 244, 0.5);
}

.badge-backups {
  color: #fff;
  background-color: #26803b;
}
a.badge-backups:hover, a.badge-backups:focus {
  color: #fff;
  background-color: #1a5929;
}
a.badge-backups:focus, a.badge-backups.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 128, 59, 0.5);
}

.badge-hardware {
  color: #fff;
  background-color: #d7223b;
}
a.badge-hardware:hover, a.badge-hardware:focus {
  color: #fff;
  background-color: #ab1b2f;
}
a.badge-hardware:focus, a.badge-hardware.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(215, 34, 59, 0.5);
}

.badge-people {
  color: #fff;
  background-color: #37ab3a;
}
a.badge-people:hover, a.badge-people:focus {
  color: #fff;
  background-color: #2b842d;
}
a.badge-people:focus, a.badge-people.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(55, 171, 58, 0.5);
}

.badge-business {
  color: #fff;
  background-color: #093a7f;
}
a.badge-business:hover, a.badge-business:focus {
  color: #fff;
  background-color: #06244f;
}
a.badge-business:focus, a.badge-business.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(9, 58, 127, 0.5);
}

.badge-manager {
  color: #fff;
  background-color: #581e80;
}
a.badge-manager:hover, a.badge-manager:focus {
  color: #fff;
  background-color: #3c1457;
}
a.badge-manager:focus, a.badge-manager.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(88, 30, 128, 0.5);
}

.badge-safezone {
  color: #fff;
  background-color: #3a15b2;
}
a.badge-safezone:hover, a.badge-safezone:focus {
  color: #fff;
  background-color: #2b1084;
}
a.badge-safezone:focus, a.badge-safezone.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(58, 21, 178, 0.5);
}

.badge-contract {
  color: #fff;
  background-color: #b25126;
}
a.badge-contract:hover, a.badge-contract:focus {
  color: #fff;
  background-color: #883e1d;
}
a.badge-contract:focus, a.badge-contract.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(178, 81, 38, 0.5);
}

.badge-forest {
  color: #fff;
  background-color: #153E15;
}
a.badge-forest:hover, a.badge-forest:focus {
  color: #fff;
  background-color: #081808;
}
a.badge-forest:focus, a.badge-forest.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(21, 62, 21, 0.5);
}

.badge-land {
  color: #fff;
  background-color: #00801d;
}
a.badge-land:hover, a.badge-land:focus {
  color: #fff;
  background-color: #004d11;
}
a.badge-land:focus, a.badge-land.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 128, 29, 0.5);
}

.badge-safety {
  color: #fff;
  background-color: #80211f;
}
a.badge-safety:hover, a.badge-safety:focus {
  color: #fff;
  background-color: #571615;
}
a.badge-safety:focus, a.badge-safety.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 33, 31, 0.5);
}

.badge-contractor {
  color: #fff;
  background-color: #801d3a;
}
a.badge-contractor:hover, a.badge-contractor:focus {
  color: #fff;
  background-color: #561427;
}
a.badge-contractor:focus, a.badge-contractor.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 29, 58, 0.5);
}

.badge-sub {
  color: #fff;
  background-color: #2f3580;
}
a.badge-sub:hover, a.badge-sub:focus {
  color: #fff;
  background-color: #21265b;
}
a.badge-sub:focus, a.badge-sub.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(47, 53, 128, 0.5);
}

.badge-workcrew {
  color: #fff;
  background-color: #4f3587;
}
a.badge-workcrew:hover, a.badge-workcrew:focus {
  color: #fff;
  background-color: #3a2762;
}
a.badge-workcrew:focus, a.badge-workcrew.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(79, 53, 135, 0.5);
}

.badge-foreman {
  color: #fff;
  background-color: #482780;
}
a.badge-foreman:hover, a.badge-foreman:focus {
  color: #fff;
  background-color: #321b59;
}
a.badge-foreman:focus, a.badge-foreman.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(72, 39, 128, 0.5);
}

.badge-crew {
  color: #fff;
  background-color: #412187;
}
a.badge-crew:hover, a.badge-crew:focus {
  color: #fff;
  background-color: #2d175e;
}
a.badge-crew:focus, a.badge-crew.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(65, 33, 135, 0.5);
}

.badge-truck {
  color: #fff;
  background-color: #fd2242;
}
a.badge-truck:hover, a.badge-truck:focus {
  color: #fff;
  background-color: #ea0224;
}
a.badge-truck:focus, a.badge-truck.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(253, 34, 66, 0.5);
}

.badge-visitor {
  color: #fff;
  background-color: #ff3131;
}
a.badge-visitor:hover, a.badge-visitor:focus {
  color: #fff;
  background-color: #fd0000;
}
a.badge-visitor:focus, a.badge-visitor.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 49, 49, 0.5);
}

.badge-workday {
  color: #212529;
  background-color: #ff943b;
}
a.badge-workday:hover, a.badge-workday:focus {
  color: #212529;
  background-color: #ff7808;
}
a.badge-workday:focus, a.badge-workday.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 148, 59, 0.5);
}

.badge-worksite {
  color: #fff;
  background-color: #fd5e53;
}
a.badge-worksite:hover, a.badge-worksite:focus {
  color: #fff;
  background-color: #fc2f21;
}
a.badge-worksite:focus, a.badge-worksite.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(253, 94, 83, 0.5);
}

.badge-welcome {
  color: #fff;
  background-color: #0342a8;
}
a.badge-welcome:hover, a.badge-welcome:focus {
  color: #fff;
  background-color: #022e76;
}
a.badge-welcome:focus, a.badge-welcome.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(3, 66, 168, 0.5);
}

.badge-error {
  color: #fff;
  background-color: #fd0921;
}
a.badge-error:hover, a.badge-error:focus {
  color: #fff;
  background-color: #d10216;
}
a.badge-error:focus, a.badge-error.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(253, 9, 33, 0.5);
}

.badge-good {
  color: #fff;
  background-color: #3b8c3b;
}
a.badge-good:hover, a.badge-good:focus {
  color: #fff;
  background-color: #2c682c;
}
a.badge-good:focus, a.badge-good.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(59, 140, 59, 0.5);
}

.badge-zero {
  color: #212529;
  background-color: #dddddd;
}
a.badge-zero:hover, a.badge-zero:focus {
  color: #212529;
  background-color: #c4c4c4;
}
a.badge-zero:focus, a.badge-zero.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(221, 221, 221, 0.5);
}

.badge-eee {
  color: #212529;
  background-color: #fafafa;
}
a.badge-eee:hover, a.badge-eee:focus {
  color: #212529;
  background-color: #e1e1e1;
}
a.badge-eee:focus, a.badge-eee.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5);
}

.badge-none {
  color: #212529;
  background-color: #ffffff;
}
a.badge-none:hover, a.badge-none:focus {
  color: #212529;
  background-color: #e6e6e6;
}
a.badge-none:focus, a.badge-none.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-delete {
  color: #fff;
  background-color: #8A0303;
}
a.badge-delete:hover, a.badge-delete:focus {
  color: #fff;
  background-color: #580202;
}
a.badge-delete:focus, a.badge-delete.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(138, 3, 3, 0.5);
}

.badge-lightgray {
  color: #212529;
  background-color: #999999;
}
a.badge-lightgray:hover, a.badge-lightgray:focus {
  color: #212529;
  background-color: gray;
}
a.badge-lightgray:focus, a.badge-lightgray.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

.alert-journey {
  color: #244667;
  background-color: #dae7f4;
  border-color: #cbddef;
}
.alert-journey hr {
  border-top-color: #b8d0e9;
}
.alert-journey .alert-link {
  color: #172c41;
}

.alert-kokua {
  color: #1b3e5d;
  background-color: #d6e4f0;
  border-color: #c6d9ea;
}
.alert-kokua hr {
  border-top-color: #b3cde3;
}
.alert-kokua .alert-link {
  color: #102435;
}

.alert-kokual {
  color: rgba(9, 13, 21, 0.584);
  background-color: rgba(250, 251, 253, 0.84);
  border-color: rgba(248, 249, 252, 0.776);
}
.alert-kokual hr {
  border-top-color: rgba(230, 234, 244, 0.776);
}
.alert-kokual .alert-link {
  color: rgba(0, 0, 0, 0.584);
}

.alert-kōkua {
  color: #1b3e5d;
  background-color: #d6e4f0;
  border-color: #c6d9ea;
}
.alert-kōkua hr {
  border-top-color: #b3cde3;
}
.alert-kōkua .alert-link {
  color: #102435;
}

.alert-ohana {
  color: #20385a;
  background-color: #d8e1ef;
  border-color: #c9d6e8;
}
.alert-ohana hr {
  border-top-color: #b7c8e0;
}
.alert-ohana .alert-link {
  color: #132134;
}

.alert-ohanal {
  color: rgba(15, 22, 10, 0.584);
  background-color: rgba(252, 254, 251, 0.84);
  border-color: rgba(250, 253, 248, 0.776);
}
.alert-ohanal hr {
  border-top-color: rgba(236, 247, 228, 0.776);
}
.alert-ohanal .alert-link {
  color: rgba(0, 0, 0, 0.584);
}

.alert-kula {
  color: #354609;
  background-color: #e0e7d0;
  border-color: #d4ddbd;
}
.alert-kula hr {
  border-top-color: #c9d4ac;
}
.alert-kula .alert-link {
  color: #131903;
}

.alert-kulal {
  color: rgba(11, 15, 2, 0.584);
  background-color: rgba(251, 252, 249, 0.84);
  border-color: rgba(249, 250, 245, 0.776);
}
.alert-kulal hr {
  border-top-color: rgba(239, 242, 228, 0.776);
}
.alert-kulal .alert-link {
  color: rgba(0, 0, 0, 0.584);
}

.alert-hui {
  color: #051e42;
  background-color: #ced8e5;
  border-color: #bac8db;
}
.alert-hui hr {
  border-top-color: #a9bbd2;
}
.alert-hui .alert-link {
  color: #010813;
}

.alert-huil {
  color: rgba(1, 6, 14, 0.584);
  background-color: rgba(248, 250, 252, 0.84);
  border-color: rgba(245, 247, 250, 0.776);
}
.alert-huil hr {
  border-top-color: rgba(228, 233, 242, 0.776);
}
.alert-huil .alert-link {
  color: rgba(0, 0, 0, 0.584);
}

.alert-dbs {
  color: #354609;
  background-color: #e0e7d0;
  border-color: #d4ddbd;
}
.alert-dbs hr {
  border-top-color: #c9d4ac;
}
.alert-dbs .alert-link {
  color: #131903;
}

.alert-dbalpha {
  color: #504609;
  background-color: #ebe7d0;
  border-color: #e2ddbd;
}
.alert-dbalpha hr {
  border-top-color: #dad4ab;
}
.alert-dbalpha .alert-link {
  color: #221e04;
}

.alert-dbresource {
  color: #6a4609;
  background-color: #f5e7d0;
  border-color: #f1ddbd;
}
.alert-dbresource hr {
  border-top-color: #edd2a8;
}
.alert-dbresource .alert-link {
  color: #3b2705;
}

.alert-dbthing {
  color: #854609;
  background-color: #ffe7d0;
  border-color: #ffddbd;
}
.alert-dbthing hr {
  border-top-color: #ffd0a4;
}
.alert-dbthing .alert-link {
  color: #552d06;
}

.alert-dbsl {
  color: #74786c;
  background-color: #f9faf6;
  border-color: #f6f8f2;
}
.alert-dbsl hr {
  border-top-color: #ebefe1;
}
.alert-dbsl .alert-link {
  color: #5a5d54;
}

.alert-superadmin {
  color: #25465d;
  background-color: #dae7f0;
  border-color: #ccddea;
}
.alert-superadmin hr {
  border-top-color: #bad1e3;
}
.alert-superadmin .alert-link {
  color: #162b39;
}

.alert-admin {
  color: #3c2f5d;
  background-color: #e3def0;
  border-color: #d8d1ea;
}
.alert-admin hr {
  border-top-color: #c9bfe2;
}
.alert-admin .alert-link {
  color: #261e3b;
}

.alert-supporter {
  color: #5d2a56;
  background-color: #f0dced;
  border-color: #eacee6;
}
.alert-supporter hr {
  border-top-color: #e2bcdd;
}
.alert-supporter .alert-link {
  color: #3a1a36;
}

.alert-appliedfor {
  color: #701843;
  background-color: #f7d5e6;
  border-color: #f4c4dc;
}
.alert-appliedfor hr {
  border-top-color: #f0afcf;
}
.alert-appliedfor .alert-link {
  color: #460f2a;
}

.alert-appliedforl {
  color: #701843;
  background-color: #f7d5e6;
  border-color: #f4c4dc;
}
.alert-appliedforl hr {
  border-top-color: #f0afcf;
}
.alert-appliedforl .alert-link {
  color: #460f2a;
}

.alert-available {
  color: #6e856e;
  background-color: #f6fff6;
  border-color: #f3fff3;
}
.alert-available hr {
  border-top-color: #daffda;
}
.alert-available .alert-link {
  color: #576957;
}

.alert-totest {
  color: #3f4470;
  background-color: #e4e6f7;
  border-color: #dadcf4;
}
.alert-totest hr {
  border-top-color: #c6c9ee;
}
.alert-totest .alert-link {
  color: #2d304f;
}

.alert-active {
  color: #3e6c3e;
  background-color: #e4f6e4;
  border-color: #d9f2d9;
}
.alert-active hr {
  border-top-color: #c6ecc6;
}
.alert-active .alert-link {
  color: #2b4c2b;
}

.alert-inactive {
  color: #6c3e3e;
  background-color: #f6e4e4;
  border-color: #f2d9d9;
}
.alert-inactive hr {
  border-top-color: #ecc6c6;
}
.alert-inactive .alert-link {
  color: #4c2b2b;
}

.alert-attention {
  color: #7c790b;
  background-color: #fcfbd0;
  border-color: #fbf9be;
}
.alert-attention hr {
  border-top-color: #faf7a6;
}
.alert-attention .alert-link {
  color: #4d4b07;
}

.alert-closed {
  color: #7c2c2a;
  background-color: #fcdddc;
  border-color: #fbcfce;
}
.alert-closed hr {
  border-top-color: #f9b8b6;
}
.alert-closed .alert-link {
  color: #561e1d;
}

.alert-help {
  color: #607c2d;
  background-color: #f1fcdd;
  border-color: #ebfbd0;
}
.alert-help hr {
  border-top-color: #e1f9b9;
}
.alert-help .alert-link {
  color: #43571f;
}

.alert-expired {
  color: #701725;
  background-color: #f7d5da;
  border-color: #f4c4cb;
}
.alert-expired hr {
  border-top-color: #f0afb8;
}
.alert-expired .alert-link {
  color: #460e17;
}

.alert-abandoned {
  color: #5d1d1c;
  background-color: #f0d7d7;
  border-color: #eac7c6;
}
.alert-abandoned hr {
  border-top-color: #e3b5b3;
}
.alert-abandoned .alert-link {
  color: #361110;
}

.alert-cmd {
  color: #00430f;
  background-color: #cce6d2;
  border-color: #b8dbc0;
}
.alert-cmd hr {
  border-top-color: #a7d2b1;
}
.alert-cmd .alert-link {
  color: #001004;
}

.alert-sysadmin {
  color: #700f85;
  background-color: #f7d2ff;
  border-color: #f4bfff;
}
.alert-sysadmin hr {
  border-top-color: #f0a6ff;
}
.alert-sysadmin .alert-link {
  color: #490a57;
}

.alert-super {
  color: #631e85;
  background-color: #f2d8ff;
  border-color: #edc8ff;
}
.alert-super hr {
  border-top-color: #e5afff;
}
.alert-super .alert-link {
  color: #44155b;
}

.alert-dns {
  color: #430f13;
  background-color: #e6d2d3;
  border-color: #dbbfc2;
}
.alert-dns hr {
  border-top-color: #d2afb2;
}
.alert-dns .alert-link {
  color: #190607;
}

.alert-network {
  color: #052c7f;
  background-color: #ceddfd;
  border-color: #bacffc;
}
.alert-network hr {
  border-top-color: #a2befb;
}
.alert-network .alert-link {
  color: #031b4e;
}

.alert-backups {
  color: #14431f;
  background-color: #d4e6d8;
  border-color: #c2dbc8;
}
.alert-backups hr {
  border-top-color: #b2d2ba;
}
.alert-backups .alert-link {
  color: #081c0d;
}

.alert-hardware {
  color: #70121f;
  background-color: #f7d3d8;
  border-color: #f4c1c8;
}
.alert-hardware hr {
  border-top-color: #f0abb5;
}
.alert-hardware .alert-link {
  color: #440b13;
}

.alert-people {
  color: #1d591e;
  background-color: #d7eed8;
  border-color: #c7e7c8;
}
.alert-people hr {
  border-top-color: #b5dfb6;
}
.alert-people .alert-link {
  color: #103311;
}

.alert-business {
  color: #051e42;
  background-color: #ced8e5;
  border-color: #bac8db;
}
.alert-business hr {
  border-top-color: #a9bbd2;
}
.alert-business .alert-link {
  color: #010813;
}

.alert-manager {
  color: #2e1043;
  background-color: #ded2e6;
  border-color: #d0c0db;
}
.alert-manager hr {
  border-top-color: #c4b0d2;
}
.alert-manager .alert-link {
  color: #12061a;
}

.alert-safezone {
  color: #1e0b5d;
  background-color: #d8d0f0;
  border-color: #c8bde9;
}
.alert-safezone hr {
  border-top-color: #b8aae3;
}
.alert-safezone .alert-link {
  color: #0f062f;
}

.alert-contract {
  color: #5d2a14;
  background-color: #f0dcd4;
  border-color: #e9cec2;
}
.alert-contract hr {
  border-top-color: #e2bfaf;
}
.alert-contract .alert-link {
  color: #33170b;
}

.alert-forest {
  color: #0b200b;
  background-color: #d0d8d0;
  border-color: #bdc9bd;
}
.alert-forest hr {
  border-top-color: #afbeaf;
}
.alert-forest .alert-link {
  color: black;
}

.alert-land {
  color: #00430f;
  background-color: #cce6d2;
  border-color: #b8dbc0;
}
.alert-land hr {
  border-top-color: #a7d2b1;
}
.alert-land .alert-link {
  color: #001004;
}

.alert-safety {
  color: #431110;
  background-color: #e6d3d2;
  border-color: #dbc1c0;
}
.alert-safety hr {
  border-top-color: #d2b1b0;
}
.alert-safety .alert-link {
  color: #1a0706;
}

.alert-contractor {
  color: #430f1e;
  background-color: #e6d2d8;
  border-color: #dbc0c8;
}
.alert-contractor hr {
  border-top-color: #d2b0ba;
}
.alert-contractor .alert-link {
  color: #19060b;
}

.alert-sub {
  color: #181c43;
  background-color: #d5d7e6;
  border-color: #c5c6db;
}
.alert-sub hr {
  border-top-color: #b5b7d1;
}
.alert-sub .alert-link {
  color: #0b0c1d;
}

.alert-workcrew {
  color: #291c46;
  background-color: #dcd7e7;
  border-color: #cec6dd;
}
.alert-workcrew hr {
  border-top-color: #c0b6d3;
}
.alert-workcrew .alert-link {
  color: #140d22;
}

.alert-foreman {
  color: #251443;
  background-color: #dad4e6;
  border-color: #ccc3db;
}
.alert-foreman hr {
  border-top-color: #beb3d1;
}
.alert-foreman .alert-link {
  color: #0f081c;
}

.alert-crew {
  color: #221146;
  background-color: #d9d3e7;
  border-color: #cac1dd;
}
.alert-crew hr {
  border-top-color: #bcb1d4;
}
.alert-crew .alert-link {
  color: #0e071d;
}

.alert-truck {
  color: #841222;
  background-color: #ffd3d9;
  border-color: #fec1ca;
}
.alert-truck hr {
  border-top-color: #fea8b5;
}
.alert-truck .alert-link {
  color: #570c16;
}

.alert-visitor {
  color: #851919;
  background-color: #ffd6d6;
  border-color: #ffc5c5;
}
.alert-visitor hr {
  border-top-color: #ffacac;
}
.alert-visitor .alert-link {
  color: #5a1111;
}

.alert-workday {
  color: #854d1f;
  background-color: #ffead8;
  border-color: #ffe1c8;
}
.alert-workday hr {
  border-top-color: #ffd3af;
}
.alert-workday .alert-link {
  color: #5c3515;
}

.alert-worksite {
  color: #84312b;
  background-color: #ffdfdd;
  border-color: #fed2cf;
}
.alert-worksite hr {
  border-top-color: #fdbbb6;
}
.alert-worksite .alert-link {
  color: #5e231e;
}

.alert-welcome {
  color: #022257;
  background-color: #cdd9ee;
  border-color: #b8cae7;
}
.alert-welcome hr {
  border-top-color: #a5bce1;
}
.alert-welcome .alert-link {
  color: #010f25;
}

.alert-error {
  color: #840511;
  background-color: #ffced3;
  border-color: #febac1;
}
.alert-error hr {
  border-top-color: #fea1aa;
}
.alert-error .alert-link {
  color: #53030b;
}

.alert-good {
  color: #1f491f;
  background-color: #d8e8d8;
  border-color: #c8dfc8;
}
.alert-good hr {
  border-top-color: #b8d6b8;
}
.alert-good .alert-link {
  color: #102510;
}

.alert-zero {
  color: #737373;
  background-color: #f8f8f8;
  border-color: whitesmoke;
}
.alert-zero hr {
  border-top-color: #e8e8e8;
}
.alert-zero .alert-link {
  color: #5a5a5a;
}

.alert-eee {
  color: #828282;
  background-color: #fefefe;
  border-color: #fefefe;
}
.alert-eee hr {
  border-top-color: #f1f1f1;
}
.alert-eee .alert-link {
  color: dimgray;
}

.alert-none {
  color: #858585;
  background-color: white;
  border-color: white;
}
.alert-none hr {
  border-top-color: #f2f2f2;
}
.alert-none .alert-link {
  color: #6c6c6c;
}

.alert-delete {
  color: #480202;
  background-color: #e8cdcd;
  border-color: #deb8b8;
}
.alert-delete hr {
  border-top-color: #d6a7a7;
}
.alert-delete .alert-link {
  color: #160101;
}

.alert-lightgray {
  color: #505050;
  background-color: #ebebeb;
  border-color: #e2e2e2;
}
.alert-lightgray hr {
  border-top-color: #d5d5d5;
}
.alert-lightgray .alert-link {
  color: #373737;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.list-group-item-journey {
  color: #244667;
  background-color: #cbddef;
}
.list-group-item-journey.list-group-item-action:hover, .list-group-item-journey.list-group-item-action:focus {
  color: #244667;
  background-color: #b8d0e9;
}
.list-group-item-journey.list-group-item-action.active {
  color: #fff;
  background-color: #244667;
  border-color: #244667;
}

.list-group-item-kokua {
  color: #1b3e5d;
  background-color: #c6d9ea;
}
.list-group-item-kokua.list-group-item-action:hover, .list-group-item-kokua.list-group-item-action:focus {
  color: #1b3e5d;
  background-color: #b3cde3;
}
.list-group-item-kokua.list-group-item-action.active {
  color: #fff;
  background-color: #1b3e5d;
  border-color: #1b3e5d;
}

.list-group-item-kokual {
  color: rgba(9, 13, 21, 0.584);
  background-color: rgba(248, 249, 252, 0.776);
}
.list-group-item-kokual.list-group-item-action:hover, .list-group-item-kokual.list-group-item-action:focus {
  color: rgba(9, 13, 21, 0.584);
  background-color: rgba(230, 234, 244, 0.776);
}
.list-group-item-kokual.list-group-item-action.active {
  color: #fff;
  background-color: rgba(9, 13, 21, 0.584);
  border-color: rgba(9, 13, 21, 0.584);
}

.list-group-item-kōkua {
  color: #1b3e5d;
  background-color: #c6d9ea;
}
.list-group-item-kōkua.list-group-item-action:hover, .list-group-item-kōkua.list-group-item-action:focus {
  color: #1b3e5d;
  background-color: #b3cde3;
}
.list-group-item-kōkua.list-group-item-action.active {
  color: #fff;
  background-color: #1b3e5d;
  border-color: #1b3e5d;
}

.list-group-item-ohana {
  color: #20385a;
  background-color: #c9d6e8;
}
.list-group-item-ohana.list-group-item-action:hover, .list-group-item-ohana.list-group-item-action:focus {
  color: #20385a;
  background-color: #b7c8e0;
}
.list-group-item-ohana.list-group-item-action.active {
  color: #fff;
  background-color: #20385a;
  border-color: #20385a;
}

.list-group-item-ohanal {
  color: rgba(15, 22, 10, 0.584);
  background-color: rgba(250, 253, 248, 0.776);
}
.list-group-item-ohanal.list-group-item-action:hover, .list-group-item-ohanal.list-group-item-action:focus {
  color: rgba(15, 22, 10, 0.584);
  background-color: rgba(236, 247, 228, 0.776);
}
.list-group-item-ohanal.list-group-item-action.active {
  color: #fff;
  background-color: rgba(15, 22, 10, 0.584);
  border-color: rgba(15, 22, 10, 0.584);
}

.list-group-item-kula {
  color: #354609;
  background-color: #d4ddbd;
}
.list-group-item-kula.list-group-item-action:hover, .list-group-item-kula.list-group-item-action:focus {
  color: #354609;
  background-color: #c9d4ac;
}
.list-group-item-kula.list-group-item-action.active {
  color: #fff;
  background-color: #354609;
  border-color: #354609;
}

.list-group-item-kulal {
  color: rgba(11, 15, 2, 0.584);
  background-color: rgba(249, 250, 245, 0.776);
}
.list-group-item-kulal.list-group-item-action:hover, .list-group-item-kulal.list-group-item-action:focus {
  color: rgba(11, 15, 2, 0.584);
  background-color: rgba(239, 242, 228, 0.776);
}
.list-group-item-kulal.list-group-item-action.active {
  color: #fff;
  background-color: rgba(11, 15, 2, 0.584);
  border-color: rgba(11, 15, 2, 0.584);
}

.list-group-item-hui {
  color: #051e42;
  background-color: #bac8db;
}
.list-group-item-hui.list-group-item-action:hover, .list-group-item-hui.list-group-item-action:focus {
  color: #051e42;
  background-color: #a9bbd2;
}
.list-group-item-hui.list-group-item-action.active {
  color: #fff;
  background-color: #051e42;
  border-color: #051e42;
}

.list-group-item-huil {
  color: rgba(1, 6, 14, 0.584);
  background-color: rgba(245, 247, 250, 0.776);
}
.list-group-item-huil.list-group-item-action:hover, .list-group-item-huil.list-group-item-action:focus {
  color: rgba(1, 6, 14, 0.584);
  background-color: rgba(228, 233, 242, 0.776);
}
.list-group-item-huil.list-group-item-action.active {
  color: #fff;
  background-color: rgba(1, 6, 14, 0.584);
  border-color: rgba(1, 6, 14, 0.584);
}

.list-group-item-dbs {
  color: #354609;
  background-color: #d4ddbd;
}
.list-group-item-dbs.list-group-item-action:hover, .list-group-item-dbs.list-group-item-action:focus {
  color: #354609;
  background-color: #c9d4ac;
}
.list-group-item-dbs.list-group-item-action.active {
  color: #fff;
  background-color: #354609;
  border-color: #354609;
}

.list-group-item-dbalpha {
  color: #504609;
  background-color: #e2ddbd;
}
.list-group-item-dbalpha.list-group-item-action:hover, .list-group-item-dbalpha.list-group-item-action:focus {
  color: #504609;
  background-color: #dad4ab;
}
.list-group-item-dbalpha.list-group-item-action.active {
  color: #fff;
  background-color: #504609;
  border-color: #504609;
}

.list-group-item-dbresource {
  color: #6a4609;
  background-color: #f1ddbd;
}
.list-group-item-dbresource.list-group-item-action:hover, .list-group-item-dbresource.list-group-item-action:focus {
  color: #6a4609;
  background-color: #edd2a8;
}
.list-group-item-dbresource.list-group-item-action.active {
  color: #fff;
  background-color: #6a4609;
  border-color: #6a4609;
}

.list-group-item-dbthing {
  color: #854609;
  background-color: #ffddbd;
}
.list-group-item-dbthing.list-group-item-action:hover, .list-group-item-dbthing.list-group-item-action:focus {
  color: #854609;
  background-color: #ffd0a4;
}
.list-group-item-dbthing.list-group-item-action.active {
  color: #fff;
  background-color: #854609;
  border-color: #854609;
}

.list-group-item-dbsl {
  color: #74786c;
  background-color: #f6f8f2;
}
.list-group-item-dbsl.list-group-item-action:hover, .list-group-item-dbsl.list-group-item-action:focus {
  color: #74786c;
  background-color: #ebefe1;
}
.list-group-item-dbsl.list-group-item-action.active {
  color: #fff;
  background-color: #74786c;
  border-color: #74786c;
}

.list-group-item-superadmin {
  color: #25465d;
  background-color: #ccddea;
}
.list-group-item-superadmin.list-group-item-action:hover, .list-group-item-superadmin.list-group-item-action:focus {
  color: #25465d;
  background-color: #bad1e3;
}
.list-group-item-superadmin.list-group-item-action.active {
  color: #fff;
  background-color: #25465d;
  border-color: #25465d;
}

.list-group-item-admin {
  color: #3c2f5d;
  background-color: #d8d1ea;
}
.list-group-item-admin.list-group-item-action:hover, .list-group-item-admin.list-group-item-action:focus {
  color: #3c2f5d;
  background-color: #c9bfe2;
}
.list-group-item-admin.list-group-item-action.active {
  color: #fff;
  background-color: #3c2f5d;
  border-color: #3c2f5d;
}

.list-group-item-supporter {
  color: #5d2a56;
  background-color: #eacee6;
}
.list-group-item-supporter.list-group-item-action:hover, .list-group-item-supporter.list-group-item-action:focus {
  color: #5d2a56;
  background-color: #e2bcdd;
}
.list-group-item-supporter.list-group-item-action.active {
  color: #fff;
  background-color: #5d2a56;
  border-color: #5d2a56;
}

.list-group-item-appliedfor {
  color: #701843;
  background-color: #f4c4dc;
}
.list-group-item-appliedfor.list-group-item-action:hover, .list-group-item-appliedfor.list-group-item-action:focus {
  color: #701843;
  background-color: #f0afcf;
}
.list-group-item-appliedfor.list-group-item-action.active {
  color: #fff;
  background-color: #701843;
  border-color: #701843;
}

.list-group-item-appliedforl {
  color: #701843;
  background-color: #f4c4dc;
}
.list-group-item-appliedforl.list-group-item-action:hover, .list-group-item-appliedforl.list-group-item-action:focus {
  color: #701843;
  background-color: #f0afcf;
}
.list-group-item-appliedforl.list-group-item-action.active {
  color: #fff;
  background-color: #701843;
  border-color: #701843;
}

.list-group-item-available {
  color: #6e856e;
  background-color: #f3fff3;
}
.list-group-item-available.list-group-item-action:hover, .list-group-item-available.list-group-item-action:focus {
  color: #6e856e;
  background-color: #daffda;
}
.list-group-item-available.list-group-item-action.active {
  color: #fff;
  background-color: #6e856e;
  border-color: #6e856e;
}

.list-group-item-totest {
  color: #3f4470;
  background-color: #dadcf4;
}
.list-group-item-totest.list-group-item-action:hover, .list-group-item-totest.list-group-item-action:focus {
  color: #3f4470;
  background-color: #c6c9ee;
}
.list-group-item-totest.list-group-item-action.active {
  color: #fff;
  background-color: #3f4470;
  border-color: #3f4470;
}

.list-group-item-active {
  color: #3e6c3e;
  background-color: #d9f2d9;
}
.list-group-item-active.list-group-item-action:hover, .list-group-item-active.list-group-item-action:focus {
  color: #3e6c3e;
  background-color: #c6ecc6;
}
.list-group-item-active.list-group-item-action.active {
  color: #fff;
  background-color: #3e6c3e;
  border-color: #3e6c3e;
}

.list-group-item-inactive {
  color: #6c3e3e;
  background-color: #f2d9d9;
}
.list-group-item-inactive.list-group-item-action:hover, .list-group-item-inactive.list-group-item-action:focus {
  color: #6c3e3e;
  background-color: #ecc6c6;
}
.list-group-item-inactive.list-group-item-action.active {
  color: #fff;
  background-color: #6c3e3e;
  border-color: #6c3e3e;
}

.list-group-item-attention {
  color: #7c790b;
  background-color: #fbf9be;
}
.list-group-item-attention.list-group-item-action:hover, .list-group-item-attention.list-group-item-action:focus {
  color: #7c790b;
  background-color: #faf7a6;
}
.list-group-item-attention.list-group-item-action.active {
  color: #fff;
  background-color: #7c790b;
  border-color: #7c790b;
}

.list-group-item-closed {
  color: #7c2c2a;
  background-color: #fbcfce;
}
.list-group-item-closed.list-group-item-action:hover, .list-group-item-closed.list-group-item-action:focus {
  color: #7c2c2a;
  background-color: #f9b8b6;
}
.list-group-item-closed.list-group-item-action.active {
  color: #fff;
  background-color: #7c2c2a;
  border-color: #7c2c2a;
}

.list-group-item-help {
  color: #607c2d;
  background-color: #ebfbd0;
}
.list-group-item-help.list-group-item-action:hover, .list-group-item-help.list-group-item-action:focus {
  color: #607c2d;
  background-color: #e1f9b9;
}
.list-group-item-help.list-group-item-action.active {
  color: #fff;
  background-color: #607c2d;
  border-color: #607c2d;
}

.list-group-item-expired {
  color: #701725;
  background-color: #f4c4cb;
}
.list-group-item-expired.list-group-item-action:hover, .list-group-item-expired.list-group-item-action:focus {
  color: #701725;
  background-color: #f0afb8;
}
.list-group-item-expired.list-group-item-action.active {
  color: #fff;
  background-color: #701725;
  border-color: #701725;
}

.list-group-item-abandoned {
  color: #5d1d1c;
  background-color: #eac7c6;
}
.list-group-item-abandoned.list-group-item-action:hover, .list-group-item-abandoned.list-group-item-action:focus {
  color: #5d1d1c;
  background-color: #e3b5b3;
}
.list-group-item-abandoned.list-group-item-action.active {
  color: #fff;
  background-color: #5d1d1c;
  border-color: #5d1d1c;
}

.list-group-item-cmd {
  color: #00430f;
  background-color: #b8dbc0;
}
.list-group-item-cmd.list-group-item-action:hover, .list-group-item-cmd.list-group-item-action:focus {
  color: #00430f;
  background-color: #a7d2b1;
}
.list-group-item-cmd.list-group-item-action.active {
  color: #fff;
  background-color: #00430f;
  border-color: #00430f;
}

.list-group-item-sysadmin {
  color: #700f85;
  background-color: #f4bfff;
}
.list-group-item-sysadmin.list-group-item-action:hover, .list-group-item-sysadmin.list-group-item-action:focus {
  color: #700f85;
  background-color: #f0a6ff;
}
.list-group-item-sysadmin.list-group-item-action.active {
  color: #fff;
  background-color: #700f85;
  border-color: #700f85;
}

.list-group-item-super {
  color: #631e85;
  background-color: #edc8ff;
}
.list-group-item-super.list-group-item-action:hover, .list-group-item-super.list-group-item-action:focus {
  color: #631e85;
  background-color: #e5afff;
}
.list-group-item-super.list-group-item-action.active {
  color: #fff;
  background-color: #631e85;
  border-color: #631e85;
}

.list-group-item-dns {
  color: #430f13;
  background-color: #dbbfc2;
}
.list-group-item-dns.list-group-item-action:hover, .list-group-item-dns.list-group-item-action:focus {
  color: #430f13;
  background-color: #d2afb2;
}
.list-group-item-dns.list-group-item-action.active {
  color: #fff;
  background-color: #430f13;
  border-color: #430f13;
}

.list-group-item-network {
  color: #052c7f;
  background-color: #bacffc;
}
.list-group-item-network.list-group-item-action:hover, .list-group-item-network.list-group-item-action:focus {
  color: #052c7f;
  background-color: #a2befb;
}
.list-group-item-network.list-group-item-action.active {
  color: #fff;
  background-color: #052c7f;
  border-color: #052c7f;
}

.list-group-item-backups {
  color: #14431f;
  background-color: #c2dbc8;
}
.list-group-item-backups.list-group-item-action:hover, .list-group-item-backups.list-group-item-action:focus {
  color: #14431f;
  background-color: #b2d2ba;
}
.list-group-item-backups.list-group-item-action.active {
  color: #fff;
  background-color: #14431f;
  border-color: #14431f;
}

.list-group-item-hardware {
  color: #70121f;
  background-color: #f4c1c8;
}
.list-group-item-hardware.list-group-item-action:hover, .list-group-item-hardware.list-group-item-action:focus {
  color: #70121f;
  background-color: #f0abb5;
}
.list-group-item-hardware.list-group-item-action.active {
  color: #fff;
  background-color: #70121f;
  border-color: #70121f;
}

.list-group-item-people {
  color: #1d591e;
  background-color: #c7e7c8;
}
.list-group-item-people.list-group-item-action:hover, .list-group-item-people.list-group-item-action:focus {
  color: #1d591e;
  background-color: #b5dfb6;
}
.list-group-item-people.list-group-item-action.active {
  color: #fff;
  background-color: #1d591e;
  border-color: #1d591e;
}

.list-group-item-business {
  color: #051e42;
  background-color: #bac8db;
}
.list-group-item-business.list-group-item-action:hover, .list-group-item-business.list-group-item-action:focus {
  color: #051e42;
  background-color: #a9bbd2;
}
.list-group-item-business.list-group-item-action.active {
  color: #fff;
  background-color: #051e42;
  border-color: #051e42;
}

.list-group-item-manager {
  color: #2e1043;
  background-color: #d0c0db;
}
.list-group-item-manager.list-group-item-action:hover, .list-group-item-manager.list-group-item-action:focus {
  color: #2e1043;
  background-color: #c4b0d2;
}
.list-group-item-manager.list-group-item-action.active {
  color: #fff;
  background-color: #2e1043;
  border-color: #2e1043;
}

.list-group-item-safezone {
  color: #1e0b5d;
  background-color: #c8bde9;
}
.list-group-item-safezone.list-group-item-action:hover, .list-group-item-safezone.list-group-item-action:focus {
  color: #1e0b5d;
  background-color: #b8aae3;
}
.list-group-item-safezone.list-group-item-action.active {
  color: #fff;
  background-color: #1e0b5d;
  border-color: #1e0b5d;
}

.list-group-item-contract {
  color: #5d2a14;
  background-color: #e9cec2;
}
.list-group-item-contract.list-group-item-action:hover, .list-group-item-contract.list-group-item-action:focus {
  color: #5d2a14;
  background-color: #e2bfaf;
}
.list-group-item-contract.list-group-item-action.active {
  color: #fff;
  background-color: #5d2a14;
  border-color: #5d2a14;
}

.list-group-item-forest {
  color: #0b200b;
  background-color: #bdc9bd;
}
.list-group-item-forest.list-group-item-action:hover, .list-group-item-forest.list-group-item-action:focus {
  color: #0b200b;
  background-color: #afbeaf;
}
.list-group-item-forest.list-group-item-action.active {
  color: #fff;
  background-color: #0b200b;
  border-color: #0b200b;
}

.list-group-item-land {
  color: #00430f;
  background-color: #b8dbc0;
}
.list-group-item-land.list-group-item-action:hover, .list-group-item-land.list-group-item-action:focus {
  color: #00430f;
  background-color: #a7d2b1;
}
.list-group-item-land.list-group-item-action.active {
  color: #fff;
  background-color: #00430f;
  border-color: #00430f;
}

.list-group-item-safety {
  color: #431110;
  background-color: #dbc1c0;
}
.list-group-item-safety.list-group-item-action:hover, .list-group-item-safety.list-group-item-action:focus {
  color: #431110;
  background-color: #d2b1b0;
}
.list-group-item-safety.list-group-item-action.active {
  color: #fff;
  background-color: #431110;
  border-color: #431110;
}

.list-group-item-contractor {
  color: #430f1e;
  background-color: #dbc0c8;
}
.list-group-item-contractor.list-group-item-action:hover, .list-group-item-contractor.list-group-item-action:focus {
  color: #430f1e;
  background-color: #d2b0ba;
}
.list-group-item-contractor.list-group-item-action.active {
  color: #fff;
  background-color: #430f1e;
  border-color: #430f1e;
}

.list-group-item-sub {
  color: #181c43;
  background-color: #c5c6db;
}
.list-group-item-sub.list-group-item-action:hover, .list-group-item-sub.list-group-item-action:focus {
  color: #181c43;
  background-color: #b5b7d1;
}
.list-group-item-sub.list-group-item-action.active {
  color: #fff;
  background-color: #181c43;
  border-color: #181c43;
}

.list-group-item-workcrew {
  color: #291c46;
  background-color: #cec6dd;
}
.list-group-item-workcrew.list-group-item-action:hover, .list-group-item-workcrew.list-group-item-action:focus {
  color: #291c46;
  background-color: #c0b6d3;
}
.list-group-item-workcrew.list-group-item-action.active {
  color: #fff;
  background-color: #291c46;
  border-color: #291c46;
}

.list-group-item-foreman {
  color: #251443;
  background-color: #ccc3db;
}
.list-group-item-foreman.list-group-item-action:hover, .list-group-item-foreman.list-group-item-action:focus {
  color: #251443;
  background-color: #beb3d1;
}
.list-group-item-foreman.list-group-item-action.active {
  color: #fff;
  background-color: #251443;
  border-color: #251443;
}

.list-group-item-crew {
  color: #221146;
  background-color: #cac1dd;
}
.list-group-item-crew.list-group-item-action:hover, .list-group-item-crew.list-group-item-action:focus {
  color: #221146;
  background-color: #bcb1d4;
}
.list-group-item-crew.list-group-item-action.active {
  color: #fff;
  background-color: #221146;
  border-color: #221146;
}

.list-group-item-truck {
  color: #841222;
  background-color: #fec1ca;
}
.list-group-item-truck.list-group-item-action:hover, .list-group-item-truck.list-group-item-action:focus {
  color: #841222;
  background-color: #fea8b5;
}
.list-group-item-truck.list-group-item-action.active {
  color: #fff;
  background-color: #841222;
  border-color: #841222;
}

.list-group-item-visitor {
  color: #851919;
  background-color: #ffc5c5;
}
.list-group-item-visitor.list-group-item-action:hover, .list-group-item-visitor.list-group-item-action:focus {
  color: #851919;
  background-color: #ffacac;
}
.list-group-item-visitor.list-group-item-action.active {
  color: #fff;
  background-color: #851919;
  border-color: #851919;
}

.list-group-item-workday {
  color: #854d1f;
  background-color: #ffe1c8;
}
.list-group-item-workday.list-group-item-action:hover, .list-group-item-workday.list-group-item-action:focus {
  color: #854d1f;
  background-color: #ffd3af;
}
.list-group-item-workday.list-group-item-action.active {
  color: #fff;
  background-color: #854d1f;
  border-color: #854d1f;
}

.list-group-item-worksite {
  color: #84312b;
  background-color: #fed2cf;
}
.list-group-item-worksite.list-group-item-action:hover, .list-group-item-worksite.list-group-item-action:focus {
  color: #84312b;
  background-color: #fdbbb6;
}
.list-group-item-worksite.list-group-item-action.active {
  color: #fff;
  background-color: #84312b;
  border-color: #84312b;
}

.list-group-item-welcome {
  color: #022257;
  background-color: #b8cae7;
}
.list-group-item-welcome.list-group-item-action:hover, .list-group-item-welcome.list-group-item-action:focus {
  color: #022257;
  background-color: #a5bce1;
}
.list-group-item-welcome.list-group-item-action.active {
  color: #fff;
  background-color: #022257;
  border-color: #022257;
}

.list-group-item-error {
  color: #840511;
  background-color: #febac1;
}
.list-group-item-error.list-group-item-action:hover, .list-group-item-error.list-group-item-action:focus {
  color: #840511;
  background-color: #fea1aa;
}
.list-group-item-error.list-group-item-action.active {
  color: #fff;
  background-color: #840511;
  border-color: #840511;
}

.list-group-item-good {
  color: #1f491f;
  background-color: #c8dfc8;
}
.list-group-item-good.list-group-item-action:hover, .list-group-item-good.list-group-item-action:focus {
  color: #1f491f;
  background-color: #b8d6b8;
}
.list-group-item-good.list-group-item-action.active {
  color: #fff;
  background-color: #1f491f;
  border-color: #1f491f;
}

.list-group-item-zero {
  color: #737373;
  background-color: whitesmoke;
}
.list-group-item-zero.list-group-item-action:hover, .list-group-item-zero.list-group-item-action:focus {
  color: #737373;
  background-color: #e8e8e8;
}
.list-group-item-zero.list-group-item-action.active {
  color: #fff;
  background-color: #737373;
  border-color: #737373;
}

.list-group-item-eee {
  color: #828282;
  background-color: #fefefe;
}
.list-group-item-eee.list-group-item-action:hover, .list-group-item-eee.list-group-item-action:focus {
  color: #828282;
  background-color: #f1f1f1;
}
.list-group-item-eee.list-group-item-action.active {
  color: #fff;
  background-color: #828282;
  border-color: #828282;
}

.list-group-item-none {
  color: #858585;
  background-color: white;
}
.list-group-item-none.list-group-item-action:hover, .list-group-item-none.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}
.list-group-item-none.list-group-item-action.active {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-delete {
  color: #480202;
  background-color: #deb8b8;
}
.list-group-item-delete.list-group-item-action:hover, .list-group-item-delete.list-group-item-action:focus {
  color: #480202;
  background-color: #d6a7a7;
}
.list-group-item-delete.list-group-item-action.active {
  color: #fff;
  background-color: #480202;
  border-color: #480202;
}

.list-group-item-lightgray {
  color: #505050;
  background-color: #e2e2e2;
}
.list-group-item-lightgray.list-group-item-action:hover, .list-group-item-lightgray.list-group-item-action:focus {
  color: #505050;
  background-color: #d5d5d5;
}
.list-group-item-lightgray.list-group-item-action.active {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-journey {
  background-color: #4687c7 !important;
}

a.bg-journey:hover, a.bg-journey:focus,
button.bg-journey:hover,
button.bg-journey:focus {
  background-color: #336da7 !important;
}

.bg-kokua {
  background-color: #3378b3 !important;
}

a.bg-kokua:hover, a.bg-kokua:focus,
button.bg-kokua:hover,
button.bg-kokua:focus {
  background-color: #285d8b !important;
}

.bg-kokual {
  background-color: rgba(84, 120, 191, 0.2) !important;
}

a.bg-kokual:hover, a.bg-kokual:focus,
button.bg-kokual:hover,
button.bg-kokual:focus {
  background-color: rgba(61, 95, 163, 0.2) !important;
}

.bg-kōkua {
  background-color: #3378b3 !important;
}

a.bg-kōkua:hover, a.bg-kōkua:focus,
button.bg-kōkua:hover,
button.bg-kōkua:focus {
  background-color: #285d8b !important;
}

.bg-ohana {
  background-color: #3e6bad !important;
}

a.bg-ohana:hover, a.bg-ohana:focus,
button.bg-ohana:hover,
button.bg-ohana:focus {
  background-color: #315487 !important;
}

.bg-ohanal {
  background-color: rgba(141, 208, 89, 0.2) !important;
}

a.bg-ohanal:hover, a.bg-ohanal:focus,
button.bg-ohanal:hover,
button.bg-ohanal:focus {
  background-color: rgba(114, 192, 54, 0.2) !important;
}

.bg-kula {
  background-color: #668712 !important;
}

a.bg-kula:hover, a.bg-kula:focus,
button.bg-kula:hover,
button.bg-kula:focus {
  background-color: #445a0c !important;
}

.bg-kulal {
  background-color: rgba(102, 135, 18, 0.2) !important;
}

a.bg-kulal:hover, a.bg-kulal:focus,
button.bg-kulal:hover,
button.bg-kulal:focus {
  background-color: rgba(68, 90, 12, 0.2) !important;
}

.bg-hui {
  background-color: #093a7f !important;
}

a.bg-hui:hover, a.bg-hui:focus,
button.bg-hui:hover,
button.bg-hui:focus {
  background-color: #06244f !important;
}

.bg-huil {
  background-color: rgba(9, 58, 127, 0.2) !important;
}

a.bg-huil:hover, a.bg-huil:focus,
button.bg-huil:hover,
button.bg-huil:focus {
  background-color: rgba(6, 36, 79, 0.2) !important;
}

.bg-dbs {
  background-color: #668712 !important;
}

a.bg-dbs:hover, a.bg-dbs:focus,
button.bg-dbs:hover,
button.bg-dbs:focus {
  background-color: #445a0c !important;
}

.bg-dbalpha {
  background-color: #998712 !important;
}

a.bg-dbalpha:hover, a.bg-dbalpha:focus,
button.bg-dbalpha:hover,
button.bg-dbalpha:focus {
  background-color: #6b5f0d !important;
}

.bg-dbresource {
  background-color: #cc8712 !important;
}

a.bg-dbresource:hover, a.bg-dbresource:focus,
button.bg-dbresource:hover,
button.bg-dbresource:focus {
  background-color: #9d680e !important;
}

.bg-dbthing {
  background-color: #ff8712 !important;
}

a.bg-dbthing:hover, a.bg-dbthing:focus,
button.bg-dbthing:hover,
button.bg-dbthing:focus {
  background-color: #de6e00 !important;
}

.bg-dbsl {
  background-color: #e0e7d0 !important;
}

a.bg-dbsl:hover, a.bg-dbsl:focus,
button.bg-dbsl:hover,
button.bg-dbsl:focus {
  background-color: #cad6ae !important;
}

.bg-superadmin {
  background-color: #4886b3 !important;
}

a.bg-superadmin:hover, a.bg-superadmin:focus,
button.bg-superadmin:hover,
button.bg-superadmin:focus {
  background-color: #396b8f !important;
}

.bg-admin {
  background-color: #745ab3 !important;
}

a.bg-admin:hover, a.bg-admin:focus,
button.bg-admin:hover,
button.bg-admin:focus {
  background-color: #5c4595 !important;
}

.bg-supporter {
  background-color: #B350A6 !important;
}

a.bg-supporter:hover, a.bg-supporter:focus,
button.bg-supporter:hover,
button.bg-supporter:focus {
  background-color: #913f86 !important;
}

.bg-appliedfor {
  background-color: #d72e81 !important;
}

a.bg-appliedfor:hover, a.bg-appliedfor:focus,
button.bg-appliedfor:hover,
button.bg-appliedfor:focus {
  background-color: #b02268 !important;
}

.bg-appliedforl {
  background-color: #d72e81 !important;
}

a.bg-appliedforl:hover, a.bg-appliedforl:focus,
button.bg-appliedforl:hover,
button.bg-appliedforl:focus {
  background-color: #b02268 !important;
}

.bg-available {
  background-color: #d4ffd4 !important;
}

a.bg-available:hover, a.bg-available:focus,
button.bg-available:hover,
button.bg-available:focus {
  background-color: #a1ffa1 !important;
}

.bg-totest {
  background-color: #7a82d7 !important;
}

a.bg-totest:hover, a.bg-totest:focus,
button.bg-totest:hover,
button.bg-totest:focus {
  background-color: #535dcb !important;
}

.bg-active {
  background-color: #77d077 !important;
}

a.bg-active:hover, a.bg-active:focus,
button.bg-active:hover,
button.bg-active:focus {
  background-color: #51c351 !important;
}

.bg-inactive {
  background-color: #d07777 !important;
}

a.bg-inactive:hover, a.bg-inactive:focus,
button.bg-inactive:hover,
button.bg-inactive:focus {
  background-color: #c35151 !important;
}

.bg-attention {
  background-color: #efe916 !important;
}

a.bg-attention:hover, a.bg-attention:focus,
button.bg-attention:hover,
button.bg-attention:focus {
  background-color: #c5bf0d !important;
}

.bg-closed {
  background-color: #ef5451 !important;
}

a.bg-closed:hover, a.bg-closed:focus,
button.bg-closed:hover,
button.bg-closed:focus {
  background-color: #eb2622 !important;
}

.bg-help {
  background-color: #b8ef56 !important;
}

a.bg-help:hover, a.bg-help:focus,
button.bg-help:hover,
button.bg-help:focus {
  background-color: #a4eb27 !important;
}

.bg-expired {
  background-color: #d72c47 !important;
}

a.bg-expired:hover, a.bg-expired:focus,
button.bg-expired:hover,
button.bg-expired:focus {
  background-color: #af2138 !important;
}

.bg-abandoned {
  background-color: #b33735 !important;
}

a.bg-abandoned:hover, a.bg-abandoned:focus,
button.bg-abandoned:hover,
button.bg-abandoned:focus {
  background-color: #8c2b29 !important;
}

.bg-cmd {
  background-color: #00801d !important;
}

a.bg-cmd:hover, a.bg-cmd:focus,
button.bg-cmd:hover,
button.bg-cmd:focus {
  background-color: #004d11 !important;
}

.bg-sysadmin {
  background-color: #d71cff !important;
}

a.bg-sysadmin:hover, a.bg-sysadmin:focus,
button.bg-sysadmin:hover,
button.bg-sysadmin:focus {
  background-color: #bf00e8 !important;
}

.bg-super {
  background-color: #be3aff !important;
}

a.bg-super:hover, a.bg-super:focus,
button.bg-super:hover,
button.bg-super:focus {
  background-color: #ad07ff !important;
}

.bg-dns {
  background-color: #801c24 !important;
}

a.bg-dns:hover, a.bg-dns:focus,
button.bg-dns:hover,
button.bg-dns:focus {
  background-color: #561318 !important;
}

.bg-network {
  background-color: #0954f4 !important;
}

a.bg-network:hover, a.bg-network:focus,
button.bg-network:hover,
button.bg-network:focus {
  background-color: #0743c3 !important;
}

.bg-backups {
  background-color: #26803b !important;
}

a.bg-backups:hover, a.bg-backups:focus,
button.bg-backups:hover,
button.bg-backups:focus {
  background-color: #1a5929 !important;
}

.bg-hardware {
  background-color: #d7223b !important;
}

a.bg-hardware:hover, a.bg-hardware:focus,
button.bg-hardware:hover,
button.bg-hardware:focus {
  background-color: #ab1b2f !important;
}

.bg-people {
  background-color: #37ab3a !important;
}

a.bg-people:hover, a.bg-people:focus,
button.bg-people:hover,
button.bg-people:focus {
  background-color: #2b842d !important;
}

.bg-business {
  background-color: #093a7f !important;
}

a.bg-business:hover, a.bg-business:focus,
button.bg-business:hover,
button.bg-business:focus {
  background-color: #06244f !important;
}

.bg-manager {
  background-color: #581e80 !important;
}

a.bg-manager:hover, a.bg-manager:focus,
button.bg-manager:hover,
button.bg-manager:focus {
  background-color: #3c1457 !important;
}

.bg-safezone {
  background-color: #3a15b2 !important;
}

a.bg-safezone:hover, a.bg-safezone:focus,
button.bg-safezone:hover,
button.bg-safezone:focus {
  background-color: #2b1084 !important;
}

.bg-contract {
  background-color: #b25126 !important;
}

a.bg-contract:hover, a.bg-contract:focus,
button.bg-contract:hover,
button.bg-contract:focus {
  background-color: #883e1d !important;
}

.bg-forest {
  background-color: #153E15 !important;
}

a.bg-forest:hover, a.bg-forest:focus,
button.bg-forest:hover,
button.bg-forest:focus {
  background-color: #081808 !important;
}

.bg-land {
  background-color: #00801d !important;
}

a.bg-land:hover, a.bg-land:focus,
button.bg-land:hover,
button.bg-land:focus {
  background-color: #004d11 !important;
}

.bg-safety {
  background-color: #80211f !important;
}

a.bg-safety:hover, a.bg-safety:focus,
button.bg-safety:hover,
button.bg-safety:focus {
  background-color: #571615 !important;
}

.bg-contractor {
  background-color: #801d3a !important;
}

a.bg-contractor:hover, a.bg-contractor:focus,
button.bg-contractor:hover,
button.bg-contractor:focus {
  background-color: #561427 !important;
}

.bg-sub {
  background-color: #2f3580 !important;
}

a.bg-sub:hover, a.bg-sub:focus,
button.bg-sub:hover,
button.bg-sub:focus {
  background-color: #21265b !important;
}

.bg-workcrew {
  background-color: #4f3587 !important;
}

a.bg-workcrew:hover, a.bg-workcrew:focus,
button.bg-workcrew:hover,
button.bg-workcrew:focus {
  background-color: #3a2762 !important;
}

.bg-foreman {
  background-color: #482780 !important;
}

a.bg-foreman:hover, a.bg-foreman:focus,
button.bg-foreman:hover,
button.bg-foreman:focus {
  background-color: #321b59 !important;
}

.bg-crew {
  background-color: #412187 !important;
}

a.bg-crew:hover, a.bg-crew:focus,
button.bg-crew:hover,
button.bg-crew:focus {
  background-color: #2d175e !important;
}

.bg-truck {
  background-color: #fd2242 !important;
}

a.bg-truck:hover, a.bg-truck:focus,
button.bg-truck:hover,
button.bg-truck:focus {
  background-color: #ea0224 !important;
}

.bg-visitor {
  background-color: #ff3131 !important;
}

a.bg-visitor:hover, a.bg-visitor:focus,
button.bg-visitor:hover,
button.bg-visitor:focus {
  background-color: #fd0000 !important;
}

.bg-workday {
  background-color: #ff943b !important;
}

a.bg-workday:hover, a.bg-workday:focus,
button.bg-workday:hover,
button.bg-workday:focus {
  background-color: #ff7808 !important;
}

.bg-worksite {
  background-color: #fd5e53 !important;
}

a.bg-worksite:hover, a.bg-worksite:focus,
button.bg-worksite:hover,
button.bg-worksite:focus {
  background-color: #fc2f21 !important;
}

.bg-welcome {
  background-color: #0342a8 !important;
}

a.bg-welcome:hover, a.bg-welcome:focus,
button.bg-welcome:hover,
button.bg-welcome:focus {
  background-color: #022e76 !important;
}

.bg-error {
  background-color: #fd0921 !important;
}

a.bg-error:hover, a.bg-error:focus,
button.bg-error:hover,
button.bg-error:focus {
  background-color: #d10216 !important;
}

.bg-good {
  background-color: #3b8c3b !important;
}

a.bg-good:hover, a.bg-good:focus,
button.bg-good:hover,
button.bg-good:focus {
  background-color: #2c682c !important;
}

.bg-zero {
  background-color: #dddddd !important;
}

a.bg-zero:hover, a.bg-zero:focus,
button.bg-zero:hover,
button.bg-zero:focus {
  background-color: #c4c4c4 !important;
}

.bg-eee {
  background-color: #fafafa !important;
}

a.bg-eee:hover, a.bg-eee:focus,
button.bg-eee:hover,
button.bg-eee:focus {
  background-color: #e1e1e1 !important;
}

.bg-none {
  background-color: #ffffff !important;
}

a.bg-none:hover, a.bg-none:focus,
button.bg-none:hover,
button.bg-none:focus {
  background-color: #e6e6e6 !important;
}

.bg-delete {
  background-color: #8A0303 !important;
}

a.bg-delete:hover, a.bg-delete:focus,
button.bg-delete:hover,
button.bg-delete:focus {
  background-color: #580202 !important;
}

.bg-lightgray {
  background-color: #999999 !important;
}

a.bg-lightgray:hover, a.bg-lightgray:focus,
button.bg-lightgray:hover,
button.bg-lightgray:focus {
  background-color: gray !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-journey {
  border-color: #4687c7 !important;
}

.border-kokua {
  border-color: #3378b3 !important;
}

.border-kokual {
  border-color: rgba(84, 120, 191, 0.2) !important;
}

.border-kōkua {
  border-color: #3378b3 !important;
}

.border-ohana {
  border-color: #3e6bad !important;
}

.border-ohanal {
  border-color: rgba(141, 208, 89, 0.2) !important;
}

.border-kula {
  border-color: #668712 !important;
}

.border-kulal {
  border-color: rgba(102, 135, 18, 0.2) !important;
}

.border-hui {
  border-color: #093a7f !important;
}

.border-huil {
  border-color: rgba(9, 58, 127, 0.2) !important;
}

.border-dbs {
  border-color: #668712 !important;
}

.border-dbalpha {
  border-color: #998712 !important;
}

.border-dbresource {
  border-color: #cc8712 !important;
}

.border-dbthing {
  border-color: #ff8712 !important;
}

.border-dbsl {
  border-color: #e0e7d0 !important;
}

.border-superadmin {
  border-color: #4886b3 !important;
}

.border-admin {
  border-color: #745ab3 !important;
}

.border-supporter {
  border-color: #B350A6 !important;
}

.border-appliedfor {
  border-color: #d72e81 !important;
}

.border-appliedforl {
  border-color: #d72e81 !important;
}

.border-available {
  border-color: #d4ffd4 !important;
}

.border-totest {
  border-color: #7a82d7 !important;
}

.border-active {
  border-color: #77d077 !important;
}

.border-inactive {
  border-color: #d07777 !important;
}

.border-attention {
  border-color: #efe916 !important;
}

.border-closed {
  border-color: #ef5451 !important;
}

.border-help {
  border-color: #b8ef56 !important;
}

.border-expired {
  border-color: #d72c47 !important;
}

.border-abandoned {
  border-color: #b33735 !important;
}

.border-cmd {
  border-color: #00801d !important;
}

.border-sysadmin {
  border-color: #d71cff !important;
}

.border-super {
  border-color: #be3aff !important;
}

.border-dns {
  border-color: #801c24 !important;
}

.border-network {
  border-color: #0954f4 !important;
}

.border-backups {
  border-color: #26803b !important;
}

.border-hardware {
  border-color: #d7223b !important;
}

.border-people {
  border-color: #37ab3a !important;
}

.border-business {
  border-color: #093a7f !important;
}

.border-manager {
  border-color: #581e80 !important;
}

.border-safezone {
  border-color: #3a15b2 !important;
}

.border-contract {
  border-color: #b25126 !important;
}

.border-forest {
  border-color: #153E15 !important;
}

.border-land {
  border-color: #00801d !important;
}

.border-safety {
  border-color: #80211f !important;
}

.border-contractor {
  border-color: #801d3a !important;
}

.border-sub {
  border-color: #2f3580 !important;
}

.border-workcrew {
  border-color: #4f3587 !important;
}

.border-foreman {
  border-color: #482780 !important;
}

.border-crew {
  border-color: #412187 !important;
}

.border-truck {
  border-color: #fd2242 !important;
}

.border-visitor {
  border-color: #ff3131 !important;
}

.border-workday {
  border-color: #ff943b !important;
}

.border-worksite {
  border-color: #fd5e53 !important;
}

.border-welcome {
  border-color: #0342a8 !important;
}

.border-error {
  border-color: #fd0921 !important;
}

.border-good {
  border-color: #3b8c3b !important;
}

.border-zero {
  border-color: #dddddd !important;
}

.border-eee {
  border-color: #fafafa !important;
}

.border-none {
  border-color: #ffffff !important;
}

.border-delete {
  border-color: #8A0303 !important;
}

.border-lightgray {
  border-color: #999999 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-journey {
  color: #4687c7 !important;
}

a.text-journey:hover, a.text-journey:focus {
  color: #2d6194 !important;
}

.text-kokua {
  color: #3378b3 !important;
}

a.text-kokua:hover, a.text-kokua:focus {
  color: #225077 !important;
}

.text-kokual {
  color: rgba(84, 120, 191, 0.2) !important;
}

a.text-kokual:hover, a.text-kokual:focus {
  color: rgba(54, 84, 144, 0.2) !important;
}

.text-kōkua {
  color: #3378b3 !important;
}

a.text-kōkua:hover, a.text-kōkua:focus {
  color: #225077 !important;
}

.text-ohana {
  color: #3e6bad !important;
}

a.text-ohana:hover, a.text-ohana:focus {
  color: #2a4875 !important;
}

.text-ohanal {
  color: rgba(141, 208, 89, 0.2) !important;
}

a.text-ohanal:hover, a.text-ohanal:focus {
  color: rgba(102, 172, 49, 0.2) !important;
}

.text-kula {
  color: #668712 !important;
}

a.text-kula:hover, a.text-kula:focus {
  color: #334409 !important;
}

.text-kulal {
  color: rgba(102, 135, 18, 0.2) !important;
}

a.text-kulal:hover, a.text-kulal:focus {
  color: rgba(51, 68, 9, 0.2) !important;
}

.text-hui {
  color: #093a7f !important;
}

a.text-hui:hover, a.text-hui:focus {
  color: #041938 !important;
}

.text-huil {
  color: rgba(9, 58, 127, 0.2) !important;
}

a.text-huil:hover, a.text-huil:focus {
  color: rgba(4, 25, 56, 0.2) !important;
}

.text-dbs {
  color: #668712 !important;
}

a.text-dbs:hover, a.text-dbs:focus {
  color: #334409 !important;
}

.text-dbalpha {
  color: #998712 !important;
}

a.text-dbalpha:hover, a.text-dbalpha:focus {
  color: #554b0a !important;
}

.text-dbresource {
  color: #cc8712 !important;
}

a.text-dbresource:hover, a.text-dbresource:focus {
  color: #86580c !important;
}

.text-dbthing {
  color: #ff8712 !important;
}

a.text-dbthing:hover, a.text-dbthing:focus {
  color: #c56100 !important;
}

.text-dbsl {
  color: #e0e7d0 !important;
}

a.text-dbsl:hover, a.text-dbsl:focus {
  color: #bfcd9d !important;
}

.text-superadmin {
  color: #4886b3 !important;
}

a.text-superadmin:hover, a.text-superadmin:focus {
  color: #325d7c !important;
}

.text-admin {
  color: #745ab3 !important;
}

a.text-admin:hover, a.text-admin:focus {
  color: #513d84 !important;
}

.text-supporter {
  color: #B350A6 !important;
}

a.text-supporter:hover, a.text-supporter:focus {
  color: #7f3776 !important;
}

.text-appliedfor {
  color: #d72e81 !important;
}

a.text-appliedfor:hover, a.text-appliedfor:focus {
  color: #9b1e5b !important;
}

.text-appliedforl {
  color: #d72e81 !important;
}

a.text-appliedforl:hover, a.text-appliedforl:focus {
  color: #9b1e5b !important;
}

.text-available {
  color: #d4ffd4 !important;
}

a.text-available:hover, a.text-available:focus {
  color: #88ff88 !important;
}

.text-totest {
  color: #7a82d7 !important;
}

a.text-totest:hover, a.text-totest:focus {
  color: #3f4bc5 !important;
}

.text-active {
  color: #77d077 !important;
}

a.text-active:hover, a.text-active:focus {
  color: #40ba40 !important;
}

.text-inactive {
  color: #d07777 !important;
}

a.text-inactive:hover, a.text-inactive:focus {
  color: #ba4040 !important;
}

.text-attention {
  color: #efe916 !important;
}

a.text-attention:hover, a.text-attention:focus {
  color: #ada80c !important;
}

.text-closed {
  color: #ef5451 !important;
}

a.text-closed:hover, a.text-closed:focus {
  color: #df1815 !important;
}

.text-help {
  color: #b8ef56 !important;
}

a.text-help:hover, a.text-help:focus {
  color: #99e315 !important;
}

.text-expired {
  color: #d72c47 !important;
}

a.text-expired:hover, a.text-expired:focus {
  color: #991d31 !important;
}

.text-abandoned {
  color: #b33735 !important;
}

a.text-abandoned:hover, a.text-abandoned:focus {
  color: #782524 !important;
}

.text-cmd {
  color: #00801d !important;
}

a.text-cmd:hover, a.text-cmd:focus {
  color: #00340c !important;
}

.text-sysadmin {
  color: #d71cff !important;
}

a.text-sysadmin:hover, a.text-sysadmin:focus {
  color: #aa00cf !important;
}

.text-super {
  color: #be3aff !important;
}

a.text-super:hover, a.text-super:focus {
  color: #9e00ed !important;
}

.text-dns {
  color: #801c24 !important;
}

a.text-dns:hover, a.text-dns:focus {
  color: #410e12 !important;
}

.text-network {
  color: #0954f4 !important;
}

a.text-network:hover, a.text-network:focus {
  color: #063baa !important;
}

.text-backups {
  color: #26803b !important;
}

a.text-backups:hover, a.text-backups:focus {
  color: #144520 !important;
}

.text-hardware {
  color: #d7223b !important;
}

a.text-hardware:hover, a.text-hardware:focus {
  color: #951829 !important;
}

.text-people {
  color: #37ab3a !important;
}

a.text-people:hover, a.text-people:focus {
  color: #247126 !important;
}

.text-business {
  color: #093a7f !important;
}

a.text-business:hover, a.text-business:focus {
  color: #041938 !important;
}

.text-manager {
  color: #581e80 !important;
}

a.text-manager:hover, a.text-manager:focus {
  color: #2d0f42 !important;
}

.text-safezone {
  color: #3a15b2 !important;
}

a.text-safezone:hover, a.text-safezone:focus {
  color: #240d6e !important;
}

.text-contract {
  color: #b25126 !important;
}

a.text-contract:hover, a.text-contract:focus {
  color: #733419 !important;
}

.text-forest {
  color: #153E15 !important;
}

a.text-forest:hover, a.text-forest:focus {
  color: #020502 !important;
}

.text-land {
  color: #00801d !important;
}

a.text-land:hover, a.text-land:focus {
  color: #00340c !important;
}

.text-safety {
  color: #80211f !important;
}

a.text-safety:hover, a.text-safety:focus {
  color: #421110 !important;
}

.text-contractor {
  color: #801d3a !important;
}

a.text-contractor:hover, a.text-contractor:focus {
  color: #420f1e !important;
}

.text-sub {
  color: #2f3580 !important;
}

a.text-sub:hover, a.text-sub:focus {
  color: #1a1e48 !important;
}

.text-workcrew {
  color: #4f3587 !important;
}

a.text-workcrew:hover, a.text-workcrew:focus {
  color: #2f1f50 !important;
}

.text-foreman {
  color: #482780 !important;
}

a.text-foreman:hover, a.text-foreman:focus {
  color: #271545 !important;
}

.text-crew {
  color: #412187 !important;
}

a.text-crew:hover, a.text-crew:focus {
  color: #23124a !important;
}

.text-truck {
  color: #fd2242 !important;
}

a.text-truck:hover, a.text-truck:focus {
  color: #d10220 !important;
}

.text-visitor {
  color: #ff3131 !important;
}

a.text-visitor:hover, a.text-visitor:focus {
  color: #e40000 !important;
}

.text-workday {
  color: #ff943b !important;
}

a.text-workday:hover, a.text-workday:focus {
  color: #ee6c00 !important;
}

.text-worksite {
  color: #fd5e53 !important;
}

a.text-worksite:hover, a.text-worksite:focus {
  color: #fc1707 !important;
}

.text-welcome {
  color: #0342a8 !important;
}

a.text-welcome:hover, a.text-welcome:focus {
  color: #02245d !important;
}

.text-error {
  color: #fd0921 !important;
}

a.text-error:hover, a.text-error:focus {
  color: #b80113 !important;
}

.text-good {
  color: #3b8c3b !important;
}

a.text-good:hover, a.text-good:focus {
  color: #245624 !important;
}

.text-zero {
  color: #dddddd !important;
}

a.text-zero:hover, a.text-zero:focus {
  color: #b7b7b7 !important;
}

.text-eee {
  color: #fafafa !important;
}

a.text-eee:hover, a.text-eee:focus {
  color: #d4d4d4 !important;
}

.text-none {
  color: #ffffff !important;
}

a.text-none:hover, a.text-none:focus {
  color: #d9d9d9 !important;
}

.text-delete {
  color: #8A0303 !important;
}

a.text-delete:hover, a.text-delete:focus {
  color: #3f0101 !important;
}

.text-lightgray {
  color: #999999 !important;
}

a.text-lightgray:hover, a.text-lightgray:focus {
  color: #737373 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}